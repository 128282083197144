import {  Modal,  Space } from "antd";
import  { useState } from "react";
import { updateMultipleApplicants } from "../../../../../api/service/applicant";
import ButtonLoadingHOC from "../../../../../HOC/ButtonLoadingHOC";
import { notiHOC } from "../../../../../HOC/NotiHOC";

const ModalDeleteApplicant: React.FC<any> = ({setSelectedRowKeys=()=>{},tableJob=false,selectedRowKeys, fetchDataForTable, jobName, }:any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const onDeleteMultiple = async () => {
    setLoading(true)
    const payloadData = {
      applicant_id: selectedRowKeys,
      data: {
        deleted_at: true,
      },
    };
    
    const response = await updateMultipleApplicants(payloadData);
    if ((response.status = 200)) {
      setSelectedRowKeys([]);
      fetchDataForTable();
    } else {
      notiHOC("更新に失敗しました。", false);
    }
  };

  
  return (
    <>
      <a onClick={showModal}   className={tableJob? "link-hover-yl-table":"btn-border-blue-blod-medium btn-hover-yl"}>
      削除
      </a>
      <Modal
        centered
        title={<h1>{"削除確認"}</h1>}
        open={isModalOpen}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="delete-manager-list">
          <p className="m-t-8" style={{maxHeight:500, overflowY:'auto'}}>{'選択された応募者を削除します。よろしければ「削除」をクリックしてください'}
              </p>
         
          <div style={{ padding: "20px 0px", textAlign: "end" }}>
            <Space size={15}>
              <span
                onClick={handleCancel}
                className="btn-exit-modal btn-hover-yl"
              >
                キャンセル
              </span>

              <ButtonLoadingHOC
                name={"削除"}
                handelButton={onDeleteMultiple}
                loadingButton={loading}
              />
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalDeleteApplicant;
