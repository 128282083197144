
import { Form, Input, Select, Space } from "antd";

const {TextArea} = Input
const FormSelect = ({
  name,
  required,
  placeholder,
  labelName,
  message,
  arrayData
}:any) => {
 
  return (
    <Form.Item
          label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>{labelName}</div>
              <div className={required?'required':'not_required'}>{required?"必須":"任意"} </div>
            </Space>
          }
        >
      <Form.Item
        name={name}
        rules={[
          {
            required: required,
            message: message,
          },
          
        ]}
      >
        <Select
              placeholder={"選択してください。"}
              size="large"
             
            >
              {arrayData.map((el) => {
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {el.name}
                  </Select.Option>
                );
              })}
            </Select>
      </Form.Item>
     
    </Form.Item>
  );
};
export default FormSelect;
