import request from "../utils/request";

export async function getPostingListData(data) {
     return request(`/api/post/getList`, {
       method: "GET",
       params: data,
     });
   }

   export async function updateStatusPosting(id,data) {
    return request(`/api/post/updatePost/${id}`, {
      method: "POST",
      data: data,
    });
  }



  export async function deleteMultiplePost(data) {
    return request(`api/post/deleteListPost`, {
        method:"DELETE",
        data: data
    })
}


export async function createPost(data) {
  return request(`/api/post/create`, {
      method:"POST",
      data: data
  })
}

export async function getDetailPost(id) {
  return request(`/api/post/getPost/${id}`, {
      method:"GET",
    
  })
}



export async function updatePost(id,data) {
  return request(`/api/post/updatePost/${id}`, {
      method:"POST",
      data: data
    
  })
}



export async function duplicatePost(data) {
  return request(`/api/post/duplicate-multiple`, {
      method:"POST",
      data: data
  })
}

export async function updateMultiPost(data) {
  return request(`/api/post/update-status`, {
      method:"POST",
      data: data
  })
}