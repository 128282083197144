import "./style.scss";
import { Form,Space,Col,Row,Button} from 'antd';
import {  Modal } from 'antd';
import  { useState } from 'react';
import  { CloseOutlined,CheckCircleOutlined  } from '@ant-design/icons';
import { notiHOC } from "../../../HOC/NotiHOC";
import  '../../../../css/customModalAnt.scss';
import {
  createTimeInterView
} from "../../../api/service/applicant";
export const ModalNotiSendInterView = ({dataConfirm,applicant,setOpen}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);





  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const createTimeInter = (data) => {
    createTimeInterView(data).then((resp) => {
      if (resp.status==200) {
        setIsModalOpen(true);
        setOpen(false)   
       } else {
    
        notiHOC('失敗したメンバーを追加',false)
      }

    });
  };
  return (
  <>
<button
                type="button"
                onClick={()=>{                  
                  const listInterview = dataConfirm.listInterviewSchedule.map((item,key)=>{
                    return  {"date":item.date,"time":item.time}
                 })
                 if(dataConfirm.online==1){
                  const dataInterView={
                    "data": {
                      "time": listInterview,
                        "is_online": dataConfirm.online,
                        "post_code": dataConfirm.post_code,
                        "prefecture": dataConfirm.prefectures_1,
                        "city": "fdsaf",
                        "address": dataConfirm.address,
                        "belonging":dataConfirm.belongings,
                        "emergency_contact": dataConfirm.emergencyContact,
                        "remark": dataConfirm.remark,
                        "time_interview":dataConfirm.time_interview
                    },
                    "applicant_id": applicant.status_progress_applicants
                    [0].applicant_id
                    ,
                    "status_applicant_id": applicant.status_progress_applicants
                    [0].status_applicant_id

                }


                
                createTimeInter(dataInterView)
                 }else{
                  const dataInterView={
                    "data": {
                      "time": listInterview,
                        "is_online": dataConfirm.online,
                        "url_interview":dataConfirm.url_interview,
                        "city": "fdsaf",
                        "address": dataConfirm.address,
                        "belonging":dataConfirm.belongings,
                        "emergency_contact": dataConfirm.emergencyContact,
                        "remark": dataConfirm.remark,
                        "time_interview":dataConfirm.time_interview
                    },
                    "applicant_id": applicant.status_progress_applicants
                    [0].applicant_id
                    ,
                    "status_applicant_id": applicant.status_progress_applicants
                    [0].status_applicant_id

                }


                
                createTimeInter(dataInterView)
                 }
           
                }}
                style={{
                  color: "white",
                  background: "#00c4cc",
                  borderRadius: "999px",
                  height: "55px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "310px",
                  fontWeight: "bold",
                  marginLeft: '10px'
                }}
              >
                面接日程をユーザーに送信する
              </button>
<Modal title={true} footer={false} open={isModalOpen}    centered onOk={handleOk} onCancel={handleCancel} width={600}  className="style_modal">
        <Row style={{justifyContent:'center'}}>
       <Col style={{textAlign:'center',padding:'50px 0px'}}>
       <Space className="noti-modal"><CheckCircleOutlined />送信完了しました</Space>
        <p>受信メールをご確認ください。</p>
        <button className="noti-button-send"   onClick={handleCancel} >
        <CloseOutlined /> 閉じる
</button>
       
       </Col>
        </Row>
      </Modal>
  </>

  );
}


