import React, { useState, useEffect } from 'react';

import { Button,Form, Input, Drawer, Spin} from 'antd';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { child, get, getDatabase, onValue, ref, set } from "firebase/database";
import { sendMail } from '../../../../api/service/applicant';
import { AuthContext } from '../../../../hooks/useAuth';
import TextSend from '../../../../components/chats/TextSend';
import TextReceive from '../../../../components/chats/TextReceive';
import ModalSelectTemplate from './ModalSelectTemplate';
const firebaseDB = getDatabase();
const { TextArea } = Input;

const ChatBox: React.FC = ({applicant}) => {
  const [open, setOpen] = useState(false);
  const [partnerId, setPartnerId] = React.useState("");
 const [isLoading, setIsLoading]= useState(false)
 
 const [conversation, setConversation] = React.useState<any>(null);
 const [currentFirebaseUser, setCurrentFirebaseUser] = React.useState<any>({});
 const { user } = React.useContext(AuthContext);
 const [notiSuccess, setNotiSuccess] = React.useState(false);


 const auth = getAuth();
 console.log('authauthauthauth',auth);
 console.log('isLoading',isLoading);
 console.log('currentFirebaseUser',currentFirebaseUser);
 console.log('conversation',conversation);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setCurrentFirebaseUser(user);
    }
  });


  const conversationRef = React.useRef(null);
  const scrollToBottom = () => {
    conversationRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  React.useEffect(scrollToBottom, [conversation]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  console.log('partnerId',partnerId);
  useEffect(()=>{
      if(open){
        
          console.log('applicant',applicant);
          get(child(ref(getDatabase()), `users/`))
      .then((snapshot) => {
        const users = snapshot.val();
        console.log('usersusersusers',users);

        Object.keys(users).forEach(function (key) {
          // find user
          if (users[key].email == applicant?.email) {
            setPartnerId(key);
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
      }
  },[open])
  
  useEffect(() => {
    
     if(partnerId){
        onValue(ref(getDatabase(), "conversations"), (snapshot) => {
            if (snapshot.val()) {
              const  obj = snapshot.val();
              const arr_conv = [];
              let obj_conv = {};
    
              Object.keys(obj).forEach(function (key) {
                // find user
                if (key == `${currentFirebaseUser.uid}-${partnerId}`) {
                  obj_conv = obj[key];
                }
              });
      
              Object.keys(obj_conv).forEach(function (key) {
                arr_conv.push(obj_conv[key]);
              });
              // set conservations
              setConversation([...arr_conv])
            }
          });
     }
    
  }, [partnerId]);

  const [form] = Form.useForm();
  

  const handleSubmit = async (values) => {
    get(
      child(
        ref(firebaseDB),
        `conversations/${currentFirebaseUser.uid}-${partnerId}`
      )
    )
      .then((snapshot) => {
        if (!snapshot.val()) {
          console.log("cuộc hội thoại mới");
          const dataSend = {
            id: applicant.id,
            content: values.message,
            host: window.location.origin,
            link: `${currentFirebaseUser.uid}-${partnerId}`,
            partnerId: partnerId ?? 0,
            userName: user?.name ?? null,
          };
          console.log("dataSend", dataSend);

          sendMail(dataSend).then((response) => {
            console.log("gửi mail", response);
            setNotiSuccess(true);
          });
        }
      })
      .catch((error) => {
        console.log("er->", error.code, error.message);
      });

    const userId = currentFirebaseUser.uid;
    const data = {
      message: values.message,
      timeSend: new Date().getTime(),
      isUser: true,
      listed_company_name: applicant?.listed_company_name ?? "",
    };

    if (data.message.trim() != "") {
        console.log('hihihihi');
        
      set(
        ref(
          firebaseDB,
          `conversations/${userId}-${partnerId}/${data.timeSend}`
        ),
        data
      ).then((e)=>{
         form.resetFields()
         
      });
     
    } else {
      console.log("data is empty");
    }
  };



  return (
    <>
     
        
        
        <Button
            className="applicant-button applicant-btn-chat" 
            style={{ marginBottom: "10px", fontWeight:700 }}
           
            onClick={showDrawer}>
          
            メッセージ
          </Button>
      <Drawer
        title={<div style={{fontWeight:700}}>{`${applicant.first_name_kanji} ${applicant.last_name_kanji}`}</div>}
        placement={"right"}
        width={500}
        
        onClose={onClose}
        open={open}
        footer={<div>
            <Form
        colon={false}
        form={form}
        onFinish={handleSubmit}
       
      >
        <div style={{display:'flex', flexDirection:'row'}}
        > 
          <Form.Item
            name="message"
            style={{flex:1}}
          >
            <TextArea

              placeholder={
                "テキストを入力して送信をクリック"
              }
              size="large"
              autoSize={{ minRows: 3, maxRows: 3 }}
            />
          </Form.Item>
          <Button
                htmlType="submit"
                style={{
                  color: "white",
                  backgroundColor: "#00c4cc",
                  border: "none",
                  borderRadius: "100vh",
                  fontSize: "16px",
                  fontWeight: "bold",
                 
                  margin: '0 10px',
                  width: "133px",
                  height: 40,
                 
                }}
              >
                送信
              </Button>
        </div>
       <ModalSelectTemplate form={form}/>
        </Form>
        </div>}
      >
        <Spin spinning={!conversation}>
        {conversation &&
              conversation?.map((conv) => {
                if (conv?.isUser == true) {
                  return (
                    <TextSend
                      text={conv.message}
                      time={conv.timeSend}
                      key={conv.timeSend}
                    />
                  );
                } else {
                  return (
                    <TextReceive
                      text={conv.message}
                      time={conv.timeSend}
                      key={conv.timeSend}
                    />
                  );
                }
              })}
              <div className="" ref={conversationRef}></div>
        </Spin>
      </Drawer>
    </>
  );
};

export default ChatBox;