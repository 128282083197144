import {
    Card,
    Col,
    Form,
    DatePicker,
    Row,
    Select,
    Input,
    Checkbox,
    Button,
  } from "antd";
  import { AuthContext } from "../../../../../hooks/useAuth";
  import { useContext } from "react";
  import "../index.scss";

  import { FilterOutlined } from "@ant-design/icons";
import ButtonLoadingHOC from "../../../../../HOC/ButtonLoadingHOC";

  

  // eslint-disable-next-line max-lines-per-function
  const SearchTableListAccount = ({ onSubmit, loading }: any) => {
    const { user } = useContext(AuthContext);
    const locateObj = [];
    Object.keys(user.locations)
      .sort()
      .map((key) => locateObj.push({ id: key, name: user.locations[key] }));
    const [form] = Form.useForm();
    const onFinish = async (value) => {
      const  dataSent ={
        job_name: value.name,
       
      }
      if (onSubmit) onSubmit(dataSent);
    };
  
  
    return (
      <div>
        <Card style={{ marginBottom: 8 }} >
          <Form
            form={form}
           wrapperCol={{span:10}}
            name="validate_other"
            initialValues={{}}
            onFinish={onFinish}
          
          >
    
   <Row gutter={40} >
        <Col span={19} className="gutter-row">
        <Form.Item name="name" label={'氏名'}

>

    <Input
      placeholder="氏名を入力"
      size={"large"}
    />
    </Form.Item>
        </Col>


     

        <Col span={3} className="gutter-row">



        <ButtonLoadingHOC
                  name={"検索"}
                  icon={<FilterOutlined />}
                  handelButton={form.submit}
                  loadingButton={loading}
                />
        </Col>
      
        
       </Row>


     

          </Form>
        </Card>
      </div>
    );
  };
  export default SearchTableListAccount;
  
