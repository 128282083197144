import { Link } from "react-router-dom";
import Router from "../../config/route";
import { Button } from "antd";
const NotFound = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <h1 style={{ color: "red" }}>Not Found</h1>
        <Link to={Router.root} style={{ textAlign: "center" }}>
          <Button>Home</Button>
        </Link>
      </div>
    </>
  );
};

export default NotFound;
