import Header from "../../Header";
import { Row, Col, Breadcrumb } from "antd";
import BackIcon from "../../../components/Icon/BackIcon";
import { Link } from "react-router-dom";
import Router from "../../../config/route";



import "../style.css";
import TableListApplicants from "./tableListApplicants";


const ApplicantsList = ({}) => {
  return (
    <div>
      <Header />
      <Row>
        <Col style={{ justifyContent: "flex-end" }} span={24}>
          <div className="inner-block">
            <Breadcrumb
              style={{
                padding: "20px 0 20px",
              }}
            >
              <Link to={Router.root}>
                <BackIcon></BackIcon>
                <span className="text-color-black m-l-4">ホームへ戻る</span>
              </Link>
            </Breadcrumb>
            <div className="applicants-list">
            <div className="d-f job-list-top-btn jc-r mb-20">
              <button className="btn-border-blue">CSV出力</button>
            </div>
          </div>
          </div>
        </Col>
        <Col>
        <div style={{ padding: " 0 20px" }}>
          <div className="container has-shadow">
          <h1 style={{fontWeight:600, color:'#00C4CC', fontSize:20}}>応募者一覧</h1>
            
            <TableListApplicants/>
          </div>
        </div></Col>
      </Row>
     
    </div>
  );
};

export default ApplicantsList;
