import { Breadcrumb, Col, Row } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import BackIcon from "../../../components/Icon/BackIcon";
import Header from "../../Header";

import FormCreateNewPosting from "./FormCreateNewPosting";

const NewPosting = ({}) => {
  return (
    <div>
      <Header />
      <div
        className="inner-block job-new-form "
        style={{ paddingBottom: "100px" }}
      >
        <Row>
          <Col style={{ justifyContent: "flex-end" }} span={24}>
            <div className="inner-block">
              <Breadcrumb
                style={{
                  padding: "20px 0 40px",
                }}
              >
                <Link to="/posting">
                  <BackIcon></BackIcon>
                  <span className="text-color-black m-l-4">コラム一覧へ戻る</span>
                </Link>
              </Breadcrumb>
            </div>
          </Col>
          <Col span={24}>
            <div className=" job-list pos-r " style={{ padding: "0 20px" }}>
              <div className="container has-shadow">
                <h2 className="section-ttl">コラム編集</h2>

                  <FormCreateNewPosting />
              </div>
            </div>
          </Col>
        </Row>

        
      </div>
    </div>
  );
};

export default NewPosting;
