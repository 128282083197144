


const DownIcon = () => {
    return (
        <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.455497 2.68288L3.36544 6.22712C3.7159 6.65397 4.2841 6.65397 4.63456 6.22712L7.5445 2.68288C8.10983 1.99432 7.70945 0.816996 6.90995 0.816996L1.09006 0.816995C0.290557 0.816995 -0.109833 1.99432 0.455497 2.68288Z" fill="#555555" />
        </svg>
    )
}

export default DownIcon