import { createAction } from '@reduxjs/toolkit';

export const openModal = createAction('openModal', function prepare(payload) {
  return {
    payload
  }
})
export const closeModal = createAction('closeModal');

export const setSubmitModal = createAction('setSubmitModal', function prepare(payload) {
  return {
    payload
  }
})