// eslint-disable-next-line max-lines-per-function
import { Col, Select, Typography } from "antd";

import moment = require("moment");
import { Fragment } from "react";
import { Link  } from "react-router-dom";
import { duplicatePost, updateStatusPosting } from "../../../../api/service/posting";
import arrayStatusPosting from "../../../../components/dataArrayUseInProject/arrayStatusPosting";
import Router from "../../../../config/route";
import { notiHOC } from "../../../../HOC/NotiHOC";
import ModalDeletePosting from "./modelDeletePosting/ModalDeletePosting";


const { Title } = Typography;
export const defineColumnTableListPosting = (fetchDataForTable, listData) => {
  
  return [
    {
      title: "タイトル",
      sorter: true,
      width: '50%',
      render: (_, record) => {
        return (
          <div style={{ maxWidth: 900, whiteSpace: "pre-line"}}>
          <Title level={5}>{record.title}</Title>
          </div>
        );
      },
    },
    {
      title:  <Title  level={5} style={{color:'#0ABAB5'}}>ステータス</Title>,
      sorter: true,
      render: (_, record) => {
        return (
          <div>
          <Select
            size={"large"}
            style={{  width: "100%" }}
            placeholder={"職種カテゴリ"}
            value={record.status}
            onChange={async (e) => {
              const response = await updateStatusPosting(record.id,{status:e});
              if (response.data.success == true) {
                notiHOC("正常に更新されました。", true);
                fetchDataForTable();
              } else {
                notiHOC("更新に失敗しました。", false);
              }
            }
          }
          >
            {arrayStatusPosting.map((el) => {
              return (
                <Select.Option key={el.id} value={el.id}>
                  {el.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        );
      },
    },

    {
     title: "更新日時",
     sorter: true,
     render: (_, record) => {
       return (
         <Col>
     
<Typography>{moment(record.updated_at).format("YYYY/MM/DD")}</Typography>
<Typography>{moment(record.updated_at).format("hh:mm:ss")}</Typography>


       </Col>
       );
     },
   },
   {
    fixed: "right",
    title: "",
    width: '10%',
    render: (_, record) => {
      return (
        <div className="action-box">
          <div className="group-btn-left">
            <div className="btn-group-2">
              <Link
                to={`${Router.posting_edit}/${record.id}`}
                className=" action-btn"
                style={{ marginRight: "4px" }}
              >
                編集
              </Link>
              <a
                className=" action-btn"
                onClick={async () => {
                  const data={
                    "multiplePost": [record.id]
                }
                  const response = await duplicatePost(data);
                  if (response.data.success == true) {
                    fetchDataForTable();
                  } else {
                    notiHOC("更新に失敗しました。", false);
                  }
                }}
              >
                複製
              </a>
            </div>
            <div className="">
              <a href={record.url_preview} className=" action-btn btn-full">
                プレビュー
              </a>
            </div>
          </div>
          <div className="tag-del">
            <ModalDeletePosting
              selectedRowKeys={[record.id]}
              fetchDataForTable={fetchDataForTable}
              record={record}
              listData={listData}
              tableJob={true}
              postTitle={
                <Fragment>
                  <p>@ {record.title}</p>
                  <br></br>
                </Fragment>
              }
            />
          </div>
        </div>
      );
    },
  },
  ];
};
