import { Link } from "react-router-dom";
import Header from "../../Header";
import { Row, Col, Breadcrumb, } from "antd";
import BackIcon from "../../../components/Icon/BackIcon";
import FormCreateNewAccount from "./FormCreateNewAccount";



const CreateNewAccount = ({}) => {
  return (
    <div>
      <Header />
      <div
        className="inner-block job-new-form "
        style={{ paddingBottom: "100px" }}
      >
        <Row>
          <Col style={{ justifyContent: "flex-end" }} span={24}>
            <div className="inner-block">
              <Breadcrumb
                style={{
                  padding: "20px 0 40px",
                }}
              >
                <Link to="/setting">
                  <BackIcon></BackIcon>
                  <span className="text-color-black m-l-4">一覧に戻る</span>
                </Link>
              </Breadcrumb>
            </div>
          </Col>
          <Col span={24}>
            <div className=" job-list pos-r " style={{ padding: "0 20px" }}>
              <div className="container has-shadow">
                
                <h2 className="section-ttl">アカウントを新規追加</h2>
                <div className="list-count" style={{ paddingBottom: "20px" }}>
                  <FormCreateNewAccount />
                
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateNewAccount;
