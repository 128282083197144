import axios from "axios";

const api = "https://zipcloud.ibsnet.co.jp/api/search?zipcode=";
export const getAddress = async (id: number) => {
  return await axios.get(`${api}${id}`);
};

export const getCoordinate = async (address: any) => {
  return await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBnedCyJWLSEPfr8WYRZPDu2rhVz3TOrfk&language=ja`
  );
};
