import { Link } from "react-router-dom";

import NewCompanyForm from "./NewCompanyForm";
import BackIcon from "../../../components/Icon/BackIcon";

import Header from "../../Header";
import { Breadcrumb } from "antd";

const New = () => {
  return (
    <>
      <Header />
      <div
        className="inner-block job-new-form px-40"
        style={{ paddingBottom: "80px" }}
      >
        <div className="inner-block">
          <Breadcrumb
            style={{
              padding: "20px 0 20px",
            }}
          >
            <Link to="/admin">
              <BackIcon></BackIcon>
              <span className="text-color-black m-l-4">求人一覧へ戻る</span>
            </Link>
          </Breadcrumb>
        </div>

        <NewCompanyForm />
      </div>
    </>
  );
};

export default New;
