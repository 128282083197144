import "./style.scss";
import { Form, Space, Col, Row } from 'antd';
import { Modal } from 'antd';
import { ModalNotiSendInterView } from "./ModalNotiSendInterView";
import { useState } from "react";
import { notiHOC } from "../../../HOC/NotiHOC";
import {
  createTimeInterView
} from "../../../api/service/applicant";
import  '../../../../css/customModalAnt.scss';
import { Typography } from 'antd';
const ScheduleConfirmModal = ({ setOpen, open, dataConfirm, applicant }) => {


  const [openModalNoti, setOpenModalNoti] = useState<Boolean>(false);



  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const createTimeInter = (data) => {
    setLoading(true)


    createTimeInterView(data).then((resp) => {
      if (resp.status == 200) {
        setOpenModalNoti(true)
        setOpen(false)
      } else {

        notiHOC('失敗したメンバーを追加', false)
        setOpenModalNoti(false)
      }
      setLoading(false)
      setIsModalOpen(false);
    });
  };
const {  Link } = Typography;
  return (
    <>


      <Modal width={900} open={open} onOk={handleOk} centered onCancel={handleCancel} footer={false}  className="style_modal">
        <Form style={{ padding: '40px 30px' }}>
          <Space className="icon-close-modal"

            onClick={() => {

            }}>
          </Space>
          <Col style={{ marginBottom: "40px" }}>
        

            <Row className="field-confirm" style={{ borderBottom: "1px solid #ccc", padding: '15px 0' }} >
              <Col span={7} className="field-confirm-name">会場</Col>
              <Col className="field-confirm-data" span={17}>
                {dataConfirm?.online == 1 ? 'オンライン' : 'オフライン'}
              </Col>
            </Row>
            <Row className="field-confirm" style={{ borderBottom: "1px solid #ccc", padding: '15px 0' }} >
            <Col span={7} className="field-confirm-name">{dataConfirm?.online==1?'住所':'URL'}</Col>
              <Col className="field-confirm-data" span={17}>
              {dataConfirm?.online == 1 ? `${dataConfirm?.post_code} - ${dataConfirm?.prefectures_1} - ${dataConfirm?.city}  ${dataConfirm?.workplace_address==""|| dataConfirm?.workplace_address==undefined?"": `- ${dataConfirm?.workplace_address}`}` :   <Link href={dataConfirm?.url_interview}>{dataConfirm?.url_interview}</Link>}
              </Col>
            </Row>
            <Row className="field-confirm" style={{ borderBottom: "1px solid #ccc", padding: '15px 0' }}>
              <Col span={7} className="field-confirm-name">面接所要時間</Col>
              <Col className="field-confirm-data" span={17}>
                {dataConfirm?.time_interview}分

              </Col>
            </Row>
            <Row className="field-confirm" style={{ borderBottom: "1px solid #ccc", padding: '15px 0' }}>
              <Col span={7} className="field-confirm-name">面接日程候補</Col>
              <Col className="field-confirm-data " span={17}>
                <Col className="data-schedule" >
                  {dataConfirm?.listInterviewSchedule.map((schedule, index) => {
                    return (
                      <div style={{ marginBottom: '20px' }} key={index}>
                        <Col className="" >{schedule?.date}</Col>

                        {
                          schedule?.time.map((time, key) => {

                            return <Row key={key}>
                              <Col className="">{time.startTime}</Col>
                              <Col>~</Col>
                              <Col className="">{time.endTime}</Col>
                            </Row>

                          }
                          )
                        }
                      </div>




                    )



                  })}
                </Col>


              </Col>
            </Row>
            <Row className="field-confirm" style={{ borderBottom: "1px solid #ccc", padding: '15px 0' }}>
              <Col span={7} className="field-confirm-name">持ち物</Col>
              <Col className="field-confirm-data " span={17}>
                {dataConfirm?.item}

              </Col>
            </Row>
            <Row className="field-confirm" style={{ borderBottom: "1px solid #ccc", padding: '15px 0' }}>
              <Col span={7} className="field-confirm-name">緊急連絡先</Col>
              <Col className="field-confirm-data " span={17}>
                {dataConfirm?.emergencyContact}

              </Col>
            </Row>
            <Row className="field-confirm" style={{ borderBottom: "1px solid #ccc", padding: '15px 0' }}>
              <Col span={7} className="field-confirm-name">備考</Col>
              <Col className="field-confirm-data " span={17}>
                {dataConfirm?.remark}

              </Col>
            </Row>
          </Col>


          <Row
            style={{ justifyContent: "center", marginTop: '20px' }}
          >
            <Col >
              <button
                onClick={handleCancel}

                style={{
                  color: "#00c4cc",
                  border: "solid 2px #00c4cc",
                  borderRadius: "999px",
                  height: "55px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "180px",
                  fontWeight: "bold",
                  marginRight: '10px'
                }}
              >
                入力ページに戻る
              </button>
            </Col>
            <Col >


              <ModalNotiSendInterView
                dataConfirm={dataConfirm}
                setOpen={setOpen}
                applicant={applicant} />



            </Col>
          </Row>






        </Form>

      </Modal>
    </>

  );
};

export default ScheduleConfirmModal;
