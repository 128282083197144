import { Space, Input, Button, Row, Col, DatePicker, Breadcrumb, Radio, Form, Select } from 'antd';
import { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { getDetailApplicant } from "../../api/service/applicant";
import { getAddress } from "../../api/session/address";
import PlusIcon from "../../components/Icon/PlusIcon";
import Header from "../../containers/Header";
import ScheduleConfirmModal from "./component/ScheduleConfirmModal";
import { MinusCircleOutlined } from '@ant-design/icons';
import LoadingScreen from "../../components/Common/LoadingScreen";
import '../../../css/interViewSchedule.scss'
import { notiHOC } from '../../HOC/NotiHOC';
import moment = require("moment");
import arrTime from '../../components/dataArrayUseInProject/arrTime';
import { Console, log } from 'console';
interface InterviewSchedule {
  index: number;
  time: any;
}
const InterviewSchedule = () => {
    const dateFormat = 'YYYY/MM/DD';
  const { id } = useParams();
  const [invalidZipcode, setInvalidZipcode] = useState("");
  const [inputZipCode, setInputZipCode] = useState(null);
  const [listInterviewSchedule, setListInterviewSchedule] = useState([]);
  const [applicant, setApplicant] = useState<any>();
  const [open, setOpen] = useState<Boolean>(false);
  const [dataConfirm, setDataConfirm] = useState(null);
  const [valueWorkFormat, setValueWorkFormat] = useState(1);

  const renderContent = (tab: number) => {
    switch (tab) {
      case 1:
        return (<Form.Item label={<Space className="field-name" >
          会場<span className="tag-red"> 必須</span>

        </Space>}
          rules={[{ required: true, message: '空欄にすることはできません' }]} >

          <Form.Item

          >
            <Row style={{ display: "flex", alignItems: "start" }}>
              <Col span={5} style={{ paddingTop: 10 }}>
                <Space size={14}>
                  <div style={{ fontWeight: 500 }}>{"郵便番号"}</div>
                  <div className="required">{"必須"} </div>
                </Space>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="post_code"
                  rules={[
                    {
                      required: true,
                      message: "選んでください。",
                    },
                  ]}
                >
                  <Input
                    size={"large"}
                    placeholder="例）540000"
                    type="number"
                    onChange={(e) => {
                      console.log(e);
                      setInvalidZipcode("");
                      setInputZipCode(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <div className={"color-error"}>{invalidZipcode}</div>
                <div style={{ color: "#cccccc" }}>
                  郵便番号から住所が自動入力されます
                </div>
                <div style={{ color: "#cccccc", paddingBottom: 12 }}>
                  {" "}
                  ハイフンなしで記入
                </div>
              </Col>
            </Row>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col span={5}>
                <Space size={14}>
                  <div style={{ fontWeight: 500 }}>{"都道府県"}</div>
                  <div className="required">{"必須"} </div>
                </Space>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="prefectures"
                >
                  <Input size={"large"} placeholder="都道府県" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col span={5}>
                <Space size={14}>
                  <div style={{ fontWeight: 500 }}>{"市区町村"}</div>
                  <div className="required">{"必須"} </div>
                </Space>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="workplace_city"

                >
                  <Input size={"large"} placeholder="市区町村" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col span={5}>
                <Space size={14}>
                  <div style={{ fontWeight: 500 }}>{"番地以降"}</div>
                  <div className="not_required">{"任意"} </div>
                </Space>
              </Col>
              <Col span={19}>
                <Form.Item name="workplace_address">
                  <Input size={"large"} placeholder="1丁目２番地３" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form.Item>);
      case 0:
        return (<Form.Item label={<Space className="field-name" >
          会場<span className="tag-red"> 必須</span>

        </Space>}
          rules={[{ required: true, message: '空欄にすることはできません' }]} >

          <Form.Item>
            <Form.Item labelCol={{ span: 3 }} name="url_interview" rules={[
              {
                required: true,
                message: "入力してください。"
              },
              {
                type: "url",
                message: "URLフォームに入力してください"
              },
            ]} label={<Space className="field-name" >
              URL<span className="tag-red"> 必須</span>

            </Space>} >
              <Input size={"large"} placeholder="https://" />

            </Form.Item>

          </Form.Item>
        </Form.Item>);

    }
  }
  const fetchData = async () => {
    try {
      let response = await getDetailApplicant(id);
      setApplicant(response.data.applicant);
      setValueWorkFormat(response.data.applicant.status_progress_applicants[0]?.is_online)
      setTimeInterView(response.data.applicant.status_progress_applicants[0]?.time_interview)

      if (response.data.applicant.status_progress_applicants[0]?.time) {
        setListInterviewSchedule(response.data.applicant.status_progress_applicants[0]?.time)
      }else{
        setListInterviewSchedule([{ index: 0, date: moment().add(1, 'days').format("YYYY/MM/DD"), time: [{ startTime: '12:00', endTime: '12:30' }] }
      ])

      }
      let responseZip = await getAddress(response.data.applicant.status_progress_applicants[0]?.post_code)

      if (responseZip?.data?.status == 200) {
        setInvalidZipcode('')
        form.setFieldsValue({
          prefectures: responseZip.data.results[0].address1,
          workplace_city: responseZip.data.results[0].address2,
        });
      }

    } catch (err) {
      console.log(err);
    }
  };
  const [form] = Form.useForm();
  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };
  useEffect(() => {
    if (!inputZipCode) {
      return;
    }
    const zip = setTimeout(async () => {

      await getAddress(inputZipCode)
        .then((res) => {
          if (res?.data?.status == 200) {
            setInvalidZipcode('')
            form.setFieldsValue({
              prefectures: res.data.results[0].address1,
              workplace_city: res.data.results[0].address2,
            });
          }
          if (res?.data?.status == 400) {
            form.setFieldsValue({
              prefectures: "",
              workplace_city: "",

            });
            setInvalidZipcode(res?.data?.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500);

    return () => {
      clearTimeout(zip);
    };
  }, [inputZipCode]);

  useEffect(() => {
    window.localStorage.setItem("isInterview",  '1');

    fetchData();

  }, []);
  const handleSubmit = (e) => {


    setOpen(true);
    if (valueWorkFormat == 1) {

      const data = {
        address: e.after_address,
        prefectures_1: e.prefectures,
        belongings: e.belongings,
        post_code: e.post_code,
        listInterviewSchedule: listInterviewSchedule,
        online: valueWorkFormat,
        item: e.belongings,
        emergencyContact: e.emergency_contact,
        remark: e.remarks,
        time_interview: timeInterview,
        workplace_address: e.workplace_address,
        city: e.workplace_city,

      };

      setDataConfirm(data);
    }
    else {

      const data = {
        address: e.after_address,
        url_interview: e.url_interview,
        belongings: e.belongings,
        listInterviewSchedule: listInterviewSchedule,
        online: valueWorkFormat,
        item: e.belongings,
        emergencyContact: e.emergency_contact,
        remark: e.remarks,
        time_interview: timeInterview

      };
      setDataConfirm(data);
    }


  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  const handleAddSchedule = (e) => {
    setListInterviewSchedule([
      ...listInterviewSchedule,
      { index: 0, date: moment().add(1, 'days').format("YYYY/MM/DD"), time: [{ startTime: '12:00', endTime: '12:30' }] },
    ]);
  };

  const handleAddTime = (index) => {
    let listInterviewScheduleArray = [...listInterviewSchedule];
    listInterviewScheduleArray[index].time.push({ startTime: '12:00', endTime: '12:30' })
    setListInterviewSchedule(listInterviewScheduleArray);
  };

  const handleDeleteSchedule = (e, index) => {
    let newList = [...listInterviewSchedule];
    newList.splice(index, 1);
    setListInterviewSchedule(newList);
  };

  const handleDeleteTime = (key, index) => {

    let newList = [...listInterviewSchedule];

    if (newList[key].time.length - 1 < 1) {
      notiHOC('今回は削除できません', false)
      return;

    }
    newList[key].time.splice(index, 1);
    setListInterviewSchedule(newList);
  };

  const [timeInterview, setTimeInterView] = useState('30');
  const onChange = (e) => {
    setValueWorkFormat(e.target.value);
    listInterviewSchedule.map((item, key) => {

    })
  };
  const onChangeTime = (e) => {
    
    setTimeInterView(e.target.value);
 
  };

  return (
    <>
      <Header />
      <Row >

        <Row style={{ justifyContent: 'center' }}>
          <Col span={24}>
            <Breadcrumb
              style={{
                margin: "20px 80px",
              }}
            >

            </Breadcrumb>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'center', width: '100%' }}>
          <Col span={20} style={{ paddingBottom: "50px" }}>
            <Col className=" common-box" span={24}>
              <Space className="common-text">面接日程調整フォーム</Space>
              {!applicant && <LoadingScreen />}
              {applicant && <Form
                className={"formItem"}
                requiredMark={false}
                name="basic"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                colon={false}
                labelAlign="left"
                form={form}
                initialValues={{
                  post_code: applicant?.status_progress_applicants[0]?.is_online == 1 ? applicant.status_progress_applicants[0].post_code : "",
                  after_address: applicant?.status_progress_applicants[0]?.address,
                  belongings: applicant?.status_progress_applicants[0]?.belonging,
                  emergency_contact: applicant?.status_progress_applicants[0]?.emergency_contact,
                  remarks: applicant?.status_progress_applicants[0]?.remark,
                  url_interview: applicant?.status_progress_applicants[0]?.is_online == 0 ? applicant.status_progress_applicants[0].url_interview : ""
                }}

              >

                <Form.Item label>
                  <Radio.Group onChange={onChange} value={valueWorkFormat} style={{ marginBottom: '17px' }}>
                    <Radio value={1} style={{ marginRight: '120px' }} >オフライン</Radio>
                    <Radio value={0}>オンライン</Radio>
                  </Radio.Group>
                </Form.Item>
                {renderContent(valueWorkFormat)}
                <Form.Item label={<Space className="field-name">
                  面接所要時間<span className="tag-red">必須</span>
                </Space>}>
                  <Radio.Group onChange={onChangeTime} value={timeInterview}>
                    <Radio  value={'30'} style={{ marginRight: '50px' }} >30分</Radio>
                    <Radio value={'60'}>60分</Radio>
                  </Radio.Group>

                </Form.Item>






                <Form.Item style={{ paddingTop: '20px' }} label={<Space className="field-name">
                  面接日程候補<span className="tag-red">必須</span>
                </Space>} name="belongings"
                >
                  <Col span={24} >
                    {listInterviewSchedule?.map((record, index: number) => {
                      return (
                        <Row key={index} style={{ alignItems: "start", marginTop: '60px', }}>
                          <Col span={3} >
                            面接日程 {index + 1}
                          </Col>
                          <Col span={16} style={{}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>


                              <Col span={10} >
                                <Form.Item label={"日程"} rules={[{ required: true, message: 'このフィールドを空白のままにしないでください' }]} wrapperCol={{ span: 21 }}
                                  labelCol={{ span: 3 }}>
                                  <DatePicker disabledDate={disabledDate} size={"large"} style={{ width: '100%' }}
                                    defaultValue={record.date ? moment(record.date, dateFormat) : null}
                                    onChange={(date, dateString) => {

                                      const listInterviewDate = [...listInterviewSchedule]
                                      
                                      listInterviewDate[index].date = dateString
                                      setListInterviewSchedule(listInterviewDate)
                                    }}

                                    format={dateFormat}

                                  />
                                </Form.Item>


                              </Col>


                              <Col  style={{ alignItems: 'center', }}>
                                {

                                  listInterviewSchedule[index].time.map((times, key) => {
                                    return <Col style={{ display: 'flex', alignItems: 'start' }} key={key}>
                                      <Col > 
                                      <Form.Item label={"時間"}  labelCol={{ span: 4 }}
                                        wrapperCol={{ span: 19 }}
                                      >
<div style={{display:'flex'}}>
<Select
             onChange={(e) => {
         
              const listInterviewDate = [...listInterviewSchedule]

              const timePicker1 = { startTime: e, endTime: listInterviewDate[index].time[key].endTime}
              listInterviewDate[index].time[key] = timePicker1
            
              setListInterviewSchedule(listInterviewDate)
              
     
            }}
                                           style={{width:'100px'}}
              placeholder={"時間"}
              size="large"
  
              defaultValue={times.startTime ? times.startTime : '12:00'}
            >
              {arrTime.map((el) => {
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {el.name}
                  </Select.Option>
                );
              })}
            </Select>
                                        <span style={{ margin: '0 5px', fontSize: '24px' }}>~</span>

                                                                         
                                                                            <Select
              placeholder={"時間"}
              size="large"
              value={listInterviewSchedule[index].time[key].endTime}
              onChange={(e) => {
                console.log('huhuhuhu',(moment(listInterviewSchedule[index].time[key].startTime, "HH:mm").minute() + 60 * moment(listInterviewSchedule[index].time[key].startTime, "HH:mm").hour()));
                console.log('kkokokok',listInterviewSchedule[index].time[key].startTime);
                
                if(((moment(e, "HH:mm").minute() + 60 * moment(e, "HH:mm").hour())-(moment(listInterviewSchedule[index].time[key].startTime, "HH:mm").minute() + 60 * moment(listInterviewSchedule[index].time[key].startTime, "HH:mm").hour()))<30){                                         
                  notiHOC('30分以上の時間を設定する必要があります',false)
                  const listInterviewDate = [...listInterviewSchedule]

                   const timePicker0 = { startTime: [...listInterviewSchedule][index].time[key].startTime, endTime:moment.utc([...listInterviewSchedule][index].time[key].startTime, 'HH:mm').add(30, 'minutes').format('HH:mm') }
                   listInterviewDate[index].time[key] = timePicker0
                   setListInterviewSchedule(listInterviewDate)
                   return
                }
                else{
                  const listInterviewDate = [...listInterviewSchedule]
                const timePicker1 = { startTime: listInterviewDate[index].time[key].startTime, endTime:e }           
                listInterviewDate[index].time[key] = timePicker1  
                setListInterviewSchedule(listInterviewDate)
                }
       
              }}
              style={{width:'100px'}}
            >
              {arrTime.map((el) => {
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {el.name}
                  </Select.Option>
                );
              })}
            </Select>
</div>
                                      </Form.Item>



                                      </Col>
                                      <Col style={{ position: 'absolute', right: '-30px', marginTop: '10px' }} >
                                        <MinusCircleOutlined style={{ fontSize: '17px', cursor: 'pointer', marginLeft: '20px' }} onClick={(e) => {
                                          e.preventDefault();
                                          handleDeleteTime(index, key);
                                        }} />
                                      </Col>





                                    </Col>

                                  })}
                                <Form.Item label={<span></span>} labelCol={{ span: 3 }}
                                  wrapperCol={{ span: 21 }}
                                >
                                  <Col
                                    className="w-full"
                                    span={24}
                                    onClick={() => {
                                      handleAddTime(index)
                                    }}

                                    style={{
                                      marginTop:
                                        listInterviewSchedule[index].time.length > 0 ? "20px" : 0,
                                    }}
                                  >
                                    <Col className="btn-more-add"   >
                                      <Col className="items-center">
                                        <PlusIcon />
                                      </Col>
                                      <Col className="m-l-4">
                                        時間帯を追加
                                      </Col>
                                    </Col>
                                  </Col>
                                </Form.Item>
                              </Col>



                            </div>

                          </Col>

                          <Col span={4}  >
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteSchedule(e, index);
                              }}
                              style={{ cursor: "pointer", float: 'right', backgroundColor: '#00c4cc', padding: '1px 5px', fontSize: '13px', color: 'white' }}
                            >
                              削除

                            </a>

                          </Col>



                        </Row>
                      );
                    })}
                  </Col>
                  <Col
                    className="w-full"
                    onClick={handleAddSchedule}
                    style={{
                      marginTop:
                        listInterviewSchedule.length > 0 ? "40px" : 0,
                    }}
                  >
                    <Col className="btn-more-add" style={{ margin: 0 }}>
                      <Col className="items-center">
                        <PlusIcon />
                      </Col>
                      <Col className="m-l-4">
                        日程を追加

                      </Col>
                    </Col>
                  </Col>
                </Form.Item>




                <Form.Item style={{ paddingTop: '20px' }} label={<Space className="field-name" >
                  持ち物<span className="tag-gray">任意</span>
                </Space>} name="belongings"
                >
                  <Input placeholder="（例）筆記用具、メモ帳" size={"large"}
                  />
                </Form.Item>
                <Form.Item style={{ paddingTop: '20px' }} label={<Space className="field-name" >
                  緊急連絡先<span className="tag-red">必須</span>

                </Space>} name="emergency_contact"
                  rules={[{ required: true, message: '空欄にすることはできません' }]} >

                  <Input placeholder="（例）080-0000-0000（担当者名：山田）" size={"large"}
                  />
                </Form.Item>
                <Form.Item style={{ paddingTop: '20px' }} label={<Space className="field-name" >
                  備考<span className="tag-gray">任意</span>

                </Space>} name="remarks"
                >

                  <Input.TextArea style={{ backgroundColor: 'unset' }} rows={3}

                    showCount={{
                      formatter: ({ count, maxLength }) => ` ${count}/ ${maxLength} 文字`,
                    }}
                    placeholder="テキストエリア" size={"large"} maxLength={1000}
                  />
                </Form.Item>

                <Row
                  style={{ justifyContent: "center", marginTop: '20px' }}
                >

                  <Col >


                    <Button
                      htmlType="submit"
                      style={{
                        color: "white",
                        background: "#00c4cc",
                        borderRadius: "999px",
                        height: "55px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "180px",
                        fontWeight: "bold",
                        marginLeft: '10px'
                      }}
                    >
                      入力内容を確認する
                    </Button>

                  </Col>
                </Row>

              </Form>}
            </Col>
          </Col>
        </Row>
      </Row>
      <ScheduleConfirmModal
        setOpen={setOpen}
        open={open}
        dataConfirm={dataConfirm}
        applicant={applicant}
      />
    </>
  );
};

export default InterviewSchedule;
