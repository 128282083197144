/* eslint-disable react/prop-types */
import { Fragment } from "react";
import StatusNameAndCounter from "../Applicants/component/StatusNameAndCounter";

const ProcItem = ({
  status,
  listStatus,
  index,
  handleDelete,
  setListStatus,
}) => {
  const moveUp = (currentIndex) => {
    listStatus.map((currentStatus, index) => {
      if (currentIndex - 1 === index) {
        if (index !== 0) {
          const old_index = currentStatus.index;
          currentStatus.index = listStatus[index - 1].index;
          listStatus[index - 1].index = old_index;
          const before = listStatus[index];
          const after = listStatus[index - 1];
          listStatus.splice(index - 1, 1, before);
          listStatus.splice(index, 1, after);
        }
      }
    });
    const arr = [];
    listStatus.map((status, i) => {
      status.index = i + 1;
      arr.push(status);
    });
    setListStatus(arr);
  };

  const moveDown = (currentIndex) => {
    listStatus.map((currentStatus, index) => {
      if (currentIndex === index + 1) {
        if (index !== listStatus.length - 1) {
          const old_index = currentStatus.index;
          currentStatus.index = listStatus[index + 1].index;
          listStatus[index + 1].index = old_index;
          const before = listStatus[index];
          const after = listStatus[index + 1];
          listStatus.splice(index, 1, after);
          listStatus.splice(index + 1, 1, before);
        }
      }
    });
    const arr = [];
    listStatus.map((status, i) => {
      status.index = i + 1;
      arr.push(status);
    });
    setListStatus(arr);
  };

  const changeName = (e, index) => {
    const statusName = e.target.value;
    listStatus.map((status) => {
      if (status.index == index) {
        status.name = statusName;
      }
    });
    setListStatus(listStatus);
  };

  return (
    <>
      {status && status.deleted_at == null && (
        <Fragment>
          {status.name !== null && (
            <li>
              <div className="company-ttl-item">
                <div className="" style={{ width: "50%" }}>
                  {([
                    "応募",
                    "書類選考合格",
                    "一次面接合格",
                    "二次面接合格",
                    "選考完了",
                  ].includes(status.name) &&
                    status.name) || (
                    <>
                      <StatusNameAndCounter
                        index={status.index}
                        name={status.name}
                        changeName={changeName}
                      />
                      {/* <input
                        type="text"
                        placeholder="選考プロセス名"
                        name=""
                        maxLength={10}
                        onChange={(e) => {
                          changeName(e, status.index);
                        }}
                        defaultValue={status?.name}
                      />
                      <p className="txt-link-gray">最大10文字</p> */}
                    </>
                  )}
                </div>
                <div className="company-btn">
                  <img
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    src={require("../../../img/common/icon-down.png")}
                    onClick={() => moveDown(status.index)}
                  />
                  <img
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    src={require("../../../img/common/icon-up.png")}
                    onClick={() => moveUp(status.index)}
                  />
                  {(![
                    "応募",
                    "書類選考合格",
                    "一次面接合格",
                    "二次面接合格",
                    "選考完了",
                  ].includes(status.name) && (
                    <a href="">
                      <img
                        src={require("../../../img/common/icon-close.png")}
                        style={{ marginLeft: "10px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(e, status.index);
                        }}
                      />
                    </a>
                  )) || (
                    <div style={{ width: "25px", marginLeft: "10px" }}></div>
                  )}
                </div>
              </div>
            </li>
          )}
          {status.name == null && (
            <li>
              <div className="company-ttl-item">
                <div className="" style={{ width: "50%" }}>
                  <StatusNameAndCounter
                    name={""}
                    index={status.index}
                    changeName={changeName}
                  />
                </div>
                <div className="company-btn ">
                  <img
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    src={require("../../../img/common/icon-down.png")}
                    onClick={() => moveDown(status.index)}
                  />
                  <img
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    src={require("../../../img/common/icon-up.png")}
                    onClick={() => moveUp(status.index)}
                  />
                  <a href="">
                    <img
                      style={{ marginLeft: "10px" }}
                      src={require("../../../img/common/icon-close.png")}
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(e, status.index);
                      }}
                    />
                  </a>
                </div>
              </div>
            </li>
          )}
        </Fragment>
      )}
    </>
  );
};

export default ProcItem;
