import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Router from "../../config/route";
import { Row,Col} from 'antd';

const Director = ({}) => {
  // const [messages, setMessages] = useState<Array<Message>>([]);
  return (
    <>
      <div className="bg-white has-shadow-top" style={{ padding: "60px 0" }}>
        <Row
    gutter={30}
    style={{justifyContent:'center'}}
        >
      
            <ul className="d-f top-menu">
         <Col className="gutter-row">
         <Link to={Router.jobs} className="top-menu-item link-hover-yl">
                <li>求人管理</li>
              </Link>
         </Col>
         <Col className="gutter-row">
              <li className="top-menu-item">
                <div className="ta-c">
                  <div className="" style={{ color: "white" }}>
                    <FontAwesomeIcon
                      icon={faUserPen as IconProp}
                      className="mr-4 "
                    />
                    応募者管理
                  </div>
                  <Link
                    to={Router.applicants}
                    className="bg-light-blue color-blue ptb-4 plr-12 bdrs-4 mtb-8 link-hover-yl"
                  >
                    <button className="d-b w-100per ta-c">応募者一覧</button>
                  </Link>
                  <Link
                    to={Router.message_templates}
                    className="bg-light-blue color-blue p-4 plr-12 bdrs-4 mb-8 link-hover-yl"
                  >
                    <button className="d-b w-100per ta-c">
                      文章テンプレート管理
                    </button>
                  </Link>
                  <Link
                    to={Router.processes}
                    className="bg-light-blue color-blue p-4 plr-12 bdrs-4 link-hover-yl"
                  >
                    <button className="d-b w-100per ta-c">
                      選考プロセス管理
                    </button>
                  </Link>
                </div>
              </li>
              </Col>
              <Col className="gutter-row">
              <Link to={Router.jobs} className="top-menu-item link-hover-yl">
                <li>企業情報管理</li>
              </Link>
              </Col>
              <Col className="gutter-row">
              <Link to={Router.help} className="top-menu-item link-hover-yl">
                <li>ヘルプ</li>
              </Link>
              </Col>
            </ul>
          
        </Row>
      </div>
    </>
  );
};

export default Director;