import { lazy } from "react";
const New = lazy(() => import("./createNewJob"));
const List = lazy(() => import("./listJob"));
const Edit = lazy(() => import("./EditJob"));
const Anaysis = lazy(() => import("./Anaysis"));
const CSV = lazy(() => import("./JobCSV/index"));
const Report = lazy(() => import("./Report"));


export default { New, List, Anaysis, Report, CSV, Edit, };
