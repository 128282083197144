import {  createReducer } from '@reduxjs/toolkit'
import { openModal, closeModal,setSubmitModal } from '../action/modalAction';

const initialState = {
     visibleModal:false,
     ComponentContenModal:<p>Modal default</p>,
     title:'',
     heightModal:'',
     width:1000,
     callBackSubmit: () => {alert('jgidf') }
 }
 
 
 const modalHOCReducers= createReducer(initialState, (builder) => {
  builder
    .addCase(openModal, (state, action) => {
      state.visibleModal = true;
      state.ComponentContenModal = action.payload.Component;
      state.width = action.payload.width;
      state.title = action.payload.title;

      
        })
    .addCase(closeModal, (state, action) => {
      state.visibleModal = false;

    })
    .addCase(setSubmitModal, (state, action) => {
      state.callBackSubmit = action.payload.callBackSubmit;

    })
})
 
 
 export default modalHOCReducers