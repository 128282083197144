import {
  faRefresh,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { readMessage } from "../../api/service/applicant";
import TextReceive from "../../components/chats/TextReceive";
import TextSend from "../../components/chats/TextSend";
import Router from "../../config/route";
import {  Input} from 'antd';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
const { TextArea } = Input;

const firebaseDB = getDatabase();
const Conversation = () => {
  const [conversation, setConversation] = useState(null);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(null);
  const firebaseAuth = getAuth();
  const conversationRef = useRef(null);
  const navigate = useNavigate();
  var fullHref = window.location.href;
  var urlOrigin = window.location.origin + "/conversation/";
  var strData = fullHref.replace(urlOrigin, "");
  console.log("strData", strData);
  console.log("conversation", conversation);

  var arrData = strData.split("-");
  const userId = arrData[0];
  const partnerId = arrData[1];
  const roomId = userId + "-" + partnerId;
  const scrollToBottom = () => {
    conversationRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const data = {
      message: message,
      timeSend: new Date().getTime(),
      isUser: false,
    };

    if (data.message.trim() != "") {
      set(
        ref(
          firebaseDB,
          `conversations/${userId}-${partnerId}/${data.timeSend}`
        ),
        data
      );
      setMessage("");
    } else {
      console.log("data is empty");
    }
  };
  const auth = getAuth();

  const read = async () => {
    if (email) {
      await readMessage({ email });
    }
  };
  useEffect(() => {
    read();
  }, [email]);

  useEffect(scrollToBottom, [conversation]);
  useEffect(() => {
    onValue(ref(getDatabase(), "conversations"), (snapshot) => {
      if (snapshot.val()) {
        var obj = snapshot.val();
        var arr_conv = [];
        var obj_conv = {};
        Object.keys(obj).forEach(function (key) {
          if (key === roomId) {
            obj_conv = obj[key];
          }
        });
        Object.keys(obj_conv).forEach(function (key) {
          arr_conv.push(obj_conv[key]);
        });
        if (arr_conv.length > 0) {
          setConversation(arr_conv);
        }
      }
    });
    onAuthStateChanged(auth, (user) => {
      setEmail(user.email);
    });
  }, []);

  const onPressEnter = (evt) => {
    if (evt.code == "Enter" && evt.shiftKey == false) {
      handleSubmit(evt);
    }
  };

  const logoutFirebase = (evt) => {
    console.log("logout");

    navigate(-1);
    // signOut(firebaseAuth)
    //   .then(() => {
    //     console.log("Sign-out successful.");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  return (
    <Box
      className="chat-box"
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      boxShadow={"0 2px 20px 0 rgba(66, 86, 105, .1)"}
    >
      <Box
        p={2}
        bgcolor={"#fff"}
        top={0}
        borderBottom={"1px solid #dee2e6"}
        borderLeft={"1px solid #dee2e6"}
        borderRight={"1px solid #dee2e6"}
        display={"flex"}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography component={"p"} fontWeight={"bold"} maxWidth={"75%"}>
          {arrData[2]}
        </Typography>
        <FontAwesomeIcon
          icon={faRightFromBracket as IconProp}
          className="pointer"
          onClick={logoutFirebase}
        />
      </Box>
      <Box
        flex={1}
        borderLeft={"1px solid #dee2e6"}
        borderRight={"1px solid #dee2e6"}
        p={2}
        bgcolor={"#fff"}
        overflow={"auto"}
      >
        {conversation &&
          conversation?.map((conv, ind) => {
            if (conv?.isUser == false) {
              return (
                <TextSend text={conv.message} time={conv.timeSend} key={ind} />
              );
            } else {
              return (
                <TextReceive
                  text={conv.message}
                  time={conv.timeSend}
                  key={ind}
                />
              );
            }
          })}
        <div className="" ref={conversationRef}></div>
      </Box>
      <Box
        p={2}
        borderTop={"1px solid #dee2e6"}
        borderLeft={"1px solid #dee2e6"}
        borderRight={"1px solid #dee2e6"}
        bgcolor={"#fff"}
      >
        <form onSubmit={handleSubmit}>
          <Box
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextArea
              name="message"
              id=""
              placeholder={
                "テキストを入力して送信をクリック"
              }
              className="message-box"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              onKeyDown={onPressEnter}
            ></TextArea>
            <Button
              type="submit"
              sx={{
                color: "white",
                backgroundColor: "#00c4cc",
                border: "none",
                borderRadius: "100vh",
                py: 1,
                fontSize: "16px",
                fontWeight: "bold",
                transitions: "all ease-in-out .4s",
                marginLeft: 2,
                width: "133px",
                height: 40,
                "&:hover": {
                  color: "white",
                  backgroundColor: "#00c4cc",
                  opacity: ".7",
                },
              }}
            >
              送信
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default Conversation;
