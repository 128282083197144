// eslint-disable-next-line max-lines-per-function
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Router from "../../../../config/route";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import {  useContext } from "react";
import { AuthContext } from "../../../../hooks/useAuth";
import moment = require("moment");
import arrayListSalaryTypes from "../../../../components/dataArrayUseInProject/arrayListSalaryTypes";
import { Select } from "antd";
import { notiHOC } from "../../../../HOC/NotiHOC";
import arrayStatusProgressApplicant from "../../../../components/dataArrayUseInProject/arrayStatusProgressApplicant";
import { updateApplicant } from "../../../../api/service/applicant";
import ChatBox from '../../component/ChatBox'

export const defineColumnTableListApplicants = (
  fetchDataForTable,
  listData,
  setApplicantId, setOpenChat

) => {


  const { user } = useContext(AuthContext);
  
  return [
    {
      title: "応募日時",
      dataIndex: "apply-time",
      sorter: true,
      key: 1,
      render:(_, record)=>{
        return <div>
          {record.created_at? moment(record.created_at).format("YYYY/MM/DD hh:mm") :
                          ''}
        </div>
      }
    },
    {
      title: "応募者／メッセージ",
      render:(_, record)=>{
        return ( <div className="box-applicant-info" >
        <div style={{ flex: 1, marginRight: "20px" }}>
          <Link
            to={`${Router.applicants_detail}/${record.id}`}
            className="color-blue bold"
            style={{
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              position: "relative",
              paddingRight: "20px",
            }}
          >
            {record.first_name_kanji + " " + record.last_name_kanji}
            <FontAwesomeIcon
              icon={faPen as IconProp}
              className="color-blue ml-4"
              style={{
                position: "absolute",
                top: "50%",
                right: "0",
                transform: "translateY(-50%)",
              }}
            />
          </Link>
          <div>
          性別:{" "}
            {(record.gender == 0 && "男性") ||
              (record.gender == 1 && "女性") ||
              "その他"}
          </div>
          <div>TEL: {record.phone}</div>
        </div>
        <div>
          <ChatBox applicant={record}/>

        
          <Link
            to={`${Router.interview_schedule}/${record.id}`}
        
            className="applicant-button applicant-btn-info bold"  
          >
            フォーム送信
          </Link>
        </div>
      </div>)
      }
    },
    {
      title: "年齢",
      sorter: true,
      key:11,
      dataIndex:'birthday',
      render:(_, record)=>{
        return <div> {record.birth_day_year ? (new Date().getFullYear()-parseInt(record.birth_day_year,10)):""}歳</div>
      }
    },
    {
      title: "選考ステータス",
      render: (_, record) => {
        return (
          <div>
            <Select
              size={"large"}
              style={{ width: "100%" }}
              placeholder={"職種カテゴリ"}
              value={record.status_applicant}
              onChange={async (e) => {
                
                const response = await updateApplicant(record.id,{
                  status_applicant: e,
                } );
                if (response.status == 200) {
                  notiHOC("正常に更新されました。", true);
                  fetchDataForTable();
                } else {
                  notiHOC("更新に失敗しました。", false);
                }
              }}
            >
              {arrayStatusProgressApplicant.map((el) => {
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {el.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        );
      },
    
    },
    
    {
      title: "応募求人",
      render: (_, record)=>{
       return <Link to={`/jobs/edit/${record.job_work_id}`}  className="color-blue bold">{record.job_name}</Link>
      }
    },
    {
      title: "掲載企業",
      dataIndex: "listed_company_name",

      render: (_, record) => (
        <div
          className="bold color-blue "
          style={{ width: 300, whiteSpace: "pre-line" }}
        >
          {record.listed_company_name}
        </div>
      ),
    },
    {
      title: "都道府県",
      dataIndex: "workplace_prefecture",

    },
    {
      title: "市区町村",
      dataIndex: "workplace_city",

    },
    {
      title: "職種カテゴリ",
      dataIndex: ["category_name"],
    },
    {
      title: "雇用形態",
      dataIndex: ["employment_type_name"],
    
    },
    {
      title: "給与形態",
      render: (_, record) => (
        <div>
          {
            arrayListSalaryTypes.filter((el) => el.id == record.salary_type)[0]
              ?.name
          }
        </div>
      ),
    },
    
    {
      title: "面接日程",
      key:7,
      dataIndex:'interview-schedule',
      sorter: true,
      render:(_,record)=>{
        return <div>{record.interview_schedule?moment(record?.interview_schedule).format(
          "YYYY/MM/DD hh:mm"
        ):'-' }</div>
      }
    },
    {
      title: "メモ",
      dataIndex:'memo'
    },
  ];
};
