// eslint-disable-next-line max-lines-per-function
import { Link } from "react-router-dom";
import Router from "../../../../config/route";


import { formatPhoneNumber } from 'react-phone-number-input';


export const defineColumnTableListAccount = (
  fetchDataForTable,
  listData,


) => {


  
  return [
    {
      title: <p style={{color:'#555555',fontWeight:'700', fontSize:'bold',marginBottom:'0px'}}>氏名</p>,
      dataIndex: "name",
   
      width: '30%',
      key: "name",
      render:(_, record)=>{
        return <div>

<Link
            to={`${Router.account_detail}/${record.id}`}
            className="color-blue bold"
            style={{
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              position: "relative",
              paddingRight: "20px",
            }}
          >
           {record.name}
        
          </Link>
     
        </div>
      }
    },
    {
      title:<p style={{color:'#555555',fontWeight:'700', fontSize:'bold',marginBottom:'0px'}}>直通電話番号</p> ,
      dataIndex: 'phone',

      key: 'phone',
      render:(_, record)=>{
        return ( <div >
       
       {formatPhoneNumber(record.phone) || record.phone}
      </div>)
      }
    },
    {
      title:<p style={{color:'#555555',fontWeight:'700', fontSize:'bold',marginBottom:'0px'}}>メールアドレス</p> ,

      key:'email',
      dataIndex:'email',
      render:(_, record)=>{
        return ( <div >
       
       {record.email}
                </div>)
      }
    },
    {
      title:<p style={{color:'#555555',fontWeight:'700', fontSize:'bold',marginBottom:'0px'}}>権限</p>,
      key:'role',
      dataIndex:'role',
      render: (_, record) => {
        return (
          <div>
          {record.role=="SUB_ADMIN_HR"?"メンバー":"マスター"}
          </div>
        );
      },
    
    },
    
  ];
};
