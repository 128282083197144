import { useNavigate } from 'react-router-dom';
import { getPageQuery } from './utils';


export function getAuthority(str?: string) {
  const authorityString =
    typeof str === 'undefined' && localStorage
      ? localStorage.getItem('access-token')
      : str; // authorityString could be admin, "admin", ["admin"]
  let authority;

  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {
    authority = authorityString;
  }

  if (typeof authority === 'string') {
    return [authority];
  }

  return authority;
}
export function removeAuthority() {
  console.log('hihi');
  localStorage.removeItem('access-token');
  const navigate = useNavigate();
  const { redirect } = getPageQuery();
  if (!window.location.pathname.includes('/login') && !redirect) {
 console.log('out out');
 navigate('/login')
  }
}
