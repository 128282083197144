
import { Form, Image, Space } from 'antd';
import { useEffect, useState } from 'react';
import ModalImageStorage from '../../components/ModalSelectImage/ModalImageStorage';
const FormImage = ({form,dataPost }) => {
     const [imageUrl, setImageUrl] = useState("");


     useEffect(() => {
         if(dataPost){
          setImageUrl(dataPost.image)
         }
          
          
        }, [dataPost]);


  return (
     <>
<Form.Item 
          label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>{"ロゴ画像"}</div>
              <div className="required">{"必須"} </div>
            </Space>
          }
        >
          <Form.Item
            name="main_image"
      
          >
            <div>
              {!imageUrl ? (
                <ModalImageStorage
                  imgAspectRatio={16 / 9}
                  hanldeSelectImage={(url, id) => {
                    setImageUrl(url);


                    form.setFieldsValue({
                         main_image:url,
                      
                       });
                   
                  }}
                />
              ) : (
                <div
                  className="img-up-item"
                  style={{ width: 92, position: "relative" }}
                >
                  {imageUrl ? (
                    <img
                      className="close"
                      src={require("../../../img/common/icon-close.png")}
                      onClick={() => {
                        setImageUrl("");
                        form.setFieldsValue({
                         main_image:""
                      
                       });
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <div className="img">
                    <label>
                      {imageUrl ? (
                        <Image
                          src={imageUrl ? imageUrl : ""}
                          style={{ width: 90 }}
                        />
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              )}
            </div>
          </Form.Item>
    
          <p className="txt-link-gray">
            ファイル形式：JPEG、JPG、PNG
            <br />
            ファイルサイズ：10MB以下
          </p>
        </Form.Item>
   </>

  );
};

export default FormImage;
