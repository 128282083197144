import React = require("react");
import { Link, useNavigate } from "react-router-dom";
import Router from "../config/route";
import {
  faBuilding,
  faCircleUser,
  faFileAlt,
 
} from "@fortawesome/free-solid-svg-icons";

import { AuthContext, useAuth } from "../hooks/useAuth";
import { logout } from "../api/session";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { HelpContext } from "./Help/HelpComponents/HelperContext";
import { Menu, Dropdown } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { SubMenu } = Menu;

const Header = () => {
  const { user } = React.useContext(AuthContext);
console.log('fsfd',user);

  const { searchTerm, setSearchTerm } = React.useContext(HelpContext);
  const navigate = useNavigate();

  window.localStorage.setItem("companyId", user?.company_id?.toString());

  const currentLocation = window.location.href;
  const auth = useAuth();
  const onLogout: React.MouseEventHandler<HTMLButtonElement> = (_evt) => {
    logout().then((_resp) => {
      auth.signout(() => {
        // do nothing
      });
    });
    window.localStorage.removeItem("access-token");
  };



  const menu = (
    <Menu
      items={[
        {
          label: (
            <div className="-line-wrapper pt-2">
              <button
                className="-button-support"
                onClick={() => {
                  navigate(Router.set_new_password);
                }}
              >
                <div className="text-center flex-1" style={{ fontWeight: 500 }}>
                  {"パスワード変更"}
                </div>
              </button>
            </div>
          ),
          key: "0",
        },
        {
          label: (
            <div className="-line-wrapper pt-2">
              <button
                className="-button-support"
                onClick={(e) => {
                  onLogout(e);
                }}
              >
                <div className="text-center flex-1" style={{ fontWeight: 500 }}>
                  {"ログアウト"}
                </div>
              </button>
            </div>
          ),
          key: "1",
        },
      ]}
    />
  );

  return (
    <div
      style={{
        background: "#00C4CC",
        padding: "13px 0",
        zIndex: 3,
        position: "sticky",
        color: "rgb(255, 255, 255)",
        top: "0px",
        left: "auto",
        right: "0px",
        display: "flex",
        alignItems: "center",
        height: 60,
      }}
      className="fixNavbar"
    >
      <div
        style={{
          maxWidth: "1440px !important",
          padding: "0px 40px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems:'center'
        }}
      >
<h1 onClick={()=>{
  navigate("/");
}}
style={{cursor:'pointer',fontSize:'25px',  color: "#fff",textAlign:'center',marginBottom:'0px',fontWeight:'700',letterSpacing:'0.4px'}}>
受付求人.List
</h1>

        {(user.role==="ADMIN_HR" ||user.role==="SUB_ADMIN_HR")  && (
          <div style={{ display: "flex", flex: 1 }}>
            <Menu
              defaultSelectedKeys={[""]}
              selectedKeys={
                (currentLocation.match("applicants") && ["8"]) ||
                (currentLocation.match("processes") && ["10"]) ||
                (currentLocation.match("message_templates") && ["9"]) || [""]
              }
              mode="horizontal"
              style={{
                backgroundColor: "rgb(0, 196, 204)",
                color: "#fff",
                width: "100%",
                border: "none",
                marginLeft: 40,
              }}
            >
             
                <>
                  {" "}
             
                  <SubMenu
                    key="sub198"
                    title={
                      <Link to={Router.jobs}>
                       <span
                        className={`mr-4 text-header ${
                          currentLocation.match("jobs") || currentLocation.match("jobs/csv")   !== null
                            ? "color-focus"
                            : ""
                        }`}
                      >
                      <FontAwesomeIcon
                          icon={faFileAlt as IconProp}
                          className="mr-4"
                        />
                        求人管理
                      </span>
                      </Link>
                    }
                  >
            
                    <Menu.Item key="11">
                      {" "}
                      <Link to={Router.jobs_csv}>
                        <div className="ml-2">求人CSV入稿</div>
                      </Link>
                    </Menu.Item>
            
               
                  </SubMenu>


               
                </>
      
         
                <Menu.Item key="2" className="hover:bg-rose-100">
                    <Link
                      to={Router.company_detail}
                      style={{ fontSize: 16, fontWeight: 500 }}
                    >
                      <span
                        className={`mr-4 text-header ${
                          currentLocation.match("company") !== null
                            ? "color-focus"
                            : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faBuilding as IconProp}
                          className="mr-4"
                        />
                        企業ページ編集
                      </span>
                    </Link>
                  </Menu.Item>

              
              
 
<SubMenu
                    key="sub199"
                    title={
                      <Link to={Router.setting}>
                       <span
                        className={`mr-4 text-header ${
                          currentLocation.match("account/new")||currentLocation.match("setting")  !== null
                            ? "color-focus"
                            : ""
                        }`}
                      >
                 <FontAwesomeIcon
                          icon={faBuilding as IconProp}
                          className="mr-4"
                        />
                        設定
                      </span>
                      </Link>
                    }
                  >
                    <Menu.Item key="11">
                      {" "}
                      <Link to={Router.new_account}>
                        <div className="ml-2">アカウント一覧</div>
                      </Link>
                    </Menu.Item>
               
                  </SubMenu>






            </Menu>
          </div>
        )}
       
       
       
       
       
       
       
       
        {user && (
          <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faCircleUser as IconProp}
                style={{ width: 20 }}
                className="color-white mr-4 color-check"
              />
              <div className="user-name">{user?.name || null}</div>
              <div className="has-arrow-down"></div>
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  );
};
export default Header;
