import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { useRef, useState } from "react";
import { Link, useNavigate, useLocation, Location } from "react-router-dom";
import { login } from "../../api/session";
import { useAuth } from "../../hooks/useAuth";
import  './Login.scss';
interface LoginLocation extends Location {
  state: { from?: Location };
}

const Login = function () {
  const location: LoginLocation = useLocation();
  const [loginStatus, setLoginStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [remember, setRemember] = useState(false);

  const [errorsMessage, setErrorMessage] = useState([]);
  const [errorsMessagePassword, setErrorMessagePassword] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();
  const from = location.state?.from?.pathname || "/";

  const submitLogin = (e) => {
    setErrorMessage([]);
    setErrorMessagePassword("");
    setSubmitting(true);
    login(
       e.email,
        e.password
    ).then((body: any) => {
      if(body.success == false){
        setLoginStatus(false);
        setSubmitting(false);


if (body.error.includes("Verify fail")) {
  setErrorMessage(["アカウントを承認する必要がありますメールでverifyしてください"]);
  return;
}
        if (body.error.password) {
          setErrorMessagePassword(body.error.password[0]);
        } else {
          const mess = body.error.split("。");
          mess.length = mess.length - 1;
          console.log('koookokokssssssssssss',mess
          )
          setErrorMessage(mess);
          setIsLoading(false);
        }
        setIsLoading(false);
        return
      }
      if(body.data?.role=='ADMIN'){
        setErrorMessage(['ログインしているアカウントは承認されていません']);
        setIsLoading(false);
        return
      }
      if (body.success !== false) {
     
          window.localStorage.setItem("access-token", body?.access_token);
if(remember){
  window.localStorage.setItem("email", body?.data?.email);
  window.localStorage.setItem("password",   e.password);
  window.localStorage.setItem("remember",  remember.toString());
}
          
        

        auth.signin(body.data, () => {
          navigate(from, { replace: true });
          if(localStorage.getItem('isInterview')=='1'){
            navigate(-1)
          }
        });
      } 
    });
    return false;
  };
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState(() => {
    const email = localStorage.getItem('email') || '';
    const password = localStorage.getItem('password') || '';
    const remember = Boolean(localStorage.getItem('remember'))

    return { email, password,remember };
  });
  const ref = useRef();

  return (
    <div style={{ width: '500px',

      padding: '30px 50px',position:'absolute', top:'50%',left:'50%',transform: 'translate(-50%,-50%)'}}
      >
        <Form
      name="basic"
      initialValues={initialValues}
      onFinish={submitLogin}
      onChange={() => {
        setSubmitting(false);
      }}
      form={form}
      ref={ref}
    >
      <div style={{ marginBottom: '50px' }}>
        <div style={{fontWeight:'700'}} className={'title'}>{'受付求人List 掲載管理画面'}</div>
      </div>
      {errorsMessage.length > 0 && (
        <>
          <p className={'alertMessage'}>{errorsMessage}</p>
        </>
      )}
           <Form.Item
        name="email"
        label=""
        rules={[
          { type: 'email', message: '有効なメールアドレスではありません' },
          { required: true, message: 'メールアドレスを入力してください' },
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder={'メールアドレス'}
          className={'emailInput'}
        />
      </Form.Item>
      <Form.Item
      style={{marginBottom:'10px'}}
        name={'password'}
        rules={[
          {
            required: true,
            message: `パスワードを入力してください。`,
          },
          {
            min: 8,
            message: `パスワードは8文字以上である必要があります。`,
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={'パスワード'}
          className={'passwordInput'}
        />
      </Form.Item>
<Form.Item name={'remember'} valuePropName="checked" style={{marginBottom:'10px'}} >
<Checkbox  onChange={(e)=>{
      setRemember(e.target.checked)
      window.localStorage.setItem("remember",  e.target.checked.toString());

      }} >ログイン情報を保存する</Checkbox>
</Form.Item>
      <div>
        <Button
          block
          htmlType="submit"
          type="primary"
          loading={submitting}
          className={'submitButton'}
        >
         {'ログイン'}
        </Button>
        <div style={{ display: 'flex', justifyContent: 'end ' }}>
          <Link className={'forgotPassword'} to={'/forgot_password'}>
            {'パスワードを忘れた方はこちら'}
          </Link>
        </div>
      </div>
    </Form>
    </div>
  );
};

export default Login;
