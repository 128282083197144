import { useContext } from "react";
import NotFound from "../components/Common/NotFound";
import { AuthContext } from "./useAuth";

// const RoleAdmin = ({ children }: { children: React.ReactNode }) => {
//   const { user } = useContext(AuthContext);
//   // console.log("user nè", user);
//   if (user.role === "ADMIN") {
//     return <>{children}</>;
//   } else {
//     return <NotFound />;
//   }
// };

const RoleUser = ({ children }: { children: React.ReactNode }) => {
  const { user } = useContext(AuthContext);
  if (user.role==="ADMIN_HR" ||user.role==="SUB_ADMIN_HR") {
    return <>{children}</>;
  } else {
    return <NotFound />;
  }
};
export { RoleUser };
