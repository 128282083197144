import { Space } from "antd";
import moment = require("moment");
import { Link } from "react-router-dom";
import Router from "../../../../config/route";
import { formatPhoneNumber } from "react-phone-number-input";

export const defineColumnTableAdminList = () => {
  return [
    {
      title: <div style={{ fontWeight: 600 }}>{"企業"}</div>,
      dataIndex: "name",
      render: (_, record) => {
        return (
          <Link
            to={`${Router.admin_edit_company}/${record.id}`}
            style={{ color: "#18C9B4" }}
          >
            {record.name}
          </Link>
        );
      },
    },
    {
      title: <div style={{ fontWeight: 600 }}>{"登録日"}</div>,
      dataIndex: "created_at",
      render: (_, record) => {
        return (
          <div>
            {record.created_at
              ? moment(record.created_at).format("YYYY/MM/DD")
              : null}
          </div>
        );
      },
    },
    {
      title: <div style={{ fontWeight: 600 }}>{"本社所在地"}</div>,
      dataIndex: "address",
    },
    {
      title: <div style={{ fontWeight: 600 }}>{"担当者名"}</div>,
      dataIndex: "representative_name",
    },
    {
      title: <div style={{ fontWeight: 600 }}>{"メールアドレス"}</div>,
      dataIndex: "email_company",
    },
    {
      title: <div style={{ fontWeight: 600 }}>{"直通電話番号"}</div>,
      dataIndex: "phone",
      render: (_, record) => {
        return <div>{formatPhoneNumber(record.phone) || record.phone}</div>;
      },
    },
    {
      title: <div style={{ fontWeight: 600 }}>{"登録求人数"}</div>,
    },
    {
      title: <div style={{ fontWeight: 600 }}>{"採用決定数"}</div>,
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <Space size={14}>
            <Link
              to={`${Router.admin_edit_company}/${record.id}`}
              style={{ color: "#18C9B4" }}
            >
              企業情報
            </Link>

            <div style={{ color: "#18C9B4", cursor: "pointer" }}>求人一覧f</div>
          </Space>
        );
      },
    },
  ];
};
