import { FilterOutlined } from "@ant-design/icons";
import { Card, Col, Form, Row, Select, Input } from "antd";
import arrayStatus from "../../../components/dataArrayUseInProject/arrayStatus";
import arrayStatusPosting from "../../../components/dataArrayUseInProject/arrayStatusPosting";
import ButtonLoadingHOC from "../../../HOC/ButtonLoadingHOC";


// eslint-disable-next-line max-lines-per-function
const SearchTablePostingList = ({ onSubmit, loading }: any) => {

  const [form] = Form.useForm();
  const onFinish = async (value) => {
    console.log(value);
  
    if (onSubmit) onSubmit(value);
  };



  return (
    <div>
      <Card style={{ marginBottom: 8 }} className="fix_label_search">
        <Form
          form={form}
          labelCol={{ span: 6 }}
          name="validate_other"
          initialValues={{}}
          onFinish={onFinish}
          className="fix_background_select"
        >
          <Row
            style={{ display: "flex", justifyContent: "flex-end",alignItems:'center' }}
            gutter={20}
          >
            <Col xs={24} md={12} lg={{ span: 6 }} xxl={{ span: 6 }}>
              <Form.Item name="title">
             <Input size="large" placeholder="検索用キーワード" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={{ span: 6 }} xxl={{ span: 6 }}>
              <Form.Item name="status">
                <Select
                  size={"large"}
                 
                  allowClear={() => form.setFieldsValue({ status: null })}
                  placeholder={"下書き＋公開"}
                >
            
            {arrayStatusPosting.map((el) => {
              return (
                <Select.Option key={el.id} value={el.id}>
                  {el.name}
                </Select.Option>
              );
            })}




                </Select>
              </Form.Item>
            </Col>
          
          
          
          
          
          
          
            <Col xs={24} md={12} lg={{ span: 6 }} xxl={{ span: 6 }}>
              <Form.Item name="status">
                <Select
                  size={"large"}
                  allowClear={() => form.setFieldsValue({ job_category: null })}
                 
                  placeholder={"カテゴリ"}
                >
                  {arrayStatus.map((el) => {
                    return (
                      <Select.Option key={el.id} value={el.id}>
                        {el.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
         
       
            
       
            <Col xs={24} md={12} lg={{ span: 6 }} xxl={{ span: 6 }}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <ButtonLoadingHOC
                  name={"検索"}
                  icon={<FilterOutlined />}
                  handelButton={form.submit}
                  loadingButton={loading}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};
export default SearchTablePostingList;
