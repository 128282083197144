
import { Form, Input, Space } from "antd";


const FormInput = ({
  name,
  required,
  placeholder,
  labelName,
  message,
  showCount,
  maxLength,
  placeEmail
}:any) => {
 
  return (
    <Form.Item
          label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>{labelName}</div>
              <div className={required?'required':'not_required'}>{required?"必須":"任意"} </div>
            </Space>
          }
        >
      <Form.Item
        name={name}
        rules={[
          {
            required: required,
            message: message,
          },
          {
            type: placeEmail ? "email" : null,
            message: "メールアドレスは有効なメールではありません。",
          },
          {
            whitespace: required,
            message:  `空白を入力できません。`,
          },
        ]}
      >
        <Input placeholder={placeholder} size="large" showCount={showCount? {
          formatter: ({  count, maxLength }) => ` ${count}/ ${maxLength} 文字`,
        } : false} maxLength={maxLength}/>
      </Form.Item>
     
    </Form.Item>
  );
};
export default FormInput;
