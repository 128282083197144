import { FilterOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { removeEmpty } from "../../../../../components/HandleObj";
import { handleParamInSearchCompany } from "./handleParamInSearchCompany";

// eslint-disable-next-line max-lines-per-function
const SearchTableCompanyList = ({ onSubmit }:any) => {
  const [form] = Form.useForm();
  const onFinish = async (value) => {
    const params = handleParamInSearchCompany(value);
    const params2 = removeEmpty(params);
    if (onSubmit) onSubmit(params2);
  };
  return (
    <div>
      <Card style={{ marginBottom: 8, paddingBottom:0 }} className={'styleCardInSearch'}>
        <Form
          form={form}
          colon={false}
          name="validate_other"
          initialValues={{}}
          onFinish={onFinish}
        >
          <Row justify="space-between" style={{ display: "flex" }} gutter={20}>
            <Col span={14}>
              <Form.Item name="keyword" label="キーワード検索">
                <Input
                  allowClear={() => form.setFieldsValue({ keyword: null })}
                  placeholder={"企業名・担当者名・メールアドレスなどを入力してください"}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="keyword" label="本社所在地">
                <Input
                  allowClear={() => form.setFieldsValue({ keyword: null })}
                  placeholder={"都道府県を選択する"}
                />
              </Form.Item>
            </Col>

            <Col style={{  textAlign:'end' }} span={3}>
              <Button
                style={{color:'#18C9B4', borderColor:'#18C9B4'}}
                htmlType="submit"
                icon={<FilterOutlined twoToneColor={'#18C9B4'}/>}
                ghost
              >
                {"絞り込む"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};
export default SearchTableCompanyList;
