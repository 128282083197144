import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Space,
  Form,
  Input,
  Select,
  Row,
  Col,
  Table,
  Button,
  Spin,
  DatePicker,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment = require("moment");
import { useState } from "react";
import { Link } from "react-router-dom";
import { updateApplicant } from "../../../../api/service/applicant";
import arrayStatusProgressApplicant from "../../../../components/dataArrayUseInProject/arrayStatusProgressApplicant";
import FormSelect from "../../../../components/formSelect";
import Router from "../../../../config/route";
import { notiHOC } from "../../../../HOC/NotiHOC";
import { handleInitialValuesResultApplicant } from "./handleInitialValuesResultApplicant";
import { handleDataSentResultApplicant } from "./handleDataSentResultApplicant";

import "./index.scss";
const { TextArea } = Input;

const ResultApplicant: React.FC<any> = ({
  applicant,
  id,
  setApplicant,
}: any) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (values) => {
    // setIsLoading(true);
    console.log("values", values);
    setIsLoading(true);

    const dataSent = handleDataSentResultApplicant(values, applicant);
    console.log("dataSent", dataSent);
    const response = await updateApplicant(id, dataSent);
    if (response.status === 200) {
      setIsLoading(false);
      setApplicant(response.data.data);
      notiHOC("求人情報を保存しました。", true);
    } else {
      setIsLoading(false);

      notiHOC("更新に失敗しました。", false);
    }
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };
  const columns: ColumnsType<any> = [
    {
      title: "選考ステータス",
      dataIndex: ["status_applicant", "name"],
      key: "name",
    },
    {
      title: "評価",
      dataIndex: "",
      key: "grade",
      render: (item, record, index) => (
        <Form.Item name={`grades${index + 1}`} wrapperCol={{ span: 24 }}>
          <Select placeholder={"選択してください。"} size={"large"}>
            {arrayStatusProgressApplicant.map((item) => {
              {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              }
            })}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "コメント",
      dataIndex: "",
      render: (item, record, index) => (
        <>
          <Form.Item name={`description${index + 1}`} wrapperCol={{ span: 24 }}>
            <TextArea
              size="large"
              showCount={{
                formatter: ({ count, maxLength }) =>
                  ` ${count}/ ${maxLength} 文字`,
              }}
              maxLength={3000}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
    },
  ];
  return (
    <Spin spinning={isLoading}>
      <div className="panel-detail-appplicant formItem">
        {applicant && (
          <Form
            className="applicants-detail"
            colon={false}
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            form={form}
            onFinishFailed={onFinishFailed}
            initialValues={handleInitialValuesResultApplicant(applicant)}
            onFinish={onFinish}
          >
            <div
              className="results-item applicants-results-item"
              id="applicants-overview"
            >
              <div className="results-ttl-item">
                <div className="results-ttl">選考ステータス</div>
              </div>
              <div style={{ paddingTop: 20, width: "60%" }}>
                <FormSelect
                  name="status_applicant"
                  labelName="現在のステータス"
                  required={true}
                  message="選んでください。"
                  arrayData={arrayStatusProgressApplicant}
                />

                <Form.Item
                  label={
                    <Space size={14}>
                      <div style={{ fontWeight: 500 }}>
                        {"次回面接予定日"} <QuestionCircleOutlined />
                      </div>
                      <div className="not_required">{"任意"} </div>
                    </Space>
                  }
                >
                  <Form.Item name="next_interview">
                    <DatePicker
                      size={"large"}
                      showTime
                      format="l HH:mm"
                      minuteStep={15}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Form.Item>
              </div>
              <Table
                columns={columns}
                dataSource={applicant.status_progress_applicants}
                pagination={false}
              />
            </div>
            <div
              className="results-item applicants-results-item"
              id="applicants-memo"
            >
              <div className="results-ttl-item">
                <div className="results-ttl">メモ</div>
                <div className="results-btn"></div>
              </div>
              <Form.Item
                style={{ paddingTop: 20 }}
                label={
                  <Space size={14}>
                    <div style={{ fontWeight: 500 }}>{"メモ"}</div>
                    <div className={"not_required"}>{"任意"} </div>
                  </Space>
                }
              >
                <Form.Item name={"memo"}>
                  <TextArea
                    placeholder={""}
                    size="large"
                    showCount={{
                      formatter: ({ count, maxLength }) =>
                        ` ${count}/ ${maxLength} 文字`,
                    }}
                    maxLength={3000}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                  />
                </Form.Item>
              </Form.Item>
            </div>
            <div className="d-f applicants-detail-btn txt-center">
              <div className="txt-center">
                <input
                  type="submit"
                  value="保存する"
                  className="btn-blue-big m-t-40"
                />
              </div>
            </div>
          </Form>
        )}
      </div>
    </Spin>
  );
};
export default ResultApplicant;
