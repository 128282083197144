import { resetPassword } from "../../api/session";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext, useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import { notiHOC } from "../../HOC/NotiHOC";
import {  Form, Input } from 'antd';
import React = require("react");
import { Link } from "react-router-dom";
import Router from "../../config/route";
import "./Login.scss";

import { useForm } from "react-hook-form";

const SetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorsMessage, setErrorMessage] = useState([]);
  const [errorsMessagePassword, setErrorMessagePassword] = useState("");
  const auth = useAuth();
  const params = new URLSearchParams(location.search);
  const accessToken = window.localStorage.getItem("access-token");
  const client = params.get("client_id");
  const uid = params.get("uid");
  const { user } = React.useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  const submitForm = (evt) => {
    setErrorMessagePassword("")
    if(evt.new_password.length<8){
    
      setErrorMessagePassword("新しいパスワードは、8文字以上にしてください。");
      return;
    }
    
    const formData = new FormData;
    formData.append("user_id", user.id.toString());
    formData.append("new_password", evt.new_password)
    formData.append("new_password_confirm", evt.new_password_confirm)
    resetPassword(formData)
      .then((resp) => {
        if (resp.success) {
          auth.signin(resp.data, () => {
            setErrorMessagePassword("")
  notiHOC('パスワードが正常に変更されました',true)
          });
        } else {
          setErrorMessagePassword("パスワードが一致しません。");
        }
      })
      .catch((resp) => {
        console.log(resp);
      });
  };

  if (accessToken) {
    return (
      <div className="main">
 
        <div className="box-account">
        <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span:24 }}
      initialValues={{ remember: true }}
      onFinish={submitForm}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
            <Link to={Router.root}>
              <div className="app-logo">
                <img src={require("../../../img/common/logo_blue.png")} />
              </div>
            </Link>
            {errorsMessage.length > 0 && (
              <div className="boder-error">
                {errorsMessage.map((error, index) => {
                  return (
                    <div key={index} className="error">
                {errorsMessagePassword}
                    </div>
                  );
                })}
              </div>
            )}
            <dl className="set-password-item">
              <dt>
                <label htmlFor="new_password">新しいパスワード</label>
              </dt>
              <dd>
              <Form.Item
     
        name="new_password"
        rules={[{ required: true, message: 'このフィールドに入力してください' }]}
      >
        <Input.Password  />
      </Form.Item>
              </dd>
            </dl>
            <dl className="set-password-item">
              <dt>
                <label htmlFor="new_password_confirm">
                
                  新しいパスワード確認
                </label>
              </dt>
              <dd>
              <Form.Item
       
        name="new_password_confirm"
        rules={[{ required: true, message: 'このフィールドに入力してください' }]}
      >
        <Input.Password />
      </Form.Item>
              </dd>
              {errorsMessagePassword ? (
                <div className="error m-t-8">{errorsMessagePassword}</div>
              ) : (
                ""
              )}
            </dl>
            <div className="text-center">
              <button type="submit" className=" btn-submit">
                パスワードを変更する
              </button>
            </div>
            <div className="text-center m-t-20">
              <Link to={Router.root}>ホームページに戻ります</Link>
            </div>
          </Form>
        </div>
        <div className="box-policy text-center">
          <div className="link-policy">
            <Link to={Router.app_policy}>利用規約</Link>
          </div>
          <div className="copyright">
            Copyright © onecourse CO.,LTD. All Rights Reserved.
          </div>
        </div>
      </div>
    );
  } else {
    return <h1>Invalid page</h1>;
  }
};

export default SetPassword;
