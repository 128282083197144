import { Link, useParams } from "react-router-dom";
import Header from "../../Header";
import { useEffect, useState } from "react";

import { Row, Col, Breadcrumb, Spin } from "antd";
import BackIcon from "../../../components/Icon/BackIcon";
import "../style.css";
import { getDetailApplicant } from "../../../api/service/applicant";

import DetailHeaderApplicant from "./DetailHeaderApplicant";
import DetailApplicantContain from "./DetailApplicantContain";
import ResultApplicant from "./ResultApplicant";

const DetailApplicant = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [applicant, setApplicant] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const fetchDetailApplicantData = async () => {
    setIsLoading(true);
    const response = await getDetailApplicant(id);
    if (response.status === 200) {
      setIsLoading(false);
      setApplicant(response.data.applicant);
    } else {
      console.log("No data");
    }
  };

  useEffect(() => {
    fetchDetailApplicantData();
  }, []);

  return (
    <div>
      <Header />
      <div
        className="inner-block job-new-form "
        style={{ paddingBottom: "100px" }}
      >
        <Row>
          <Col style={{ justifyContent: "flex-end" }} span={24}>
            <div className="inner-block">
              <Breadcrumb
                style={{
                  padding: "20px 0 40px",
                }}
              >
                <Link to="/jobs">
                  <BackIcon></BackIcon>
                  <span className="text-color-black m-l-4">求人一覧へ戻る</span>
                </Link>
              </Breadcrumb>
            </div>
          </Col>
          <Col span={24}>
            <div className=" job-list pos-r " style={{ padding: "0 20px" }}>
              <div className="container has-shadow">
                <Spin spinning={isLoading}>
                  <div className="list-count" style={{ paddingBottom: "20px" }}>
                    {applicant && (
                      <DetailHeaderApplicant applicant={applicant} />
                    )}
                  </div>
                  <div className="tab-detail-applicant">
                    <div
                      className={
                        activeTab == 1 ? "results-h1-on" : "results-h1-off"
                      }
                      onClick={() => {
                        setActiveTab(1);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      応募者情報
                    </div>
                    <div
                      className={
                        activeTab == 2 ? "results-h1-on" : "results-h1-off"
                      }
                      onClick={() => {
                        setActiveTab(2);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      選考結果
                    </div>
                    
                  </div>
                  {applicant && activeTab == 1 &&  <DetailApplicantContain applicant={applicant} id={id} setApplicant={setApplicant}/>}
                  {applicant && activeTab == 2 &&  <ResultApplicant applicant={applicant} id={id} setApplicant={setApplicant}/>}
                </Spin>
              </div>
              
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DetailApplicant;
