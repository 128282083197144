import { Link } from "react-router-dom";
import BackIcon from "../components/Icon/BackIcon";
import Router from "../config/route";
import { Row, Col, Breadcrumb } from 'antd';

const AppPolicy = () => {
  const lines = `
    Line 1
    Line 2
    Line 3
    Line 4
    Line 5
    `;
  return (
    <>

      <Row

        style={{ justifyContent: 'center' }}
      >
        <Col>
          <Breadcrumb
            style={{
              margin: "20px auto 0",

            }}
          >
            <Link to={Router.root}>
              <BackIcon></BackIcon>
              <span className="text-color-black m-l-4">ホームへ戻る</span>
            </Link>
          </Breadcrumb>
          <div className="policy-wraper">
            <div className="policy-title">求人エックス利用規約</div>
            <div className="policy-content">
              第1条 （利用規約について）
              <br />
              1.
              本求人エックス利用規約（以下、「本利用規約」といいます。）は、株式会社ONE.course（以下、「当社」といいます。）と本サービス（第2条で定義します。）を利用する企業・団体（以下、総称して「利用企業等」といいます。）との間において、本サービスの利用に関する一切の関係に適用されるものとします。
              <br />
              <br />
              2.
              利用企業等は、本利用規約について同意の上で本サービスを利用するものとし、本サービスを利用することにより、本利用規約の内容について全て同意したものとみなされます。
              <br />
              <br />
              3.
              利用企業等は、本利用規約のほか、当社が定める各種の規約（ヘルプ、投稿、ガイドラインを含みます。）（以下、「個別規定」といい、本利用規約とあわせて「本利用規約等」といいます。）に同意いただき、本サービスを利用するものとします。なお、本利用規約と個別規定の定めが異なる場合には、個別規定の定めが優先するものとします。
              <br />
              <br />
              第2条 （定義）
              <br />
              本利用規約において使用する用語の定義は、別途定める場合を除き、以下の通りとします。
              <br />
              <br />
              (1) 「当サイト」
              <br />
              <br />
              当社が「求人エックス」を含む名称で運営するインターネット上のウェブサイト（https://recruit-x.net/）をいいます。
              <br />
              <br />
              (2) 「候補者」
              <br />
              <br />
              企業が掲載した求人に対して応募した、あるいは応募する可能性がある人、および応募して企業の選考を受けたことのある人をいいます。
              <br />
              <br />
              (3) 「本サービス」
              <br />
              <br />
              当サイトを通じて、利用企業等に対して提供する以下が可能となるクラウド型採用管理サービスをいいます。ただし、当社の裁量により提供する内容は変更すること、または別途利用条件を定めることがあり、次の各号の全ての機能を利用企業に保証するものではありません。
              <br />
              <br />
              ① 企業が新たに募集する求人の作成ならびに応募の受付
              <br />
              <br />
              ② 当サイトまたは外部サービスにおける企業求人への候補者データの管理
              <br />
              <br />
              ③ 候補者へのメールの送信、面接スケジュールの調整
              <br />
              <br />
              ④ 採用選考情報の管理
              <br />
              <br />
              ⑤ その他①〜④に付帯する機能
              <br />
              <br />
              (4) 「利用履歴情報データ」
              <br />
              <br />
              本サービスの利用履歴情報の集計データ（企業の求人応募、ページビュー数、メール履歴、応募内容集計等）をいいます。なお、利用履歴情報データには、個人に関する情報で、会員の氏名・住所・電話番号・年齢・生年月日･職業・メールアドレス等の一つまたは二つ以上の組み合わせにより特定の個人を識別出来る情報は含みません。
              <br />
              <br />
              (5) 「秘密情報」
              <br />
              <br />
              本サービスに関連し、当社及び利用企業等が、相手方に関し知り得た一切の情報（本サービスの内容ならびに当社が提供及び利用企業等が利用する事実を全て含みますが、これらに限られません。）をいいます。ただし、次の①～④に該当する情報を除きます。
              <br />
              <br />
              ① 相手方から知り得た時点で公知である情報
              <br />
              <br />
              ② 相手方から知り得た後、自己の責によらず公知となった情報
              <br />
              <br />
              ③ 第三者から機密保持義務を負うことなく適法に入手した情報
              <br />
              <br />
              ④ 相手方から知り得た情報によることなく、独自に開発した情報
              <br />
              <br />
              第3条 （利用契約の成立）
              <br />
              1.
              当社所定のオンラインフォーム上での申込、当社所定の申込書その他当社所定の方法により、企業による本サービスの利用にかかる申込がなされ、当社の審査により適格と判断された場合において、当社による承諾の意思表示が企業に到達した時をもって、当社と企業の間に本サービスに関する利用契約（以下、「本契約」といいます。）が成立するものとします。ただし、利用企業等は、本契約の成立前であっても、かかる申込後、当社による承諾の意思表示が企業に到達するまでに本サービスの利用を行う場合は、本利用規約等の各条項を遵守するものとします。
              <br />
              <br />
              2.
              本サービスの利用申込は、必ず本契約を締結する権限を有する者が行わなければならず、本契約を締結する正当な権限を有しない企業の役職員その他第三者の代理による申込は認められないものとします。
              <br />
              <br />
              3.
              当社は、利用申込を行う者が以下に定める事由のいずれかに該当すると判断した場合、利用申込に対して承諾しないこと、承諾を取消しすることが出来るものとします。当社はこれについて一切の責任を負わず、また承諾しない理由もしくは承諾を取り消す理由を当該利用申込者に説明する義務を負わないものとします。
              <br />
              <br />
              (1)
              本利用規約等に違反する行為を行うおそれがある場合または過去に違反した事実が判明した場合
              <br />
              <br />
              (2)
              利用申込時に当社に提供された情報に、虚偽の記載や記載漏れがあった場合
              <br />
              <br />
              (3)
              利用申込をしようとする企業・団体等が実在しないか、または実体的な活動を行っていない場合
              <br />
              <br />
              (4)
              当社に提供されたメールアドレスへの問い合わせに対し5日以上連絡がなかった場合
              <br />
              <br />
              (5)
              第18条（反社会的勢力等）に違反し、または違反するおそれがあると当社が判断した場合
              <br />
              <br />
              (6) その他当社が不適切と判断した場合
              <br />
              <br />
              第4条 （本サービスの利用）
              <br />
              1.
              利用企業等は、本サービスに関し、当社に対して職業安定法その他の法令等に抵触する可能性のある業務を委託することは出来ないこと、実施の依頼を受けた業務について当社が当該法令等に違反すると判断した場合には当該業務の実施依頼を断ることが出来ることを予め了承するものとします。この場合、第16条（損害賠償義務）の定めにかかわらず、利用企業等は当社に損害賠償請求をすることは出来ません。
              <br />
              <br />
              2.
              利用企業等は、当社のサービス環境の変化、本サービスの瑕疵の修補、本サービス利用上の不都合または相当数の利用企業等からの要請等により、利用企業等への事前の通知なく本サービスの仕様を変更する場合があることを予め承諾します。
              <br />
              <br />
              3.
              利用企業等は、本サービス上の操作・業務を当社の事前の書面（FAX、電子メールを含みます。以下同じ。）による同意なく第三者に委託することが出来ず、当社の同意により委託をする場合も本契約と同等の義務を当該第三者に負わせるものとします。ただし、それにより利用企業等の責を免れるものではありません。
              <br />
              <br />
              第5条 （本サービスの利用料）
              <br />
              1.
              利用企業等は、本契約に基づき、当社に対し、本サービス利用の対価として、別途当社が定め、当社がウェブサイト（https://www.recruit-x.net/）に掲載する利用料金を当社が定める方法にて支払うものとします。なお、支払いにかかる手数料は、利用企業等の負担とします。
              <br />
              <br />
              2.
              利用料金の請求にあたり当社は、事前に利用企業等に対して料金や支払方法について通知するものとします。
              <br />
              <br />
              3.
              本契約が終了した場合（当社の責に帰すべき事由による場合を除きます。）においても、当社は、利用企業等が既に当社に対して支払った月額利用料に関して、いかなる事由であっても利用企業等に返還する義務を負わないものとします。ただし、当社が別に認めた場合にはこの限りではありません。
              <br />
              <br />
              第6条 （無料トライアル）
              <br />
              1.
              前条にかかわらず、当社は、利用企業等に対し、本サービスの全部または一部を、当社が別途定める一定の期間のみ無料で利用できるサービス（以下、「トライアルサービス」といいます。）を行うことがあります。ただし、当社はトライアルサービスを提供する義務を負うものではありません。
              <br />
              <br />
              2.
              当社は、トライアルサービスにおいて、提供する本サービスの機能の範囲及び使用方法等に制限を設けることがあります。
              <br />
              <br />
              第7条 （利用ID・パスワード及び本人確認）
              <br />
              1.
              利用企業等は、個人情報保護及びセキュリティ保持の必要上、本サービスの利用に必要な当社が発行するログインID及びパスワード（以下、総称して「ID等」といいます。）に関しては厳重な管理義務を負い、第三者にID等を譲渡・貸与・開示等をしてはならないものとします。
              <br />
              <br />
              2.
              利用企業等は、利用企業等の担当者その他の役職員に対して、前項の義務を遵守させるものとし、利用企業等の担当者その他の役職員が義務に違反した場合は、利用企業等が本契約に違反したものとみなします。
              <br />
              <br />
              3.
              当社は、当サイトの利用企業等の担当者その他の役職員のID等の認証時点からログアウトまでの一連の行為を、正当な権限を有する者からのアクセスとみなし、利用企業等の行為と取り扱うことが出来るものとします。
              <br />
              <br />
              4.
              利用企業等は、ID等が第三者に流出漏洩し、または第三者により不正に利用されまたはそのおそれがあることを知った場合、直ちに当社に通知し、当社の指示に従うものとします。
              <br />
              <br />
              5.
              当社が発行するID等の有効期限は、利用企業等による最後のログイン日より1年後とし、有効期限を過ぎたID等はその翌週または当社の定める日に削除することが出来るものとします。
              <br />
              <br />
              第8条 （知的財産権）
              <br />
              1.
              本サービスに関する一切の著作権（著作権法第27条及び第28条に規定する権利を含みます。）、特許権、商標権その他の知的財産権（登録等を出願する権利を含みます。）は、当社に帰属するものとし、利用企業等は本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、複製、変更、改変等しないものとます。ただし、利用企業等または利用企業等から委託を受けた第三者が作成した原稿、写真等については、この限りではありません。
              <br />
              <br />
              2.
              利用企業等は、当社がウェブサイトの宣伝、プロモーション等の広報営業活動、または本サービスの円滑な提供、改良、メンテナンスに必要な範囲内において、原稿、写真等の著作物、商標等を全世界で利用（二次利用や当社が指定する第三者への利用許諾を含みます。）することを当サイトへの情報の掲載時において、当社に対し、無償で非独占的に許諾するものとし、企業は当社及び当社の指定する者に対して著作者人格権を行使しないものとします。
              <br />
              <br />
              第9条 （当社の免責）
              <br />
              1.
              当社は、天災地変その他不可抗力（回線の輻輳、回線の障害、サーバダウン等を含みますが、これらに限られません。）により生じた損失につき、何らの責任も負わないものとします。
              <br />
              <br />
              2.
              当社は、利用企業等または第三者の責めに帰すべき事由により生じた損失（①ウイルスまたはハッキングによるサーバダウン、サービス障害、データの流出、損壊及び誤った情報の掲載、②企業の操作ミスによるデータの流出、損壊、採用機会の喪失、及び③当社のサービス環境の変化、本サービスの瑕疵を含みます。）につき、何らの責任も負わないものとします。
              <br />
              <br />
              3.
              当社は、候補者が表明する自身に関する情報の真実性、最新性、確実性等につき一切保証しないものとします。
              <br />
              <br />
              4.
              当社は、利用企業等と候補者の間に生じた一切のトラブルについて、何らの責任も負わないものとします。万一、利用企業等と候補者との間に紛争等が発生し、当社が直接これにやむを得ず対応した場合、利用企業等は、当社に発生した損害、費用（合理的な弁護士費用を含みます。）等の一切を補償するものとします。
              <br />
              <br />
              第10条 （インターフェースの変更）
              <br />
              利用企業等は、当社が本サービスを取り巻くシステム環境の変化、その他利用上の不都合または多数の企業からの要請等により、利用企業等への事前の通知なくインターフェースを変更する場合があること及び当該変更の結果、変更後のインターフェースと本サービスマニュアルまたはマニュアル内の表示等が異なる事態が生じることを予め承諾するものとします。
              <br />
              <br />
              第11条 （本サービスの中止・停止等）
              <br />
              1.
              当社は、次の各号に該当する場合、または該当するおそれがあると当社が判断した場合、利用企業等に事前の通知や承諾なしに、本サービスの全部または一部の提供を中止または停止することができます。
              <br />
              <br />
              (1)
              本サービスにかかるサーバやソフトウエア等電気通信設備の保守、点検、修理、データ更新の必要がある場合
              <br />
              <br />
              (2) 設備の故障等やむを得ない事情がある場合
              <br />
              <br />
              (3)
              電気通信事業法第8条の規定に基づき、天災その他の非常事態が発生し、もしくはそのおそれがあり、公共の利益のために緊急を要する通信を優先させる必要がある場合
              <br />
              <br />
              (4)
              天災地変その他非常事態が発生し、もしくは発生するおそれがあり、または法令等の改正・成立により本サービスの運営が困難または不可能になった場合
              <br />
              <br />
              (5) 電気通信事業者等が、電気通信サービスを中断した場合
              <br />
              <br />
              (6)
              利用企業等の行為が当社の電気通信設備に支障を及ぼし、またはそのおそれがあり、その結果、当社の業務遂行に支障が生じると利用企業等が認めた場合
              <br />
              <br />
              (7) その他当社が必要と判断した場合
              <br />
              <br />
              2.
              本サービスの全部または一部の提供を中止または停止したことにより、利用企業等にいかなら損害が生じたとしても、当社は利用企業等に対して賠償の責任を一切負わないものとします。
              <br />
              ただし、当社に明らかな瑕疵がある場合はこの限りではない。
              <br />
              <br />
              第12条 （機密情報及び個人情報の保持）
              <br />
              1.
              利用企業等は、本サービスの利用の検討または本サービスの利用を行うにあたって、知り得た機密情報（本サービスに関する情報・仕組み・ノウハウ・プログラムソース等を含みますがこれらに限られません。以下同じ。）及び個人情報を、企業の求人の遂行のみ使用するものとし、その他の目的（商業目的であるか否かは問いません。）に一切使用しないものとします。
              <br />
              <br />
              2.
              利用企業等は、機密情報及び候補者の個人情報を機密として厳重かつ適正に取り扱うものとし、第三者に開示または漏洩しないものとします。
              <br />
              <br />
              第13条 （秘密保持及び個人情報の保持）
              <br />
              1.
              当社は、秘密情報を本サービスの提供（本サービスの改善及び性能の向上等を含みます。）のために必要な範囲に限り、自己の役員、従業員、関連会社、下請等の委託先、アドバイザー、コンサルタント、弁護士もしくは税理士等に開示出来るものとし、それ以外の第三者に対しては、利用企業等の事前の書面による承諾なく、開示及び漏洩せず、かつ、本サービスを提供する以外の目的で利用しません。
              <br />
              <br />
              2.
              当社は、業務上必要な範囲内でのみ委託先に秘密情報及び個人情報を取り扱う業務の全部または一部を委託することが出来るものとし、その場合、本条における当社の義務と同等の義務を当該委託先にも負わせるものとします。
              <br />
              <br />
              3.
              利用企業等は、本サービスの利用にあたって知り得た当社の機密情報及び当社保有の個人情報を、利用企業等の求人活動ないし採用活動の遂行の目的にのみ使用するものとし、その他の目的（商業目的であるか否かは問いません。）に一切使用しないものとします。
              <br />
              <br />
              4.
              利用企業等は、本サービスの利用を通じて知りうる当社の機密情報または当社の一般に公開していない情報（本サービスに関する情報・仕組み・ノウハウ・プログラムソース等を含みますがこれらに限られません。）の一切を第三者に開示・漏洩もしくは利用企業等自らのために利用してはならないものとします。
              <br />
              <br />
              5.
              利用企業等は、候補者の個人情報を機密として厳重かつ適正に取り扱うものとし、候補者本人の同意を得た場合を除き、第三者に開示または漏洩しないものとします。
              <br />
              <br />
              第14条 （個人情報及び利用履歴情報データの利用）
              <br />
              1.
              当社は、本サービスを利用企業等に提供するにあたり、利用企業等による本サービスの利用記録及び個人情報を集計・分析し、利用企業等および個人を識別・特定出来ないように加工した上で統計データ等を作成し、当該統計データ等につき何らの制限なく、利用企業等へのサービス提供の目的に限って利用（利用企業等及び第三者への提案、市場の調査、新サービスの開発を含みますがこれらに限られません。）することが出来るものとし、利用企業等はこれを予め承諾するものとします。
              <br />
              <br />
              2.
              当社は、別途当社が収集した利用履歴情報データ、利用企業等の利用実績（採用人数など当社が確認出来るやりとり、その他利用企業等からの報告内容を含みますがこれらに限られません。）をもとにした集計結果など、利用企業等の効果情報を利用企業等が特定できない内容に限り本サービス上に掲示できるものとし、利用企業等はこれを予め承諾するものとします。
              <br />
              <br />
              第15条 （損害賠償義務）
              <br />
              当社及び利用企業等は、本契約または本利用規約等に違反し、これにより相手方に損害が生じた場合、相手方に対し、直接かつ現実に生じた損害を賠償する義務を負います。
              <br />
              <br />
              第16条 （禁止事項・解除）
              <br />
              1. 当社は、利用企業等の次の各号に該当する行為を禁止します。
              <br />
              <br />
              これらに該当する、または該当するおそれがあると当社が判断した場合、利用企業等への通知もしくは承諾なしに直ちに本サービスの提供を停止し、また本契約を解除することが出来るものとします。
              <br />
              <br />
              (1) 本利用規約等に違反する行為
              <br />
              <br />
              (2)
              労働基準法、職業安定法、個人情報保護法その他法令または公序良俗に違反する行為
              <br />
              <br />
              (3)
              違法行為・犯罪行為・反社会的行為を暗示・誘発・助長・推奨等する行為
              <br />
              <br />
              (4)
              虚偽、不完全、不正確な情報を当社に提供し、または当サイトを通じて候補者の閲覧に供する行為
              <br />
              <br />
              (5) 異性交際に関する情報を投稿または送信する行為
              <br />
              <br />
              (6)
              コンピュータウィルス等の有害なコンピュータプログラム等を送信または掲載する行為
              <br />
              <br />
              (7)
              当社のサービスの運営を妨害し、または当社の信頼を毀損するような行為
              <br />
              <br />
              (8)
              他人名義もしくは虚偽のID及びパスワードを使用して本サービスを利用する行為
              <br />
              <br />
              (9)
              その他当社が合理的な根拠に基づき本サービスの提供を継続することが不適切であると判断する行為
              <br />
              <br />
              2.
              当社は、利用企業等が次の各号の一に該当するときには、何ら事前の催告を要することなく、利用企業等に対し通知を行うことにより、即時に本契約を解除することが出来ます。
              <br />
              <br />
              (1)
              差押え、仮差押え、仮処分、租税滞納処分を受け、または破産、民事再生、特別清算、会社更生を自ら申し立てもしくは申し立てを受けたとき
              <br />
              <br />
              (2) 営業を廃止したとき、または清算手続にはいったとき
              <br />
              <br />
              (3)
              その他本利用規約等に定める事項を遂行出来る客観的見込みがないとき
              <br />
              <br />
              第19条 （反社会的勢力等）
              <br />
              1.
              当社及び利用企業等は次の各号に該当しないことを保証し、将来においても該当しないことを誓約します。
              <br />
              <br />
              (1)
              暴力団、暴力団関係企業、総会屋、社会運動等標榜ゴロもしくは特殊知能暴力集団等もしくはそれらの構成員等もしくはこれに準ずるもの（以下、｢反社会的勢力等｣といいます。）または反社会的勢力等でなくなったときから5年を経過しない者であること
              <br />
              <br />
              (2) 反社会的勢力等に資金提供、便宜の供給等を行っていること
              <br />
              <br />
              (3)
              自らまたは第三者を利用して、他者に対して、暴力的行為、詐術、脅迫的言辞を用いていること
              <br />
              <br />
              2.
              当社及び利用企業等は、相手方が前項の規定に違反した場合、何ら事前の催告を要することなく、相手方に対し通知し、即時に本契約を解除することが出来ます。
              <br />
              <br />
              3.
              当社及び利用企業等が前項の規定により契約を解除した場合には、解除により相手方に生じた損害の一切について賠償する義務を負わないものとします。
              <br />
              <br />
              第20条 （契約終了後の措置）
              <br />
              1.
              本契約が終了した場合、企業は、本サービスの利用を直ちに中止するものとします。
              <br />
              <br />
              2.
              本契約終了後においても、本利用規約第8条（知的財産権）、第9条（当社の免責）、第12条（機密情報及び個人情報の保持）、第13条（秘密保持及び個人情報の保持）、第14条（個人情報及び利用履歴情報データの利用）、第16条（損害賠償義務）、本条（契約終了後の措置）及び第23条（準拠法と合意管轄）は有効に存続します。
              <br />
              <br />
              第21条 （通知・連絡等）
              <br />
              1.
              利用企業等は、本サービスの利用申込時に当社に提供した担当者の氏名、連絡先、その他の情報（以下、「登録情報」といいます。）に変更があった場合、当社まで通知するものとします。
              <br />
              <br />
              2.
              利用企業等が前項に定める通知を怠ったために当社から利用企業等に対する通知・連絡等が遅延または不着となった場合、当該通知・連絡等は、通常到達すべき時に到達したものとみなし、当社は遅延・不着に関し一切責任を負わないものとします。
              <br />
              <br />
              3.
              本サービスに関して当社から利用企業等に対してなされる通知・連絡等は、利用企業等の担当者に対する電子メールその他当社が定める方法によるものとします。
              <br />
              <br />
              第22条 （権利義務等の譲渡）
              <br />
              1.
              利用企業等は、本契約上の地位に基づく一切の権利義務を、当社の事前の書面による承諾なく、第三者に譲渡もしくは貸与し、または担保に供してはならないものとします。
              <br />
              <br />
              2.
              当社は、本サービスに関する事業を事業譲渡その他の事由により第三者に承継させる場合には、当該事業の承継に伴い、本契約上の地位、本契約に基づく権利、義務及び企業の登録情報その他の情報を当該事業の承継人に譲渡することが出来るものとし、利用企業等は、かかる譲渡について本項において予め同意したものとします。
              <br />
              <br />
              第23条 （本利用規約の変更）
              <br />
              1.
              当社は、利用企業等への事前通知なしに本利用規約を常時改定することができます。
              <br />
              <br />
              2.
              当社は、本規約を変更した場合には、利用企業等に当該変更した旨を通知するものとし、通知後、利用企業等が本サービスを利用した場合、利用企業等は、本規約の変更に同意したものとします。
              <br />
              <br />
              第23条 （準拠法と合意管轄）
              <br />
              本利用規約等は日本法を準拠法とし、本契約に関して生じる一切の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
              <br />
              <br />
              2022年3月1日適用
            </div>
          </div>

        </Col>
      </Row>

    </>
  );
};

export default AppPolicy;
