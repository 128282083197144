import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'whatwg-fetch';
import App from './App';
import ApiService from './api/service';
import "@fontsource/noto-sans"
import store from './redux/configStore';
const root = document.getElementById('root');
import { Provider } from 'react-redux';
import {
  persistStore,

} from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider  } from 'antd';
import ja from 'antd/lib/locale-provider/ja_JP';

window.ApiService = ApiService();
let persistor = persistStore(store)

ReactDOM.render(
  <BrowserRouter>
  <ConfigProvider  locale={ja}>
  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  </ConfigProvider >
  </BrowserRouter>,
  root,
);


