import moment = require("moment");
import { Link } from "react-router-dom";
import Router from "../../../config/route";
import ChatBox from "../component/ChatBox";


const DetailHeaderApplicant: React.FC<any> = ({applicant}:any) => {
  console.log('fihsfjdofjsopdj',applicant);
  
  return (
    <div style={{ textAlign: "left" }}>
      <div className="text-bold text-title m-b-10">
        {applicant.first_name_kanji} {applicant.last_name_kanji}
      </div>
      <div className="flex" style={{ marginBottom: 10, alignItems: "center" }}>
        <div className="text-bold text-title">山田 太郎</div>
        <div className="" style={{ whiteSpace: "nowrap" }}>
          {moment().diff(
            moment(new Date(applicant.birthday)).format("YYYY-MM-DD"),
            "years",
            false
          )}
          歳・
          {(applicant?.gender == 0 && "男性") ||
            (applicant?.gender == 1 && "女性") ||
            "その他"}
        </div>
  <div  style={{display:'flex'}}>
  <div style={{ paddingLeft: 15,marginRight:'20px' }}>
          {" "}
          <ChatBox applicant={applicant} />
        </div>

        <Link
            to={`${Router.interview_schedule}/${applicant.id}`}
          style={{    height: '100%',
            padding: '5px 15px'}}
            className="applicant-button applicant-btn-info bold"  
          >
            フォーム送信
          </Link>
  </div>

      </div>
      <div className="flex" style={{ marginBottom: 10, alignItems: "center" }}>
        <div className="text-bold text-title">応募求人</div>
        <div className="link">［{applicant.job_type_name ?? null}］</div>
        <div className="link">{applicant.job_name ?? null}</div>
        <div className="" style={{ height: "26px" }}>
          {applicant.listed_company_name ?? null}
        </div>
      </div>
      <div className="flex" style={{ marginBottom: 10, alignItems: "center" }}>
        <div className="text-bold text-title">求人メモ</div>
        <div className="">{applicant.job_work_memo ?? null}</div>
      </div>
      <div className="flex" style={{ marginBottom: 10, alignItems: "center" }}>
        <div className="text-bold text-title">応募日時</div>
        <div className="">
          {moment(applicant?.created_at).format("YYYY/MM/DD hh:mm") ?? null}
        </div>
      </div>
      <div className="flex" style={{ marginBottom: 10, alignItems: "center" }}>
        <div className="text-bold text-title" style={{ paddingRight: 20 }}>
        面接予定
        </div>
        <div className="">
          


          
        </div>
      </div>
    </div>
  );
};
export default DetailHeaderApplicant