import {  Modal } from 'antd';

import { useSelector, useDispatch } from 'react-redux'
import { closeModal } from "../redux/action/modalAction";

export default function ModalHOC() {
    const dispatch=useDispatch()

    const {visibleModal, ComponentContenModal,title ,width,callBackSubmit} =useSelector((state :any) => state.modalHOCReducers);
 
    const onOk=()=>{
    callBackSubmit()
    dispatch(closeModal())
   }


  return (
    <>
    <Modal
      title={title}
      centered
      open={visibleModal}
      onOk={callBackSubmit}
      onCancel={() =>    dispatch(closeModal())}
      width={width}

      footer={[
        <div className="btn-modal-wrapper">
        <span
          onClick={() =>    dispatch(closeModal())}
          className="btn-exit-modal btn-hover-yl"
        >
          キャンセル
        </span>
  
        <button   className="btn-submit-modal btn-hover-yl" onClick={onOk}>
        削除


        </button>
      </div>
      
      ]}
    >
 {ComponentContenModal}
    </Modal>
  </>
  )
}