import { useContext, useEffect, useState } from "react";
import Header from "./Header";
import DataSummary from "../components/Top/DataSummary";
import { Box, Container } from "@mui/material";
import Director from "../components/Top/Director";
import { getDataSummary } from "../api/service/user";
import { AuthContext } from "../hooks/useAuth";
import LoadingScreen from "../components/Common/LoadingScreen";
const Top = function () {
  const [dataSummary, setDataSummary] = useState(null);
  const [statusSummary, setStatusSummary] = useState(null);
  const { user } = useContext(AuthContext);
  const getData = async () => {
    const baseData = await getDataSummary(null);
    if (baseData.status === 200) {
      setDataSummary(baseData.data);
      setStatusSummary(baseData.data);
    }
  };

  useEffect(() => {
    if (user.role === "USER") {
      getData();
    }
  }, [user]);

  return (
    <>
      <Header />
      {user.role === "USER" && (
        <Box>
          <Container
            disableGutters={true}
            sx={{
              maxWidth: "1440px !important",
              px: 5,
            }}
          >
            {statusSummary && dataSummary && (
              <>
                <DataSummary
                  dataSummary={dataSummary}
                  setDataSummary={setDataSummary}
                />
              </>
            )}
            {(!statusSummary || !dataSummary) && <LoadingScreen />}
          </Container>
          <Director></Director>
        </Box>
      )}
      {user.role === "ADMIN" && (
        <Box>
          <Container
            disableGutters={true}
            sx={{
              maxWidth: "1440px !important",
              px: 5,
            }}
          >
            <h1>Admin page</h1>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Top;
