import request from "../utils/request";

export async function getListApplicant(params) {
  return request(`/api/applicant/list`, {
    method: "POST",
    data: params,
  });
}

export async function getDetailApplicant(id) {
  return request(`/api/applicant/detail/${id}`, {
    method: "get",
  });
}

export async function deleteApplicant(id) {
  return request(`/api/applicant/delete/${id}`, {
    method: "delete",
  });
}

export async function updateApplicant(id, data) {
  return request(`/api/applicant/update/${id}`, {
    method: "post",
    data: data,
  });
}

export async function updateApplicantStatus(data) {
  return request(`/api/status-progress-applicant/create`, {
    method: "post",
    data: data,
  });
}

export async function deleteApplicantStatus(id) {
  return request(`/api/status-progress-applicant/delete/${id}`, {
    method: "delete",
  });
}

export async function sendMail(data) {
  return request(`/api/chat/send-mail/`, {
    method: "post",
    data: data,
  });
}

// multiple update

export async function updateMultipleApplicants(data) {
  return request(`/api/applicant/update-mutiple`, {
    method: "post",
    data: data,
  });
}

export async function readMessage(data) {
  return request(`/api/read-message/`, {
    method: "post",
    data: data,
  });
}

export async function getListTemplateChat() {
  return request(`/api/message-template/getList`, {
    method: "get",
  });
}

export async function getDetailTemplateChat(id) {
  return request(`/api/message-template/detail/${id}`, {
    method: "get",
  });
}
export async function createTimeInterView(data) {
  return request(`/api/status-progress-applicant/update`, {
    method: "POST",
    data: data,
  });
}
