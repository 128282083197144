import { Fragment, useEffect, useState } from "react";
import { defineColumnTableListPosting } from "./defineColumnTableListPosting";
import { Col, Table } from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { duplicatePost, getPostingListData } from "../../../../api/service/posting";
import SearchTablePostingList from "../../SearchTablePostingList";
import { notiHOC } from "../../../../HOC/NotiHOC";
import { paginationCustom } from "../../../../components/paginationCustom";
import ButtonLoadingHOC from "../../../../HOC/ButtonLoadingHOC";
import ModalUpdateStatusPosting from "./modelUpdateStatusPosting";
import ModalDeletePosting from "./modelDeletePosting/ModalDeletePosting";


interface TableParams {
  field?: any;
  order?: any;
  columnKey?: any;
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}
const TableListPosting: React.FC = ({
  setDataStatistical,
  setNumberResult,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState(null);
  const [isLoadingDub, setIsLoadingDub] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const fetchDataForTable = async () => {
    setLoading(true);
    const dataSent: any = {
      ...searchParams,
      page: tableParams.pagination.current,
    };
    if (tableParams.field && tableParams.order) {
      dataSent.order_by = tableParams.columnKey;
      dataSent.order_type = tableParams.order == "ascend" ? "asc" : "desc";
    }
    const response = await getPostingListData(dataSent);
    console.log("response.data.data", response.data.data);

    if (response.status === 200) {
      setLoading(false);
      setListData(response.data.data);

      setDataStatistical(response.data.data);
      setTotal(response.data.data.total);
      setNumberResult(response.data.data.length);
    }
  };

  const columns = defineColumnTableListPosting(fetchDataForTable, listData);

  const onSearchSubmit = (params) => {
    setSearchParams(params);
  };

  useEffect(() => {
    fetchDataForTable();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (listData) {
      fetchDataForTable();
    }
  }, [searchParams]);

  useEffect(() => {
    fetchDataForTable();
  }, []);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<any>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const onDuplicateMultiple = async () => {
    setIsLoadingDub(true);
    const dataSent = [];
    listData.map((el) => {
      if (selectedRowKeys.includes(el.id)) {
        dataSent.push(el.id);
      }
    });
    const dataID = {
      multiplePost: dataSent,
    };

    const response = await duplicatePost(dataID);
    if ((response.status = 200)) {
      setSelectedRowKeys([]);
      fetchDataForTable();
      setIsLoadingDub(false);
    } else {
      setIsLoadingDub(false);
      notiHOC("更新に失敗しました。", false);
    }
  };
  return (
    <Col span={22} className="fix_background_select">
      <SearchTablePostingList onSubmit={onSearchSubmit} loading={loading} />
      <Table
        columns={columns}
        style={{ padding: "20px 0px", minWidth: "90vw" }}
        rowKey={"id"}
        rowSelection={rowSelection}
        loading={loading}
        dataSource={listData}
        pagination={{
          ...tableParams.pagination,
          total: total,
          itemRender: paginationCustom,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          ),
        }}
        onChange={handleTableChange}
      />
      {selectedRowKeys.length > 0 && (
        <div className="process-bot has-shadow-top">
          <div>
            <div className="process-bot-btn">
              <FontAwesomeIcon
                icon={faXmark as IconProp}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedRowKeys([]);
                }}
              />
              <div className="mr-20 ml-20">
                {selectedRowKeys.length} 件の求人情報を選択しています
              </div>
              <ModalUpdateStatusPosting
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                fetchDataForTable={fetchDataForTable}
              />
              <div className="mr-8">
                <ButtonLoadingHOC
                  handelButton={onDuplicateMultiple}
                  name={"複製"}
                  loadingButton={isLoadingDub}
                />
              </div>

              <ModalDeletePosting
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                fetchDataForTable={fetchDataForTable}
                listData={listData}
                postTitle={listData
                  .filter((el) => selectedRowKeys.includes(el.id))
                  ?.map((ele) => {
                    return (
                      <Fragment key={ele.id}>
                        <p>@ {ele.title}</p>
                        <br></br>
                      </Fragment>
                    );
                  })}
              />
            </div>
          </div>
        </div>
      )}
    </Col>
  );
};

export default TableListPosting;
