/* eslint-disable react/prop-types */
import { createContext, useState } from "react";



export const HelpContext = createContext<any>("");

const HelperContextProvider = ({ children }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const helpContextData = {
        searchTerm,
        setSearchTerm
      }
  return (
    <HelpContext.Provider value={helpContextData}>
      {children}
    </HelpContext.Provider>
  );
};

export default HelperContextProvider;
