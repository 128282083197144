import { Breadcrumb } from "antd";

import { Link } from "react-router-dom";

import BackIcon from "../../../components/Icon/BackIcon";

import Header from "../../Header";
import "../style.css";
import TableAdminList from "./TableAdminList";

const AdminList = () => {
  return (
    <>
      <Header />
      <div
        className="inner-block job-new-form px-40"
        style={{ paddingBottom: "80px" }}
      >
        <div className="right-btn-item" style={{ marginTop: 20 }}>
          <Link to="/admin/new" className="btn-border-blue-padding-plus"> 
          掲載企業を追加
          </Link>
        </div>
        <div className="company-item has-shadow">
          <h1 style={{ color: "#0ABAB5", fontWeight: 700 }}>掲載企業一覧</h1>

          <TableAdminList />
        </div>
      </div>
    </>
  );
};

export default AdminList;
