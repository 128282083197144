import request from "../../utils/request";

export async function getCompanyList(data) {
  return request(`/api/company/list`, {
    method: "POST",
    data: data,
  });
}

export async function createCompany(formData) {
  return request(`/api/company/create`, {
    method: "POST",
    data: formData,
  });
}

export async function getCompanyDetail(id) {
  return request(`api/company/detail/${id}`, {
    method: "GET",
  });
}

export async function updateCompanyDetail(formData) {
  return request(`api/company/update-by-admin`, {
    method: "POST",
    data: formData,
  });
}

export async function deleteCompany(id) {
  return request(`api/company/delete/${id}`, {
    method: "POST",
    data: id,
  });
}
