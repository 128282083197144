import { message } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faX , faCheck} from "@fortawesome/free-solid-svg-icons";

export const notiHOC = (title: string, type: boolean) => {
  
  message.open({
    content: type ? (<div style={{backgroundColor:'#cff7ec', padding:'15px',borderRadius:30,color: "#43766c"}}>
      <FontAwesomeIcon
        icon={faCheck as IconProp}
        className="mt-4 mr-8 ml-8"
      />{" "}
      {title}
    </div>) : (<div style={{backgroundColor:'#fe7171', padding:'15px',borderRadius:30,color:'white'}}>
      <FontAwesomeIcon
        icon={faX as IconProp}
        className="mt-4 mr-8 ml-8"
      />{" "}
      {title}
    </div>),

    style: {
      marginTop: '10vh',


    },
    duration: 1,
  });
};