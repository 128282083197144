import SearchIcon from "../../components/Icon/SearchIcon";
import Header from "../Header";
import { Fragment, useContext, useState } from "react";
import DownIcon from "../../components/Icon/DownIcon";
import { Breadcrumbs } from "@mui/material";
import { Breadcrumb } from 'antd';

import { Link } from "react-router-dom";
import BackIcon from "../../components/Icon/BackIcon";
import Router from "../../config/route";

import ChangeEmail from "./HelpComponents/ChangeEmail";
import HelperContextProvider, {
  HelpContext,
} from "./HelpComponents/HelperContext";

const HelpList = ({ title, helpItem }: { title: string; helpItem: any }) => {
  return (
    <ul className="help-list">
      <p className="list-title">{title}</p>
      {helpItem}
    </ul>
  );
};

const HelpItem = ({
  title,
  helpContent,
}: {
  title: string;
  helpContent: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = (e) => {
    e.preventDefault;
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <ul className="help-item">
        <li className="m-b-20 help-item" onClick={toggleContent}>
          <dl>
            <dt>
              <p className="item-title">{title}</p>
              <span className="item-icon">
                <DownIcon></DownIcon>
              </span>
            </dt>
          </dl>
        </li>
        {isOpen === true ? (
          <li className="help-item-content">{helpContent}</li>
        ) : (
          ""
        )}
      </ul>
    </Fragment>
  );
};

const Helper = () => {
  // const [searchTerm, setSearchTerm] = useState("");

  // const helpContextData = {
  //   setSearchTerm
  // }
  const handleKeyPress = (event) => {
    if (event.key === "Enter"){
      setSearchTerm(event.target.value)
    }
  }
  const { searchTerm, setSearchTerm } = useContext(HelpContext);
  const loginHelpArray = [
    {
      title: "ログインメールアドレスを変更したい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "ログインパスワードを変更したい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "アカウントの企業名を変更したい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "アカウントに対するログインIDを増やしたい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "1つのログインIDで複数アカウントを管理したい",
      content: <ChangeEmail></ChangeEmail>,
    },
  ];

  const jobHelpArray = [
    {
      title: "求人原稿を作成したい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "CSV一括入稿をしたい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "画像を一括で原稿に反映したい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "応募時メール送信先の選択肢が出てこない",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "原稿の応募フォームを確認したい",
      content: <ChangeEmail></ChangeEmail>,
    },
  ];

  const statusHelpArray = [
    {
      title: "アカウント内の選考状況を確認したい",
      content: <ChangeEmail></ChangeEmail>,
    },
    {
      title: "これまでの選考結果のサマリーを確認したい",
      content: <ChangeEmail></ChangeEmail>,
    },
  ];

  const otherHelpArray = [
    {
      title: "企業ページを作成したい",
      content: <ChangeEmail></ChangeEmail>,
    },
  ];
  return (
    <HelperContextProvider>
      <Fragment>
        <Header />
        <div className="help-container">
          <div className="help-inner-content">
            <Breadcrumb
              style={{
             
                  padding: "20px 0 40px",
                  width: "1456px",
              
              }}
            >
              <Link to={Router.root}>
                <BackIcon></BackIcon>
                <span className="text-color-black m-l-4">ホームへ戻る</span>
              </Link>
            </Breadcrumb>
            {/* Search Bar */}
            <div className="search-bar-container items-center">
              <div className="search-bar-content">
                <input
                  type="text"
                  placeholder="ヘルプを検索"
                  onKeyDown={handleKeyPress}
                  className="search-bar"
                  value={searchTerm}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                />
                <span style={{cursor: 'pointer'}} onClick={() => {setSearchTerm("")}} className="search-icon">
                  <SearchIcon />
                </span>
              </div>
            </div>

            {/* Help Content */}
            <div className="help-content">
              {/* Login help */}
              <HelpList
                title="アカウント・ログインについて"
                helpItem={
                  <Fragment>
                    {loginHelpArray
                      .filter((filterItem) => {
                        if (searchTerm === "") {
                          return filterItem;
                        } else if (
                          filterItem.title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return filterItem;
                        }
                      })
                      .map((helpItem, index) => {
                        return (
                          <HelpItem
                            key={index}
                            helpContent={helpItem.content}
                            title={helpItem.title}
                          ></HelpItem>
                        );
                      })}
                  </Fragment>
                }
              ></HelpList>

              {/* Jobs Help */}
              <HelpList
                title="求人原稿作成・管理について"
                helpItem={
                  <Fragment>
                    {jobHelpArray
                      .filter((filterItem) => {
                        if (searchTerm === "") {
                          return filterItem;
                        } else if (
                          filterItem.title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return filterItem;
                        }
                      })
                      .map((helpItem, index) => {
                        return (
                          <HelpItem
                            key={index}
                            helpContent={helpItem.content}
                            title={helpItem.title}
                          ></HelpItem>
                        );
                      })}
                  </Fragment>
                }
              ></HelpList>

              {/* Status Help */}
              <HelpList
                title="アカウント・ログインについて"
                helpItem={
                  <Fragment>
                    {statusHelpArray
                      .filter((filterItem) => {
                        if (searchTerm === "") {
                          return filterItem;
                        } else if (
                          filterItem.title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return filterItem;
                        }
                      })
                      .map((helpItem, index) => {
                        return (
                          <HelpItem
                            key={index}
                            helpContent={helpItem.content}
                            title={helpItem.title}
                          ></HelpItem>
                        );
                      })}
                  </Fragment>
                }
              ></HelpList>

              {/* Other */}
              <HelpList
                title="企業ページについて"
                helpItem={
                  <Fragment>
                    {otherHelpArray
                      .filter((filterItem) => {
                        if (searchTerm === "") {
                          return filterItem;
                        } else if (
                          filterItem.title
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return filterItem;
                        }
                      })
                      .map((helpItem, index) => {
                        return (
                          <HelpItem
                            key={index}
                            helpContent={helpItem.content}
                            title={helpItem.title}
                          ></HelpItem>
                        );
                      })}
                  </Fragment>
                }
              ></HelpList>
            </div>
          </div>
        </div>
      </Fragment>
    </HelperContextProvider>
  );
};

export default Helper;
// function createContext() {
//   throw new Error("Function not implemented.");
// }
