import Header from "../../Header";
import { Row, Col, Breadcrumb } from "antd";
import BackIcon from "../../../components/Icon/BackIcon";
import { Link ,useNavigate} from "react-router-dom";
import Router from "../../../config/route";
import  { PlusOutlined } from '@ant-design/icons';




import TableListAccount from "./tableListAccount";



const AccountList = ({}) => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <Row>
        <Col style={{ justifyContent: "flex-end" }} span={24}>
          <div className="inner-block">
            <Breadcrumb
              style={{
                padding: "20px 0 20px",
              }}
            >
           
            </Breadcrumb>
            <div className="applicants-list">
            <div className="d-f job-list-top-btn jc-r mb-20">
              <button className="btn-blue-medium plr-40 link-hover-yl"  onClick={()=>{
                 navigate(Router.new_account);
           
                
              }}>
         <PlusOutlined />     アカウントを追加
              </button>
            </div>
          </div>
          </div>
        </Col>
        <Col>
        <div style={{ padding: " 0 20px" }}>
          <div className="container has-shadow">
          <h1 style={{fontWeight:600, color:'#00C4CC', fontSize:20}}>アカウント一覧</h1>
            
            <TableListAccount/>
          </div>
        </div></Col>
      </Row>
     
    </div>
  );
};

export default AccountList;
