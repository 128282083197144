const BackIcon = () => {
  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 1L1 5.5L5.5 10" stroke="#555555" />
    </svg>
  );
};

export default BackIcon;
