import {  useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput  from 'react-phone-number-input/input';

import { Form,  Button, Input, Space, Radio } from "antd";
import "./index.scss";

import FormInput from "../../../../components/formInput";
import { notiHOC } from "../../../../HOC/NotiHOC";
import { createAccount } from "../../../../api/service/user";
import Router from "../../../../config/route";
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';


const FormCreateNewAccount = ({ }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useNavigate();

  const companyId = window.localStorage.getItem("companyId");
  const [inputPhone, setInputPhone] = useState(null);
  const checkInputPhoneNumber = (_, value) => {
    if (value) {
      if (isValidPhoneNumber(value)) {
        const array = formatPhoneNumber(value).split('');
        if (array[1] == '0') {
          return Promise.reject(new Error('有効な電話番号を入力してください。 1'));
        }
        if (array[0] !== inputPhone?.split('')[0]) {
          return Promise.reject(new Error('有効な電話番号を入力してください。 2'));
        }
        if (array[1] !== inputPhone?.split('')[1]) {
          return Promise.reject(new Error('有効な電話番号を入力してください。3'));
        }
        return Promise.resolve();
      }

      return Promise.reject(new Error('有効な電話番号を入力してください。'));
    }

    return Promise.reject(new Error(''));
  };







  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };
  const onFinish = async (values) => {
    console.log("valuesvalues", values);

    if(values.password != values.confirm_password){
       notiHOC('パスワードの確認が無効です',false)
       return;
    }
    else{
      const dataCreate={
        'name':values.name,
        'email':values.email,
        'password':values.password,
        'role':values.role,
        'phone':values.phone,
        'company_id':companyId
  
      }
      const response = await createAccount(dataCreate);
console.log('response',response);
if ((response.status == 200)) {
     

  notiHOC("アカウントが正常に追加されました", true);
  history(Router.setting)
} else {
  notiHOC("アカウントの追加に失敗しました", false);
}

    }
  
 

  };

  return (
    <div style={{ width: "100%", paddingTop: 30 }} className={"formItem"}>
      <Form
        colon={false}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
       requiredMark={false}
       onChange={(e) => {
        if (e.target.id == 'phone') {
          if (e.target.value) {
            setInputPhone(e.target.value);
          }
        }
      }}
      >

        <FormInput
          name="name"
          required={true}
          labelName="氏名"
          placeholder=""
          showCount={false}
          message={"このフィールドに入力してください"}
        />


        <FormInput
          name="email"
          required={true}
          labelName="メールアドレス"
          placeholder=""
          showCount={false}
          message={"このフィールドに入力してください"}
        />




<Form.Item name="role" rules={[{ required: true, message: 'このフィールドに入力してください' }]}
           label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>権限</div>
              <div className={'required'}>必須 </div>
            </Space>}>
                  <Radio.Group  value={'SUB_ADMIN_HR'}>
                    <Radio value={'SUB_ADMIN_HR'} style={{ marginRight: '50px' }} >メンバー </Radio>
                    <Radio value={'ADMIN_HR'}>マスター</Radio>
                  </Radio.Group>

                </Form.Item>
                <Form.Item
                name={'phone'}
                label={
                  <Space size={14}>
                  <div style={{ fontWeight: 500 }}>直通電話番号</div>
                  <div className={'required'}>必須 </div>
                </Space>}

                  
                  
                
                rules={[
                  {
                    validator: checkInputPhoneNumber,
                  },
                ]}
              >
                <PhoneInput country="JP" placeholder={'03-1234-5678'} className={'inputNumberPhone'} />
              </Form.Item>
        <Form.Item wrapperCol={{span:6}} name={"password"} rules={[{ required: true, message: 'このフィールドに入力してください' }]}
          label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>パスワード</div>
              <div className={'required'}>必須 </div>
            </Space>
          }>

          <Input.Password placeholder="パスワードを入力" size="large" />
        </Form.Item>


        <Form.Item wrapperCol={{span:6}} rules={[{ required: true, message: 'このフィールドに入力してください' }]}
          name={"confirm_password"} label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>パスワード確認用</div>
              <div className={'required'}>必須 </div>
            </Space>
          }>

          <Input.Password placeholder="パスワードを入力" size="large" />
        </Form.Item>






        <div
          className="form-btn-area sticky"
          style={{ display: "flex", justifyContent: "center" }}
        >

          <Button
            htmlType="submit"
            loading={isLoading}
            style={{
              background: "#00c4cc",
              height: 55,
              color: "#fff",
              fontWeight: 700,
              padding: "14px 60px",
              borderRadius: "30px",
            }}
          >
            入力情報を確認
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormCreateNewAccount;
