import request from "../utils/request";

export async function UpImage(file: any) {
  return request(`/api/image/upload-img`, {
    method: "POST",
    data: file,
  });
}

export async function DeleteImage(url: string) {
  return request(`/api/image/delete-img`, {
    method: "POST",
    data: url,
  });
}

export async function UpNewImage(file: any) {
  return request(`/api/image/upload`, {
    method: "POST",
    data: file,
  });
}

export async function DeleteImageById(id, data) {
  return request(`/api/image/delete/${id}`, {
    method: "DELETE",
    params: data,
  });
}
