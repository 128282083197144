import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Snackbar, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { child, get, getDatabase, ref, set } from "firebase/database";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Router from "../../config/route";
import "./style.css";
const firebaseDB = getDatabase();
const auth = getAuth();

const Chat = () => {
  const navigate = useNavigate();
  const [email, setMail] = useState("");
  const [notiFail, setNotiFail] = useState(false);
  const [text, setText] = useState("");
  // const history = useHistory();
  var fullHref = window.location.href;
  var urlOrigin = window.location.origin + "/chat?";
  var strData = fullHref.replace(urlOrigin, "");
  var arrData = strData.split("-");

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, "k1y2u3j4i5n6x")
      .then((userCredential) => {
        const user: any = userCredential.user;
        console.log("Đăng nhập Firebase thành công", user);
        set(ref(firebaseDB, "users/" + user.uid), {
          email: user.email,
          accessToken: user.accessToken,
          lastLoginAt: user.metadata.lastLoginAt,
          isAnonymous: user.isAnonymous,
        });
        if (user.uid == arrData[1]) {
          navigate(`${Router.conversation}/${strData}`);
        } else {
          setNotiFail(true);
          setText("Wrong room");
        }
      })
      .catch((error) => {
        setNotiFail(true);
        setText(error.message);
      });
  };

  const checkLongin = () => {
    onAuthStateChanged(auth, (user) => {
      if (user?.uid == arrData[1]) {
        console.log("this user", user.uid);
        // signOut(auth)
        //   .then(() => {
        //     console.log("Sign-out successful.");
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        // navigate(`${Router.conversation}/${strData}`);
      }
    });
  };

  // useEffect(() => {
  //   checkLongin();
  // }, []);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={notiFail}
        onClose={() => {
          setNotiFail(false);
        }}
        autoHideDuration={3000}
        message={
          <div>
            <FontAwesomeIcon icon={faX} className="mt-4 mr-8 ml-8" /> {text}
          </div>
        }
        key={"top" + "center"}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#fe7171",
            borderRadius: "30px",
            opacity: "0.9",
            color: "white",
            marginTop: "100px",
            boxShadow: "none",
            minWidth: "200px",
          },
        }}
      />

      <Box bgcolor={"#ccc"} height={"100%"}>
        <Box bgcolor={"white"} className="signin-box">
          <Typography
            gutterBottom
            component="div"
            textAlign={"center"}
            className="signin-title"
            color={"black"}
            fontWeight={"bold"}
            mb={1}
            fontSize={"1.1rem"}
          >
            本人確認
          </Typography>
          <Typography
            className="signin-content"
            gutterBottom
            component="p"
            mt={2}
            mb={10}
            sx={{ textAlign: "center", color: "black" }}
          >
            応募時に登録した電話番号を入力してください。
          </Typography>
          <form action="" onSubmit={handleSubmit}>
            <Box display={"flex"}>
              <TextField
                // type="number"
                id="outlined-basic"
                variant="outlined"
                sx={{ marginRight: "10px", width: "100%" }}
                placeholder="email"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
              />
              <Button variant="contained" type="submit">
                送信
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default Chat;
