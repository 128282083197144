import { Breadcrumb, Col, Row, Space } from "antd";
import {  Link} from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Header from "../../Header";
import BackIcon from "../../../components/Icon/BackIcon";
import Router from "../../../config/route";
import TableListPosting from "./tableListPosting";

const ListPosting = ({ }) => {
  const [dataStatistical, setDataStatistical] = useState<any>(null);
  const [numberResult, setNumberResult] = useState<number>(0);

  const navigate = useNavigate();

  return (
     <div>
     <Header />

     <Row style={{justifyContent:'center'}}>
       <Col style={{ float:'right' }} span={24}>
         <div className="inner-block">
           <Breadcrumb
             style={{
               padding: "20px 0 40px",
             }}
           >
             <Link to={"/"}>
               <BackIcon></BackIcon>
               <span className="text-color-black m-l-4">ホームへ戻る</span>
             </Link>
           </Breadcrumb>
        
         </div>
       </Col>
       <Col span={23} style={{ marginBottom:'30px'}}>
       <div className="ta-r">
             <Link
               to={"/"}
               className="color-blue d-ib link-hover-yl"
             >
               コラムページ編集
             </Link>
           </div>
           <div className="btn-job" style={{float: 'right'}}>


             <button className="btn-blue-medium plr-40 link-hover-yl" onClick={()=>{
                 navigate(Router.new_posting);
              }}>
             新菊コラム作成
             </button>
           </div>
       </Col>
       <Col>
       <div className=" job-list pos-r " style={{ padding: "0 20px" }}>
            <div className="container has-shadow">
              <h1 style={{color:'#0ABAB5', fontWeight:700}}>コラム投稿一覧</h1>
              <div className="list-count" style={{ paddingBottom: "20px" }}>
                <Space size={15}>
                  <div className="bold">全{numberResult}件</div>
                  <div>
                  下書：
                    {dataStatistical?.filter(
                      (item) => item.status == 0
                    ).length|| "0"}{" "}
                    件
                  </div>
                  <div>
                  公開：
                    {dataStatistical?.filter(
                      (item) => item.status == 1
                    ).length || "0"}{" "}
                    件
                  </div>
                  <div>
                  終了：
                    {dataStatistical?.filter(
                      (item) => item.status == 2
                    ).length || "0"}{" "}
                    件
                  </div>
                </Space>
              </div>
              
              <TableListPosting  setDataStatistical={setDataStatistical} setNumberResult={setNumberResult}/>
            </div>
          </div>
       </Col>
     </Row>
   </div>

  );
};

export default ListPosting;
