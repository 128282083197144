import { getAuthority, removeAuthority } from "./authority";
import axios, { AxiosRequestConfig } from "axios";

const instance = axios.create({
  baseURL: process.env.API_HOST,
  timeout: 30000,
  withCredentials: false,
});

instance.interceptors.request //REQUEST
  .use(
    async function (config) {
      if (typeof window !== "undefined") {
        // client side
        const authority = getAuthority();
        const headers = { ...config.headers };
        if (authority) {
          headers["Authorization"] = `Bearer ${authority}`;
        }
        headers["X-Requested-With"] = "XMLHttpRequest";
        headers["Content-Type"] = "application/json";
        config.headers = headers;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
instance.interceptors.response.use(
  async function (response) {
    console.log("axios interceptor 1");
    if (typeof window !== "undefined") {
      console.log('response.status', response.status);
      
    } else {
      console.log("response.status.server", response.status);
    }
    return response;
  },
  async (error) => {
    // console.log('axios interceptor 2');
    console.log("error->", error.response);
   
    return {
      response: error?.response ?? null,
      data: error?.response?.data ?? null,
    };
  }
);

const request = (url: string, options: Partial<AxiosRequestConfig>) => {
  return instance.request({ ...options, url: url });
};

export const requestWithToken = (
  url: string,
  token: string,
  options: Partial<AxiosRequestConfig>
) => {
  const headers = { ...options.headers };
  headers["Authorization"] = `Bearer ${token}`;
  options.headers = headers;
  return instance.request({ ...options, url: url });
};

export default request;
