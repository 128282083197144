/* eslint-disable react/display-name */

import { Form } from 'antd';

import {

  Space
} from 'antd';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//Them chỉnh sửa ảnh
import ImageResize from 'quill-image-resize-module-react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactQuill = require('react-quill');
Quill?.register('modules/imageResize', ImageResize);

//thêm danh sách fontSize
// const Size = Quill?.import('attributors/style/size');

// Quill?.register(Size, true);

//thêm icon undo/redo
const icons = Quill?.import('ui/icons');
icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

const Font = Quill?.import('formats/font');
Font.whitelist = [false, 'Ubuntu', 'Raleway', 'Roboto', 'noto-sans-jp', 'arial'];
Quill?.register(Font, true);

// eslint-disable-next-line max-lines-per-function
export default FormEditor => {
  // banner upload prop
  let quill;
  const myUndo = () => {
    const myEditor = quill?.getEditor();
    return myEditor?.history?.undo();
  };

  const myRedo = () => {
    const myEditor = quill?.getEditor();
    return myEditor?.history?.redo();
  };
  const modules = {
    imageResize: {
      parchment: Quill?.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
    },
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {

      container: [
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['undo', 'redo'],
      ],
      handlers: {
        undo: myUndo,
        redo: myRedo,
      },
    },

    clipboard: {
      matchVisual: false,
    },
  };

  function isQuillEmpty(_, value) {
    if (value) {
      if (value?.replace(/<(.|\n)*?>/g, "")?.trim()?.length === 0 && !value?.includes("<img")

      ) {
        return Promise.reject(new Error("テンプレートタイトルフィールドは必須です"))
      }
    }
    return Promise.resolve()
  }


  return (
    <Form.Item name={"content"} label={<Space className="field-name">
      本文<span className="tag-red">必須</span>
    </Space>} rules={[{ required: true, message: 'テンプレートタイトルフィールドは必須です' }, {
      validator: isQuillEmpty
    }]}>
      <ReactQuill
        ref={(el) => {
          quill = el;
        }}
        theme="snow"
        modules={modules}

      />
    </Form.Item>
  );
};
    