/* eslint-disable @typescript-eslint/no-explicit-any */

import { Col, Divider, Row, Space } from "antd";
import moment = require("moment");
import {
  formatPhoneNumber,
} from "react-phone-number-input";
const Preview = ({ dataSent }: any) => {
    console.log('dataaaa', dataSent);
    
  return (
    <Space
      style={{ width: "100%" }}
      split={<Divider type="horizontal" />}
      size={0}
      direction={"vertical"}
    >
      <ItemPreView name={'企業名'} content={dataSent?.name}/>
      <ItemPreView name={'郵便番号'} content={dataSent?.zipcode}/>

      <ItemPreView name={'都道府県'} content={dataSent?.prefecture}/>

      <ItemPreView name={'市区町村'} content={dataSent?.municipalitie}/>

      <ItemPreView name={'番地以降'} content={dataSent?.after_address}/>
      <ItemPreView name={'代表者名'} content={dataSent?.representative_name}/>
      <ItemPreView name={'メールアドレス'} content={dataSent?.email}/>
      <ItemPreView name={'パスワード'} content={'●●●●●●●●●'}/>
      <ItemPreView name={'直通電話番号'} content={formatPhoneNumber(dataSent?.phone)}/>
      <ItemPreView name={'従業員数'} content={dataSent?.number_of_employee}/>
      <ItemPreView name={'資本金'} content={dataSent?.capital}/>
      <ItemPreView name={'設立日'} content={dataSent?.date_of_establishment ? moment(dataSent?.date_of_establishment).format("l") : null}/>
      <ItemPreView name={'アクセス'} content={dataSent?.access}/>
      <ItemPreView name={'担当者氏名'} content={dataSent?.name_of_person_in_charge}/>
      <ItemPreView name={'担当者電話番号'} content={dataSent?.phone_of_person_in_charge}/>
      <ItemPreView name={'担当者メールアドレス'} content={dataSent?.email_of_person_in_charge}/>
     

    </Space>
  );
};

export default Preview;


const ItemPreView = ({name, content}:any)=>{
    return <Row>
        <Col span={5}>{name}</Col>
        <Col span={17}>{content}</Col>
    </Row>
}