import { Link } from "react-router-dom";

import BackIcon from "../../../components/Icon/BackIcon";

import Header from "../../Header";

import { Breadcrumb } from "antd";
import EditCompanyForm from "./EditCompanyForm";

const Edit = () => {
  return (
    <>
      <Header />
      <div
        className="inner-block job-new-form px-40"
        style={{ paddingBottom: "80px" }}
      >
        <div className="inner-block">
          <Breadcrumb
            style={{
              padding: "20px 0 20px",
            }}
          >
            <Link to="/admin">
              <BackIcon></BackIcon>
              <span className="text-color-black m-l-4">求人一覧へ戻る</span>
            </Link>
          </Breadcrumb>
        </div>
        <div className="container">
          <h1 style={{ color: "#0ABAB5", fontWeight: 700 }}>企業基本情報</h1>
          <div>
            <EditCompanyForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
