import { DatePicker, Form, Modal,  Space } from "antd";
import moment = require("moment");
import  { useState } from "react";
import { updateMultipleApplicants } from "../../../../../api/service/applicant";
import ButtonLoadingHOC from "../../../../../HOC/ButtonLoadingHOC";
import { notiHOC } from "../../../../../HOC/NotiHOC";

const ModalUpdateInterviewSchedule: React.FC<any> = ({setSelectedRowKeys,selectedRowKeys, fetchDataForTable}:any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const onFinish = async (value) => {
    console.log(value);
    setLoading(true)
    const payloadData = {
      applicant_id: selectedRowKeys,
      data: {
        interview_schedule: moment(value.scheduleTime).format("YYYY-MM-DD HH:mm"),
      },
    };
      const response = await updateMultipleApplicants(payloadData);
      if(response.status==200){
        setLoading(false)
        setSelectedRowKeys([])
        notiHOC('正常に更新されました。',true)
        fetchDataForTable()
        
      }else{
        setLoading(false) 
        notiHOC('更新に失敗しました。',false)
      }
  };
  return (
    <>
      <a onClick={showModal} className="btn-blue-medium mr-8 btn-hover-yl">
      面接予約送信
      </a>
      <Modal
        centered
        title={<h1>面接予定日入力</h1>}
        open={isModalOpen}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="delete-manager-list">
          <p className="m-t-8">次回面接予定日</p>
          <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
            <Form.Item name="scheduleTime" rules={[{ required: true, message: '選んでください。' }]}>
              <DatePicker format="YYYY-MM-DD HH:mm"  showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}/>
            </Form.Item>
          </Form>
          <div style={{ padding: "20px 0px", textAlign: "end" }}>
            <Space size={15}>
              <span
                onClick={handleCancel}
                className="btn-exit-modal btn-hover-yl"
              >
                キャンセル
              </span>

              <ButtonLoadingHOC
                name={"保存"}
                handelButton={form.submit}
                loadingButton={loading}
              />
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalUpdateInterviewSchedule;
