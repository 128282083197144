

function PassForgotPassword() {
  
  return (
    <>
      <div style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)',backgroundColor:'#fff',padding:'40px',boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} >
        <div >
          <h3 style={{fontSize:'20px',fontWeight:'700',color:'#666666',justifyContent:'center',display:'flex'}}>{'パスワード再設定メールを送信しました'}</h3>
          <div style={{ fontSize: 14, color: '#666666' }}>
            <div>{'パスワード再設定メールを送信しました。'}</div>
            <div>{' メールに記載の案内に従って、パスワードの再設定をお願いいたします。'}</div>
          </div>
          <div style={{ paddingTop: '5%', color: '#666666' }}>
            <h4 style={{fontWeight:'700',color:'#666666'}}>{'以下のような場合にメールが届かないことがあります。'}</h4>
            <ul style={{ listStyle: 'circle' ,paddingLeft:'40px'}}>
              <li>{'メールアドレスに誤りがある'}</li>
              <li>{'迷惑メールとして判定されている'}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default PassForgotPassword;
