import Header from "../Header";
import ProcItem from "./ProcItems";
import { useState } from "react";
import {
  getListProcess,
  updateListProcess,
} from "../../api/service/user";
import { Row, Col, Breadcrumb  } from 'antd';
import { notiHOC } from "../../HOC/NotiHOC";
import React = require("react");
import { Link } from "react-router-dom";
import BackIcon from "../../components/Icon/BackIcon";
import "./style.css";
import LoadingScreen from "../../components/Common/LoadingScreen";
import { AuthContext } from "../../hooks/useAuth";
import Router from "../../config/route";
const ProcessesProc = () => {
  const [procList, setProcList] = useState([]);
  const [notiFail, setNotiFail] = useState(false);
  const { user } = React.useContext(AuthContext);
  const [listStatus, setListStatus] = React.useState(null);
  const [toast, setToast] = useState(false);

  const handleDelete = (e, currentIndex) => {
    const arr = [];
    listStatus.map((status) => {
      if (status.index == currentIndex) {
        status.deleted_at = new Date();
        listStatus.splice(currentIndex - 1, 1);
      }
    });
    listStatus.map((status, i) => {
      status.index = i + 1;
      arr.push(status);
    });
    setListStatus(arr);
  };
  const handleAddMore = () => {
    const newStatus = [
      ...listStatus,
      {
        id: null,
        name: null,
        company_id: user.company_id,
        index: listStatus.length + 1,
        deleted_at: null,
      },
    ];
    setListStatus(newStatus);
  };

  const getData = async () => {
    const list = await getListProcess(user.company_id);
    if (list) setListStatus(list?.data?.data);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const submitForm = async (event) => {
    event.preventDefault();
    const response = await updateListProcess(listStatus);
    if (response.status == 200) {
      notiHOC('求人情報を削除しました。',true)
    } else{
      notiHOC('求人情報を削除しました',false)

    }
  };

  

  return (
    <>
      <Header />


      <Row 
       style={{justifyContent:'center'}}
      >
<Col span={18}>
<Breadcrumb
          style={{
        
              margin: "20px auto 40px",
       
          }}
        >
          <Link to={Router.root}>
            <BackIcon></BackIcon>
            <span className="text-color-black m-l-4">ホームへ戻る</span>
          </Link>
        </Breadcrumb>
        <div className="processes-item" style={{ paddingBottom: "100px" }}>
          <div className="box-status has-shadow">
            <h1>
              選考プロセス管理
              <span>※選考ステータスは重複して登録することはできません</span>
            </h1>
            {!listStatus && <LoadingScreen />}
            {listStatus && (
              <form onSubmit={submitForm}>
                <ul>
                  {listStatus &&
                    listStatus.map((status, i) => {
                      return (
                        <ProcItem
                          key={`${status.id}` + `${i}`}
                          status={status}
                          listStatus={listStatus}
                          setListStatus={setListStatus}
                          index={status.index}
                          handleDelete={handleDelete}
                        ></ProcItem>
                      );
                    })}
                </ul>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddMore();
                  }}
                  className="btn-more-add"
                >
                  <img src={require("../../../img/common/icon-add.png")} />
                  選考プロセスを追加
                </a>
                <div className="btn-flex-item">
                  <input
                    type="submit"
                    className="btn-blue-big m-t-40"
                    value="保存する"
                  />
                </div>
              </form>
            )}
          </div>
        </div>

</Col>
      </Row>
    </>
  );
};

export default ProcessesProc;
