import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Space,
  Form,
  Input,
  Select,
  Row,
  Col,
  Image,
  Button,
  Spin,
} from "antd";
import moment = require("moment");
import { useState } from "react";
import { Link } from "react-router-dom";
import { updateApplicant } from "../../../../api/service/applicant";
import Router from "../../../../config/route";
import { notiHOC } from "../../../../HOC/NotiHOC";
import "./index.scss";
import ModalDeleteApplicant from "./ModalDeleteApplicant";
const { TextArea } = Input;

const DetailApplicantContain: React.FC<any> = ({ applicant, id,setApplicant }: any) => {
  const [form] = Form.useForm();
  const [currentFocus, setCurrentFocus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (values) => {
    console.log("values", values);
    setIsLoading(true);
    const data = {
      memo: values.memo,
      motivation: values.motivation,
      remark: values.remark,
      item_1: values.item_1,
      item_2: values.item_2,
      item_3: values.item_3,
      opportunity_change_job: values.opportunity_change_job,
    };
    const response = await updateApplicant(id, data);
    if (response.status === 200) {
      setIsLoading(false);
      setApplicant(response.data.data)
      notiHOC("求人情報を保存しました。", true);
    } else {
      setIsLoading(false);

      notiHOC("更新に失敗しました。", false);
    }
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };

 


  return (
    <Spin spinning={isLoading}>
      <div className="panel-detail-appplicant formItem">
        {applicant && (
          <Form
            className="applicants-detail"
            colon={false}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            form={form}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remark: applicant.remark,
              item_1: applicant.item_1,
              item_2: applicant.item_2,
              item_3: applicant.item_3,
              memo: applicant.memo,
              opportunity_change_job: applicant.opportunity_change_job,
              motivation: applicant.motivation,
            }}
            onFinish={onFinish}
          >
            <Row>
              <Col span={4}>
                <div
                  className="applicants-detail-anchor"
                  style={{ width: "100%" }}
                >
                  <ul style={{ width: "100%" }}>
                    <li
                      className={
                        currentFocus === "applicants-overview" ? "current" : ""
                      }
                    >
                      <a
                        href="#applicants-overview"
                        onClick={() => {
                          setCurrentFocus("applicants-overview");
                        }}
                      >
                        概要
                      </a>
                    </li>
                    <li
                      className={
                        currentFocus === "applicants-resume" ? "current" : ""
                      }
                    >
                      <a
                        href="#applicants-resume"
                        onClick={() => {
                          setCurrentFocus("applicants-resume");
                        }}
                      >
                        履歴書
                      </a>
                    </li>
                    <li
                      className={
                        currentFocus === "applicants-base-info" ? "current" : ""
                      }
                    >
                      <a
                        href="#applicants-base-info"
                        onClick={() => {
                          setCurrentFocus("applicants-base-info");
                        }}
                      >
                        基本情報
                      </a>
                    </li>
                    <li
                      className={
                        currentFocus === "applicants-info2" ? "current" : ""
                      }
                    >
                      <a
                        href="#applicants-info2"
                        onClick={() => {
                          setCurrentFocus("applicants-info2");
                        }}
                      >
                        詳細情報
                      </a>
                    </li>
                    <li
                      className={
                        currentFocus === "applicants-memo" ? "current" : ""
                      }
                    >
                      <a
                        href="#applicants-memo"
                        onClick={() => {
                          setCurrentFocus("applicants-memo");
                        }}
                      >
                        メモ
                      </a>
                    </li>
                    <li
                      className={
                        currentFocus === "applicants-management"
                          ? "current"
                          : ""
                      }
                    >
                      <a
                        href="#applicants-management"
                        onClick={() => {
                          setCurrentFocus("applicants-management");
                        }}
                      >
                        入社後管理用
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={20}>
                <div
                  className="results-item applicants-results-item"
                  id="applicants-overview"
                >
                  <div className="results-ttl-item">
                    <div className="results-ttl">概要</div>
                  </div>
                  <Form.Item
                    style={{ paddingTop: 20 }}
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"氏名"}</div>
                      </Space>
                    }
                  >
                    <div>
                      {applicant.first_name_kanji} {applicant.last_name_kanji}
                    </div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"性別"}</div>
                      </Space>
                    }
                  >
                    <div>
                      {(applicant?.gender == 0 && "男性") ||
                        (applicant?.gender == 1 && "女性") ||
                        "その他"}
                    </div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"応募求人"}</div>
                      </Space>
                    }
                  >
                    <div>
                      <span className="color-blue d-b">
                        [{applicant.job_name ?? null}]{" "}
                        {applicant.job_type_name ?? null}／
                        {applicant.listed_company_name ?? null}
                      </span>
                      {applicant.job_work_memo ?? null}
                    </div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"応募"}</div>
                      </Space>
                    }
                  >
                    <div>
                      {applicant?.created_at
                        ? moment(applicant?.created_at).format(
                            "YYYY/MM/DD hh:mm"
                          )
                        : ""}
                    </div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"応募経路"}</div>
                      </Space>
                    }
                  >
                    <div>Indeed</div>
                  </Form.Item>
                </div>
                <div
                  className="results-item applicants-results-item"
                  id="applicants-resume"
                >
                  <div className="results-ttl-item">
                    <div className="results-ttl">履歴書</div>
                  </div>
                  <Form.Item
                    style={{ paddingTop: 20 }}
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"履歴書"}</div>
                      </Space>
                    }
                  >
                    <a
                      href={applicant.link_cv}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {applicant.name_cv}
                    </a>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"備考"}</div>
                      </Space>
                    }
                  >
                    <Form.Item name={"remark"}>
                      <TextArea
                        placeholder={"備考"}
                        size="large"
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            ` ${count}/ ${maxLength} 文字`,
                        }}
                        maxLength={3000}
                        autoSize={{ minRows: 4, maxRows: 4 }}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div
                  className="results-item applicants-results-item"
                  id="applicants-base-info"
                >
                  <div className="results-ttl-item">
                    <div className="results-ttl">基本情報</div>
                    <div className="results-btn"></div>
                  </div>
                  <Form.Item
                    style={{ paddingTop: 20 }}
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"氏名（カナ）"}</div>
                      </Space>
                    }
                  >
                    <div>
                      {applicant.first_name_kata} {applicant.last_name_kata}
                    </div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"電話番号"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant?.phone}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>
                          {"メールアドレス"}
                        </div>
                      </Space>
                    }
                  >
                    <div>{applicant.email}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"生年月日"}</div>
                      </Space>
                    }
                  >
                    {applicant.birthday && (
                      <div>
                        {moment(applicant?.birthday).format("YYYY/MM/DD") ??
                          null}
                        （{" "}
                        {moment().diff(
                          moment(new Date(applicant.birthday)).format(
                            "YYYY-MM-DD"
                          ),
                          "years",
                          false
                        )}
                        歳）
                      </div>
                    )}
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"現住所"}</div>
                      </Space>
                    }
                  >
                    <div> {applicant.address}</div>
                  </Form.Item>
                </div>
                <div
                  className="results-item applicants-results-item"
                  id="applicants-info2"
                >
                  <div className="results-ttl-item">
                    <div className="results-ttl">詳細情報</div>
                    <div className="results-btn"></div>
                  </div>
                  <Form.Item
                    style={{ paddingTop: 20 }}
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"職業"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant.job_name}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"最終学歴"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant.education}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>
                          {"現在（直近）の職種"}
                        </div>
                      </Space>
                    }
                  ></Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"転職経験"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant.job_change_experience}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"直近の年収"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant.recent_manual_income ?? null}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"就業状態"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant.working_status ?? null}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"直近の企業名"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant.company_name_recent ?? null}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"在籍期間"}</div>
                      </Space>
                    }
                  >
                    <div>{applicant.enrollment_period ?? null}</div>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14} direction="vertical">
                        <div style={{ fontWeight: 500 }}>{"自由項目1"}</div>
                        <div style={{ fontWeight: 500, height: "fit-content" }}>
                          簡単な職務経歴・お持ちの経験スキルなどご記入ください
                          <br />
                          （※資料添付済みの方は不要「添付済み」）
                        </div>
                      </Space>
                    }
                  >
                    <Form.Item name={"item_1"}>
                      <TextArea
                        placeholder={""}
                        size="large"
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            ` ${count}/ ${maxLength} 文字`,
                        }}
                        maxLength={1000}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14} direction="vertical">
                        <div style={{ fontWeight: 500 }}>{"自由項目2"}</div>
                        <div style={{ fontWeight: 500, height: "fit-content" }}>
                          簡単な職務経歴・お持ちの経験スキルなどご記入ください
                          <br />
                          （※資料添付済みの方は不要「添付済み」）
                        </div>
                      </Space>
                    }
                  >
                    <Form.Item name={"item_2"}>
                      <TextArea
                        placeholder={""}
                        size="large"
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            ` ${count}/ ${maxLength} 文字`,
                        }}
                        maxLength={1000}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14} direction="vertical">
                        <div style={{ fontWeight: 500 }}>{"自由項目3"}</div>
                        <div style={{ fontWeight: 500, height: "fit-content" }}>
                          簡単な職務経歴・お持ちの経験スキルなどご記入ください
                          <br />
                          （※資料添付済みの方は不要「添付済み」）
                        </div>
                      </Space>
                    }
                  >
                    <Form.Item name={"item_3"}>
                      <TextArea
                        placeholder={""}
                        size="large"
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            ` ${count}/ ${maxLength} 文字`,
                        }}
                        maxLength={1000}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div
                  className="results-item applicants-results-item"
                  id="applicants-memo"
                >
                  <div className="results-ttl-item">
                    <div className="results-ttl">メモ</div>
                    <div className="results-btn"></div>
                  </div>
                  <Form.Item
                    style={{ paddingTop: 20 }}
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"メモ"}</div>
                        <div className={"not_required"}>{"任意"} </div>
                      </Space>
                    }
                  >
                    <Form.Item name={"memo"}>
                      <TextArea
                        placeholder={""}
                        size="large"
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            ` ${count}/ ${maxLength} 文字`,
                        }}
                        maxLength={1000}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div
                  className="results-item applicants-results-item"
                  id="applicants-management"
                >
                  <div className="results-ttl-item">
                    <div className="results-ttl">入社後管理</div>
                    <div className="results-btn"></div>
                  </div>
                  <Form.Item
                    style={{ paddingTop: 20 }}
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>
                          {"転職のきっかけ"}
                        </div>
                        <div className={"not_required"}>{"任意"} </div>
                      </Space>
                    }
                  >
                    <Form.Item name={"opportunity_change_job"}>
                      <TextArea
                        placeholder={""}
                        size="large"
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            ` ${count}/ ${maxLength} 文字`,
                        }}
                        maxLength={1000}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form.Item>
                  <div className="line_full_width"></div>
                  <Form.Item
                    label={
                      <Space size={14}>
                        <div style={{ fontWeight: 500 }}>{"志望動機"}</div>
                        <div className={"not_required"}>{"任意"} </div>
                      </Space>
                    }
                  >
                    <Form.Item name={"motivation"}>
                      <TextArea
                        placeholder={""}
                        size="large"
                        showCount={{
                          formatter: ({ count, maxLength }) =>
                            ` ${count}/ ${maxLength} 文字`,
                        }}
                        maxLength={1000}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="d-f applicants-detail-btn">
                  <div className="d-f">
                    <div className="txt-center">
                      <Link
                        to={Router.applicants}
                        className="btn-border-blue-blod-big m-t-40 mr-20"
                      >
                        キャンセル
                      </Link>
                    </div>
                    <div className="txt-center">
                      <input
                        type="submit"
                        value="保存する"
                        className="btn-blue-big m-t-40"
                      />
                    </div>
                  </div>
                  <div>
                   <ModalDeleteApplicant id={id} applicant={applicant}/>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Spin>
  );
};
export default DetailApplicantContain;
