/* eslint-disable @typescript-eslint/no-explicit-any */
import moment = require("moment");
export const handleDataSentUpdateCompany = (values, id) => {
  const params: any = {};
params.id= id,
  params.email_company = values?.email;
  params.password = values.password;
  params.name = values.name;
  params.address = values.address;
  params.representative_name = values.representative_name;
  params.phone = values.phone ;
  params.num_employee= values.number_of_employee;
  params.capital_stock = values.capital;
  params.founded_at = values.date_of_establishment ? moment(values.date_of_establishment).format('YYYY/MM/DD'): null;
  params.access= values.access;
  params.name_personnel= values.name_of_person_in_charge;
  params.phone_personnel= values.phone_of_person_in_charge;
  params.email_personnel= values.email_of_person_in_charge
  return params;
};
