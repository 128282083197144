import { responsiveProperty } from "@mui/material/styles/cssUtils";
// firebase
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth();
const firebaseDB = getDatabase();

// firebase
interface LoginResponse {
  [x: string]: any;
  email: string;
  uid: string;
  id: number;
  provider: string;
  allow_password_change: boolean;
  access_token: string;
  role: string;
  company_id: number;
  company: object;
}

type User = LoginResponse;

const login = async (
  email: string,
  password: string
): Promise<ApiResponse<LoginResponse>> => {
  const url = new URL("/api/login", process.env.API_HOST);
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  console.log("tài khoản đăng nhập", email);
  signInWithEmailAndPassword(firebaseAuth, email, password)
    .then((userCredential) => {
      console.log('userCredential',userCredential);
      
      const user: any = userCredential.user;
      console.log("Đăng nhập Firebase thành công", user);
      set(ref(firebaseDB, "users/" + user.uid), {
        email: user.email,
        accessToken: user.accessToken,
        lastLoginAt: user.metadata.lastLoginAt,
        phoneNumber: "",
        isAnonymous: user.isAnonymous,
      });
    })
    .catch((error) => {
      console.log("error", error.code, error.message);
    });
  const resp = await window.ApiService.fetch<LoginResponse>(url.toString(), {
    method: "POST",
    body: formData,
  });
  return resp.json();
};

const logout = () => {
  signOut(firebaseAuth)
    .then(() => {
      console.log("Sign-out successful.");
    })
    .catch((error) => {
      // An error happened.
    });
  const url = new URL("/api/logout", process.env.API_HOST);
  return window.ApiService.fetch(url.toString(), { method: "POST" });
};

const userInfo = async () => {
  const url = new URL("/api/user/info", process.env.API_HOST);
  const resp = await window.ApiService.fetch<LoginResponse>(url.toString());
  return resp.json();
};

const forgotPassword = async (email: string) => {
  const url = new URL("/api/forgot-password", process.env.API_HOST);
  const formData = new FormData();
  formData.append("email", email);
  formData.append("redirect_url", process.env.APP_HOST + "/set_new_password");
  window
    .fetch(url.toString(), {
      method: "POST",
      body: formData,
    })
    .then((resp) => {
      return resp.json();
    });
};

const resetPassword = async (formData: FormData) => {
  const url = new URL("/api/user/change-password", process.env.API_HOST);
  return window.ApiService.fetch<LoginResponse>(url.toString(), {
    method: "POST",
    body: formData,
  }).then((resp) => resp.json());
};

export {
  login,
  logout,
  userInfo,
  forgotPassword,
  LoginResponse,
  User,
  resetPassword,
};
