import request from "../../utils/request";


export async function getCompanyDetail(id) {
  return request(`/api/company/detail/${id}`, {
    method:"GET"
  })
}


