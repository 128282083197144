import { Backdrop, CircularProgress } from "@mui/material";

const LoadingScreen = () => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={true}
      >
        <CircularProgress color="success" />
      </Backdrop>
    </>
  );
};

export default LoadingScreen;
