import { UserOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

const FormLoginInPutEmail = ({ disabled }) => {
  return (
    <div>
      <Form.Item
        name="email"
        label=""
        rules={[
          { type: 'email', message: '有効なメールアドレスではありません' },
          { required: true, message: 'メールアドレスを入力してください' },
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          disabled={disabled || false}
          
          size="large"
        />
      </Form.Item>
    </div>
  );
};
export default FormLoginInPutEmail;
