import request from "../utils/request";

export async function listImageAvailable(data) {
    return await request(`/api/image/list`, {
        method:"GET",
        params:data
    })
}

export async function deleteImage(id:number) {
    return await request(`/api/image/delete/${id}`, {
        method:"DELETE"
    })
}