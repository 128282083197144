import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Button, Input, Space, Radio } from "antd";
import { AuthContext } from "../../../../hooks/useAuth";
import "./index.scss";

import FormInput from "../../../../components/formInput";
import { notiHOC } from "../../../../HOC/NotiHOC";
import { getDetailAccount, updateAccount } from "../../../../api/service/user";
import Router from "../../../../config/route";



const FormEditAccount = ({ }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
 const history = useNavigate();
 const { id } = useParams();
 const [data, setData]= useState(null)

 const fetchEditData = async () => {
  const response = await getDetailAccount(id);
console.log(response);

  if (response.status == 200) {
   

    setData(response.data.data)
    

  } else {
    console.log("No data");
  }
};

useEffect(() => {
  fetchEditData()
}, [])

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };
  const onFinish = async (values) => {
    console.log("valuesvalues", values);

    if(values.password != values.confirm_password){
      notiHOC('パスワードの確認が無効です',false)
      return;
   }
   else{
     const dataUpdate={
       'name':values.name,
       'role':values.role,
       'phone':values.phone,

 
     }
     const response = await updateAccount(id,dataUpdate);
console.log('response',response);
if ((response.status == 200)) {
    

 notiHOC("アカウントの更新が成功しました", true);
 history(Router.setting)
} else {
 notiHOC("アカウントの更新に失敗しました", false);
}

   }
 




  };


  return (
    <div style={{ width: "100%", paddingTop: 30 }} className={"formItem"}>
   { data &&    <Form
        colon={false}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
       requiredMark={false}
       initialValues={{
        name: data.name,
       email: data.email,
        phone: data.phone,
       role: data.role,
     
      }}
      >

        <FormInput
          name="name"
          required={true}
          labelName="氏名"
          placeholder=""
          showCount={false}
          message={"このフィールドに入力してください"}
        />


        <FormInput
          name="email"
          required={true}
          labelName="メールアドレス"
          placeholder=""
          showCount={false}
          message={"このフィールドに入力してください"}
        />


 

<Form.Item name="role" rules={[{ required: true, message: 'このフィールドに入力してください' }]}
           label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>権限</div>
              <div className={'required'}>必須 </div>
            </Space>}>
                  <Radio.Group  value={'SUB_ADMIN_HR'}>
                    <Radio value={'SUB_ADMIN_HR'} style={{ marginRight: '50px' }} >SUB_ADMIN_HR</Radio>
                    <Radio value={'ADMIN_HR'}>ADMIN_HR</Radio>
                  </Radio.Group>

                </Form.Item>
        <FormInput
          name="phone"
          required={true}
          labelName="電話番号"
          placeholder="03-1234-5678"
          showCount={false}
          message={"このフィールドに入力してください"}
        />
        <Form.Item wrapperCol={{span:6}} name={"password"} rules={[{ required: true, message: 'このフィールドに入力してください' }]}
          label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>パスワード</div>
              <div className={'required'}>必須 </div>
            </Space>
          }>

          <Input.Password placeholder="パスワードを入力" size="large" />
        </Form.Item>


        <Form.Item wrapperCol={{span:6}} rules={[{ required: true, message: 'このフィールドに入力してください' }]}
          name={"confirm_password"} label={
            <Space size={14}>
              <div style={{ fontWeight: 500 }}>パスワード確認用</div>
              <div className={'required'}>必須 </div>
            </Space>
          }>

          <Input.Password placeholder="パスワードを入力" size="large" />
        </Form.Item>






        <div
          className="form-btn-area sticky"
          style={{ display: "flex", justifyContent: "center" }}
        >

          <Button
            htmlType="submit"
            loading={isLoading}
            style={{
              background: "#00c4cc",
              height: 55,
              color: "#fff",
              fontWeight: 700,
              padding: "14px 60px",
              borderRadius: "30px",
            }}
          >
            入力情報を確認
          </Button>
        </div>
      </Form>

   }
    </div>
  );
};

export default FormEditAccount;
