import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Space, Form, Button, DatePicker } from "antd";
import "./index.scss";

import { notiHOC } from "../../../../HOC/NotiHOC";

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { createCompany } from "../../../../api/session/admin/company";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import FormInputInSuperAdmin from "../../../../components/FormUseInSuperAdmin/FormInputInSuperAdmin";
import Preview from "./Preview";
import { handleDataSentCreateCompany } from "./handleDataSentCreateCompany";
const firebaseAuth = getAuth();

const NewCompanyForm = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [inputPhone, setInputPhone] = useState(null);
  const [step, setStep] = useState(1);
  const [dataSent, setDataSent] = useState(null);
  const navigate = useNavigate();

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };
  console.log("dataSent", dataSent);

  const onFinish = async (values) => {
    console.log("valuesvalues", values);
    values.role = "USER";
    setDataSent(values);
    setStep(2);
  };
  const handleOk = async () => {
    setIsLoading(true);
    const dataSentToSever = handleDataSentCreateCompany(dataSent);
    const response = await createCompany(dataSentToSever);
    const email = dataSent.email;
    const password = dataSent.password;
    if (response.status == 200) {
      createUserWithEmailAndPassword(firebaseAuth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("new user firebase", user);

          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
      notiHOC("アカウントの新規登録を成功しました。", true);
      setStep(3);
    } else {
      setIsLoading(false);
      notiHOC("更新に失敗しました。", false);
    }
  };

  const checkInputPhoneNumber = (_, value) => {
    if (value) {
      if (isValidPhoneNumber(value)) {
        const array = formatPhoneNumber(value).split("");
        if (array[1] == "0") {
          return Promise.reject(
            new Error("有効な電話番号を入力してください。")
          );
        }
        if (array[0] !== inputPhone?.split("")[0]) {
          return Promise.reject(
            new Error("有効な電話番号を入力してください。")
          );
        }
        if (array[1] !== inputPhone?.split("")[1]) {
          return Promise.reject(
            new Error("有効な電話番号を入力してください。")
          );
        }
        return Promise.resolve();
      }

      return Promise.reject(new Error("有効な電話番号を入力してください。"));
    }

    return Promise.reject(new Error(""));
  };
  return (
    <>
      <div className="container">
        <h1 style={{ color: "#0ABAB5", fontWeight: 700 }}>
          掲載企業登録フォーム
        </h1>
        {step == 1 && (
          <p className=" mt-20 ">
            以下のフォームをご記入の上、「入力内容を確認する」ボタンをクリックしてください。
            <br />
            ※ご記入いただいた項目は後から変更可能です。
          </p>
        )}
        {step == 2 && (
          <p className=" mt-20 ">
            ご入力内容をご確認の上、登録ボタンを推してください。
          </p>
        )}
        {step == 3 && (
          <div className=" mt-20 ">
            <h1>登録が完了しました</h1>
            <p>「ホームへ戻る」ボタンからトップへ戻ってください。</p>
          </div>
        )}
        <div>
          <div style={{ width: "100%", paddingTop: 10 }} className={"formItem"}>
            {step == 1 && (
              <Form
                colon={false}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                form={form}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                onChange={(e: any) => {
                  if (e.target.id == "phone") {
                    if (e.target.value) {
                      setInputPhone(e.target.value);
                    }
                  }
                }}
                initialValues={{
                  name: dataSent?.name,
                  zipcode: dataSent?.zipcode,
                  prefecture: dataSent?.prefecture,
                  municipalitie: dataSent?.municipalitie,
                  after_address: dataSent?.after_address,
                  representative_name: dataSent?.representative_name,
                  email: dataSent?.email,
                  password: dataSent?.password,
                  phone: dataSent?.phone,
                  number_of_employee: dataSent?.number_of_employee,
                  capital: dataSent?.capital,
                  date_of_establishment: dataSent?.date_of_establishment,
                  access: dataSent?.access,
                  name_of_person_in_charge: dataSent?.name_of_person_in_charge,
                  phone_of_person_in_charge:
                    dataSent?.phone_of_person_in_charge,
                  email_of_person_in_charge:
                    dataSent?.email_of_person_in_charge,
                }}
              >
                <FormInputInSuperAdmin
                  name="name"
                  required={true}
                  labelName="企業名"
                  placeholder="株式会社○○○○"
                  message="入力してください。"
                />

                <Form.Item
                  label={
                    <Space size={14}>
                      <div className={"required_admin"}>{"必須"}</div>
                      <div style={{ fontWeight: 500 }}>{"企業所在地"}</div>
                    </Space>
                  }
                >
                  <FormInputInSuperAdmin
                    name="zipcode"
                    required={false}
                    labelName="郵便番号"
                    placeholder="5400000"
                    width={200}
                  />
                  <FormInputInSuperAdmin
                    name="prefecture"
                    required={true}
                    labelName="都道府県"
                    message="入力してください。"
                    placeholder="東京都"
                    width={120}
                  />
                  <FormInputInSuperAdmin
                    name="municipalitie"
                    required={true}
                    labelName="市区町村"
                    message="入力してください。"
                    placeholder="渋谷区"
                  />
                  <FormInputInSuperAdmin
                    name="after_address"
                    required={false}
                    labelName="番地以降"
                    placeholder="渋谷1-2-3 渋谷ビル123"
                  />
                </Form.Item>

                <FormInputInSuperAdmin
                  name="representative_name"
                  required={true}
                  placeholder="代表者名を入力"
                  labelName="代表者名"
                  message="入力してください。"
                  width={325}
                />

                <FormInputInSuperAdmin
                  name="email"
                  required={true}
                  placeholder="メールアドレスを入力"
                  labelName="メールアドレス"
                  message="入力してください。"
                  placeEmail={true}
                  width={325}
                />

                <FormInputInSuperAdmin
                  name="password"
                  required={true}
                  placeholder="パスワードを入力"
                  labelName="パスワード"
                  message="入力してください。"
                />

                <Form.Item
                  label={
                    <Space size={14}>
                      <div className={"not_required_admin"}>{"任意"} </div>

                      <div style={{ fontWeight: 500 }}>{"直通電話番号"}</div>
                    </Space>
                  }
                >
                  <Form.Item
                    name={"phone"}
                    rules={[
                      {
                        validator: checkInputPhoneNumber,
                      },
                    ]}
                  >
                    <PhoneInput
                      country="JP"
                      placeholder={"03-1234-5678"}
                      className={"inputNumberPhone"}
                    />
                  </Form.Item>
                </Form.Item>
                <FormInputInSuperAdmin
                  name="number_of_employee"
                  required={false}
                  placeholder="1,000"
                  labelName="従業員数"
                />
                <FormInputInSuperAdmin
                  name="capital"
                  required={false}
                  placeholder="5,000,000"
                  labelName="資本金（円）"
                />

                <Form.Item
                  label={
                    <Space size={14}>
                      <div className={"not_required_admin"}>{"任意"}</div>
                      <div style={{ fontWeight: 500 }}>{"設立日"}</div>
                    </Space>
                  }
                >
                  <Form.Item name="date_of_establishment">
                    <DatePicker format={"l"}/>
                  </Form.Item>
                </Form.Item>
                <FormInputInSuperAdmin
                  name="access"
                  required={false}
                  placeholder="恵比寿駅　徒歩５分"
                  labelName="アクセス"
                />
                <Form.Item
                  label={
                    <Space size={14}>
                      <div className={"required_admin"}>{"必須"}</div>
                      <div style={{ fontWeight: 500 }}>{"企業所在地"}</div>
                    </Space>
                  }
                >
                  <FormInputInSuperAdmin
                    name="name_of_person_in_charge"
                    required={true}
                    placeholder="人事担当者名を入力"
                    labelName="担当者名"
                    message="入力してください。"
                    width={325}
                  />
                  <FormInputInSuperAdmin
                    name="phone_of_person_in_charge"
                    required={true}
                    placeholder="電話番号を入力"
                    message="入力してください。"
                    labelName="直通電話番号"
                    width={325}
                  />
                  <FormInputInSuperAdmin
                    name="email_of_person_in_charge"
                    required={true}
                    placeholder="メールアドレスを入力"
                    labelName="メールアドレス"
                    message="入力してください。"
                    width={325}
                  />
                </Form.Item>
              </Form>
            )}
            {step == 2 && <Preview dataSent={dataSent} />}
          </div>
        </div>
      </div>
      {step == 1 && (
        <div className="form-btn-area sticky" style={{ textAlign: "end" }}>
          <Button
            style={{ background: "#00c4cc", color: "#fff" }}
            size="large"
            onClick={() => {
              form.submit();
            }}
          >
            {"入力情報を確認"}
          </Button>
        </div>
      )}
      {step == 2 && (
        <div style={{ textAlign: "end" }}>
          <Space className="form-btn-area " size={15}>
            <Button
              onClick={() => {
                setStep(1);
              }}
              size="large"
              disabled={isLoading}
            >
              {"内容を修正"}
            </Button>
            <Button
              style={{ background: "#00c4cc", color: "#fff" }}
              size="large"
              loading={isLoading}
              onClick={() => {
                handleOk();
              }}
            >
              {"入力情報を確認"}
            </Button>
          </Space>
        </div>
      )}
      {step == 3 && (
        <div style={{ textAlign: "end" }}>
          <Button
            style={{ background: "#00c4cc", color: "#fff" }}
            size="large"
            onClick={() => {
              navigate("/admin");
            }}
          >
            {"トップへ戻る"}
          </Button>
        </div>
      )}
    </>
  );
};

export default NewCompanyForm;
