import  { useState, useEffect } from "react";
import { Button, Modal, Select, Space } from "antd";
import { getListTemplateChat } from "../../../../api/service/applicant";
import { useNavigate } from "react-router-dom";
import Router from "../../../../config/route";


const ModalSelectTemplate: React.FC<any> = ({form}:any) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listTemplateChat, setListTemplateChat] = useState<any>(null);
  const [contentSelected, setContentSelected] = useState(null);
  const getTemplateChat = async () => {
    const response = await getListTemplateChat();
    if (response.status == 200) {
      setListTemplateChat(response.data.data);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setContentSelected(null)
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      getTemplateChat();
    }
  }, [isModalOpen]);
  return (
    <>
      <Button
        type="link"
        style={{ marginBottom: 10, color: "#0ABAB5", fontWeight: 600 }}
        onClick={showModal}
      >
        テンプレート選択
      </Button>
      <Modal
        title={<div style={{fontSize:18, fontWeight:500}}>{"返信テンプレート"}</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            width: "100%",
            borderTop: "1px solid#cccccc",
            paddingTop: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <div
            style={{ color: "#007bff", display: "inline", cursor: "pointer" }}
            onClick={() => {
              navigate(Router.message_templates);
            }}
          >
            メッセージテンプレート管理
          </div>{" "}
          でテンプレートの追加・編集・削除が可能です
        </div>
        <Space style={{ width: "100%", padding: 15 }}>
          <Select
            placeholder={"選択してください。"}
            size={"large"}
            style={{ width: 330 }}
            value={contentSelected}
            onChange={(e)=>{
              setContentSelected(e)
            }}
          >
            {listTemplateChat?.map((el) => {
              return (
                <Select.Option key={el.id} value={el.id}>
                  {el.title}
                </Select.Option>
              );
            })}
          </Select>
          <Button
            style={{
              color: "white",
              backgroundColor: "#00c4cc",
              border: "none",
              borderRadius: "100vh",
              fontSize: "16px",
              fontWeight: "bold",

              margin: "0 10px",
              width: "100px",
              height: 40,
            }}
            disabled={!contentSelected}
            onClick={()=>{
              form.setFieldsValue({message:listTemplateChat.filter((el)=> el.id == contentSelected)[0]?.content})
            handleCancel()
            }}
          >
            入力
          </Button>
        </Space>

        {contentSelected && <div style={{background:'rgb(241 239 239 / 93%)', margin:15, padding:15}}>{listTemplateChat.filter((el)=> el.id == contentSelected)[0]?.content}</div>}
        <div style={ {margin:15, padding:15}}></div>
      </Modal>
    </>
  );
};

export default ModalSelectTemplate;
