import {
    Card,
    Col,
    Form,
    DatePicker,
    Row,
    Select,
    Input,
    Checkbox,
  } from "antd";
  import { AuthContext } from "../../../../../hooks/useAuth";
  import { useContext } from "react";
  import "../index.scss";
  import arrayStatusProgressApplicant from "../../../../../components/dataArrayUseInProject/arrayStatusProgressApplicant";
  import arrayListSalaryTypes from "../../../../../components/dataArrayUseInProject/arrayListSalaryTypes";
  import ButtonLoadingHOC from "../../../../../HOC/ButtonLoadingHOC";
  import { FilterOutlined } from "@ant-design/icons";
  import moment = require("moment");
  
  const { RangePicker } = DatePicker;
  // eslint-disable-next-line max-lines-per-function
  const SearchTableListApplicants = ({ onSubmit, loading, listEmploymentType }: any) => {
    const { user } = useContext(AuthContext);
    const locateObj = [];
    Object.keys(user.locations)
      .sort()
      .map((key) => locateObj.push({ id: key, name: user.locations[key] }));
    const [form] = Form.useForm();
    const onFinish = async (value) => {
      console.log(value);
      const  dataSent ={
        job_name: value.job_name,
        employment_type: value.employment_type,
        interview_schedule: value.interview_schedule ? moment(value.interview_schedule).format('YYYY-MM-DD') : null,
        listed_company_name: value.listed_company_name,
        read_message: value.read_message,
        date_from: value.time?.length>0 ? moment(value.time[0]).format('YYYY-MM-DD'): null,
        date_to:value.time?.length>0 ? moment(value.time[1]).format('YYYY-MM-DD'): null,
        name: value.name,
        salary_type: value.salary_type,
        prefcode: value.prefcode,
        status_applicant: value.status_applicant
      }
      if (onSubmit) onSubmit(dataSent);
    };
  
  
    return (
      <div>
        <Card style={{ marginBottom: 8 }} className="fix_label_search">
          <Form
            form={form}
            labelCol={{ span: 6 }}
            name="validate_other"
            initialValues={{}}
            onFinish={onFinish}
            className="fix_background_select"
          >
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  width: 130,
                  paddingTop: 8,
                }}
              >
                {"応募者情報"}
              </div>
              <div style={{ flex: 1 }}>
                {" "}
                <Row style={{ display: "flex" }} gutter={20}>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="time">
                      <RangePicker
                        size={"large"}
                        placeholder={["開始日", "終了日"]}
                        allowClear={() => form.setFieldsValue({ time: null })}
                        format="YYYY/M/D"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="name">
                      <Input
                        placeholder={"応募者名"}
                        size={"large"}
                        allowClear={() => form.setFieldsValue({ name: null })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="status_applicant">
                      <Select
                        size={"large"}
                        allowClear={() =>
                          form.setFieldsValue({ job_category: null })
                        }
                      
                        placeholder={"全てのステータス"}
                      >
                        {arrayStatusProgressApplicant.map((el) => {
                          return (
                            <Select.Option key={el.id} value={el.id}>
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="interview_schedule">
                      <DatePicker
                        size={"large"}
                        style={{  width: "100%" }}
                        placeholder={"次回面接予定日"}
                        allowClear={() =>
                          form.setFieldsValue({ interview_schedule: null })
                        }
                        format="YYYY/M/D"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="prefcode">
                      <Select
                        size={"large"}
                        allowClear={() =>
                          form.setFieldsValue({ job_category: null })
                        }
                        placeholder={"都道府県"}
                      >
                        {locateObj.map((el) => {
                          return (
                            <Select.Option key={el.id} value={el.id}>
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="approach">
                      <Select
                        size={"large"}
                       
                        allowClear={() => form.setFieldsValue({ approach: null })}
                        placeholder={"応募経路"}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="read_message" valuePropName="checked">
                      <Checkbox>メッセージ未読のみ</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingTop: 5,
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  width: 130,
                  paddingTop: 8,
                }}
              >
                {"求人情報"}
              </div>
              <div style={{ flex: 1 }}>
                {" "}
                <Row style={{ display: "flex" }} gutter={20}>
                <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="employment_type">
                      <Select
                        size={"large"}
                        
                        allowClear={() =>
                          form.setFieldsValue({ job_category: null })
                        }
                        placeholder={"雇用形態"}
                      >
                        {listEmploymentType?.map((el) => {
                          return (
                            <Select.Option key={el.id} value={el.id}>
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="job_name">
                      <Input
                        placeholder={"職種名"}
                        size={"large"}
                        allowClear={() => form.setFieldsValue({ name: null })}
                      />
                    </Form.Item>
                  </Col><Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="listed_company_name">
                      <Input
                        placeholder={"掲載企業名"}
                        size={"large"}
                        allowClear={() => form.setFieldsValue({ name: null })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={{ span: 8 }} xxl={{ span: 8 }}>
                    <Form.Item name="salary_type">
                      <Select
                        size={"large"}
                        
                        allowClear={() =>
                          form.setFieldsValue({ job_category: null })
                        }
                        placeholder={"給与形態"}
                      >
                        {arrayListSalaryTypes?.map((el) => {
                          return (
                            <Select.Option key={el.id} value={el.id}>
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonLoadingHOC
                    name={"検索"}
                    icon={<FilterOutlined />}
                    handelButton={form.submit}
                    loadingButton={loading}
                  />
                </div>
              </Col>
                </Row>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    );
  };
  export default SearchTableListApplicants;
  
