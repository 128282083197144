
import { Button, Form } from 'antd';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getForgotPassword } from '../../api/service/forgotPassword';
import FormLoginInPutEmail from './FormLoginInPutEmail';
import { useNavigate } from 'react-router-dom';

const style = {
  styleCenter: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
};
function ForgotPassword(props) {
  const ref = useRef();
  const history = useNavigate();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  const handleSubmit = async (values) => {
    setSubmitting(true);
    const  response  = await getForgotPassword({ ...values });
    if (response.status == 201 || response.status == 200) {
      history('/forgot/pass');
    } else {
      setAlertMsg('メールアドレスは存在しません。ご確認してください。');
    }
    setSubmitting(false);
  };

  return (
    <>
      <div  style={{textAlign:'center',width:'25%',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)'}} >
        <div>
          <div >
            <div  style={{cursor:'pointer',color:'#0ABAB5',fontWeight:'800',fontSize:'25px',letterSpacing:'5x',marginBottom:'30px'}} >{'受付求人.com'}</div>
            <h4
              style={{
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '22px',
                marginBottom: '10px',
              }}

            >
              {'パスワードの再設定'}
            </h4>
            <div style={{ fontSize: 14, lineHeight: '22px', color: '#666666' }}>
              <div>{'ご利用中のメールアドレスを入力してください。'}</div>
              <div>{'パスワード再設定のためのURLをお送りします。'}</div>
            </div>
          </div>
          <Form
            name="basic"
            initialValues={{}}
            onFinish={handleSubmit}
            onChange={() => {
              setSubmitting(false);
              setAlertMsg('');
            }}
            form={form}
            ref={ref}
            wrapperCol={{span:24}}
          >
            {alertMsg && (
              <>
                <p >{alertMsg}</p>
              </>
            )}

            <FormLoginInPutEmail disabled={false}/>
            <div>
              <Button
                block
                htmlType="submit"
                type="primary"
                loading={submitting}
                size="large"
                style={{backgroundColor:'#0ABAB5'}}
              >
                {'メール送信'}
              </Button>
            </div>
          </Form>

          <Link  to={'/login'} style={{color:'#0ABAB5', float:'left', marginTop:'20px'}}>
            {'ログイン画面に戻る'}
          </Link>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword
