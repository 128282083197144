import { Fragment, useEffect, useState } from "react";
import { defineColumnTableAdminList } from "./defineColumnTableAdminList";
import { Table, Select, Space, Button } from "antd";

import { getJobListData } from "../../../../api/service/job";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { getCompanyList } from "../../../../api/session/admin/company";
import { paginationCustom } from "../../../../components/paginationCustom";
import SearchTableCompanyList from './SearchTableCompanyList';

interface TableParams {
  field?: any;
  order?: any;
  columnKey?: any;
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}
const TableAdminList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkOperation, setBulkOperation] = useState(null);

  const [total, setTotal] = useState<number>(0);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const fetchDataForTable = async () => {
    setLoading(true);
    const dataSent: any = {
      page: tableParams.pagination.current,
    };
    const response = await getCompanyList(dataSent);
    if (response.status === 200) {
      setLoading(false);

      setListData(response.data.data);
      setTotal(response.data.meta.total);
    }
  };

  const columns = defineColumnTableAdminList(fetchDataForTable);

  useEffect(() => {
    fetchDataForTable();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<any>
  ) => {
    console.log("hihihi");

    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <div className="fix_background_select" style={{ background: "#F7F9F8", padding:'20px' }}>
      
      <SearchTableCompanyList />
      <div>
            <Space style={{ paddingBottom: 10 }}>
              <div>{"一括操作"}</div>
              <Select
                allowClear
                placeholder={"操作を選択"}
                value={bulkOperation}
                onChange={(e) => {
                  setBulkOperation(e);
                }}
              >
                <Select.Option value={"deleteAll"}>{"一括削除"}</Select.Option>
              </Select>
              <Button >
                {"実行"}
              </Button>
            </Space>
          </div>
      <Table
        columns={columns}
        rowKey={"id"}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys,
        }}
        loading={loading}
        dataSource={listData}
        pagination={{
          ...tableParams.pagination,
          total: total,
          itemRender: paginationCustom,
          showSizeChanger: false,
          showTotal: (total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          ),
        }}
        onChange={handleTableChange} 
       
      />
    </div>
  );
};

export default TableAdminList;
