import request from "../utils/request";

export async function getInfo() {
  return request(`/api/user/info`, {
    method: "GET",
  });
}

export async function getDataSummary(data) {
  return request(`/api/top/data-summary/`, {
    method: "POST",
    data: data,
  });
}

export async function getListProcess(company_id) {
  return request(`/api/process/list/${company_id}`, {
    method: "GET",
  });
}

export async function updateListProcess(list) {
  return request(`/api/process/create/`, {
    method: "POST",
    data: list,
  });
}


export async function getListCompany(name) {
  return request(`/api/user/list-by-company?name=${name}`, {
    method: "GET",
  });
}


export async function createAccount(data) {
  return request(`/api/user/create`, {
    method: "POST",
    data: data,
  });
}


export async function updateAccount(id,data) {
  return request(`/api/user/update/${id}`, {
    method: "POST",
    data: data,
  });
}



export async function getDetailAccount(id) {
  return request(`/api/user/detail/${id}`, {
    method: "GET",

  });
}


export async function deleteMultipleAccount(data) {
  return request(`/api/user/delete`, {
      method:"DELETE",
      data: data
  })
}