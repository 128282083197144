

const PlusIcon = () => {
    return (
        <div>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5H10" stroke="#00C4CC" strokeWidth="3" />
                <path d="M5 0L5 10" stroke="#00C4CC" strokeWidth="3" />
            </svg>

        </div>
    )
}

export default PlusIcon