import { Modal, Space } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


import { deleteApplicant } from "../../../../api/service/applicant";
import ButtonLoadingHOC from "../../../../HOC/ButtonLoadingHOC";
import { notiHOC } from "../../../../HOC/NotiHOC";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ModalDeleteApplicant: React.FC<any> = ({applicant, id }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const redirect = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const handleDelete = async () => {
    setLoading(true)
    const response = await deleteApplicant(id);
    if (response.status == 200) {
      console.log("phản hồi", response);
      notiHOC("削除に成功しました。", true);
      redirect("/applicants");
    } else {
        notiHOC("更新に失敗しました。", false);
    }
  };
  return (
    <>
      <div className="btn-border-blue-blod-big m-t-40" onClick={showModal}>
        削除
      </div>
      <Modal
        centered
        title={<h1>{"削除確認"}</h1>}
        open={isModalOpen}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="delete-manager-list">
        <ul className="delete-manager-list">
            <li>
              {applicant?.first_name_kanji} {applicant?.last_name_kanji}
              を削除します。よろしいですか？
            </li>
          </ul>

          <div style={{ padding: "20px 0px", textAlign: "end" }}>
            <Space size={15}>
              <span
                onClick={handleCancel}
                className="btn-exit-modal btn-hover-yl"
              >
                キャンセル
              </span>

              <ButtonLoadingHOC
                name={"削除"}
                handelButton={handleDelete}
                loadingButton={loading}
              />
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalDeleteApplicant;
