import { Form, Image, Input, Space } from "antd";

import {  useState } from "react";

import 'react-quill/dist/quill.snow.css';
const ReactQuill = require('react-quill');
import '../Posting.scss';

import { useNavigate } from "react-router-dom";
import Router from "../../../config/route";
import { createPost } from "../../../api/service/posting";
import { notiHOC } from "../../../HOC/NotiHOC";
import FormEditor from "../FormEditor";
import FormImage from "../FormImage";
import ButtonLoadingHOC from "../../../HOC/ButtonLoadingHOC";

const FormCreateNewPosting = ({
}) => {
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };


  const history = useNavigate();
  const [form] = Form.useForm();
  const createPosting =async (e) => {
 
   
console.log('hahahah',e);

      const dataCreate={
        "title": e.title,
        "content":  e.content,
        "image": e.main_image
    }    
  const response = await createPost(dataCreate);
  
  if ((response.data.success == true)){
    notiHOC("保存しました", true);
    history(Router.posting)
    
  } else {
    notiHOC("失敗した投稿の削除", false);
  }
  



  };
  return (
   <>

<Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18}}
      onFinish={createPosting}
      onFinishFailed={onFinishFailed}
     colon={false}
     labelAlign="left"
     form={form} 
    requiredMark={false}
    >
  
  <Form.Item
  
  label={<Space className="field-name">
  タイトル<span className="tag-red">必須</span>
</Space>}

      name="title"
  rules={[{ required: true, message: 'テンプレートタイトルフィールドは必須です' }]}
  >


    
  <Input size={'large'}  placeholder={""}   />
</Form.Item>

<FormImage form={form} dataPost={null} />
<FormEditor/>

     
       <div className="btn-modal-wrapper" style={{display:'flex',justifyContent:'center'}}>
      <span
        onClick={()=>{}}
        className="btn-exit-modal btn-hover-yl"
      >
        プレビュー
      </span>

      <ButtonLoadingHOC name={'保存する'}  handelButton={form.submit} loadingButton={false}/>
    </div>
      </Form>
   </>
                   
  
  )}
  
export default FormCreateNewPosting;
