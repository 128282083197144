import { Form, Modal, Select, Space } from "antd";
import { useState } from "react";
import { updateMultiPost } from "../../../../../api/service/posting";
import arrayStatusPosting from "../../../../../components/dataArrayUseInProject/arrayStatusPosting";
import ButtonLoadingHOC from "../../../../../HOC/ButtonLoadingHOC";
import { notiHOC } from "../../../../../HOC/NotiHOC";


const ModalUpdateStatusPosting: React.FC<any> = ({
  setSelectedRowKeys,
  selectedRowKeys,
  fetchDataForTable,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const onFinish = async (e) => {
    console.log(e);
    setLoading(true);
 
    const data={
     "list_id_post": selectedRowKeys,
     "status": e.statusSelect
 }
 console.log("data",data);
 
    const response = await updateMultiPost(data);
    if (response.data.success == true) {
      setLoading(false);
      setSelectedRowKeys([]);
      notiHOC("正常に更新されました", true);
      fetchDataForTable();
    } else {
      setLoading(false);
      notiHOC("更新に失敗しました", false);
    }
  };
  return (
    <>
      <a onClick={showModal} className="btn-blue-medium mr-8 btn-hover-yl">
        ステータス更新
      </a>
      <Modal
        centered
        title="一括ステータス更新"
        open={isModalOpen}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="delete-manager-list">
          <p className="m-t-8">更新するステータスを選択</p>
          <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
            <Form.Item
              name="statusSelect"
              rules={[{ required: true, message: "選んでください。" }]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder={"選んでください"}
              >
                          {arrayStatusPosting.map((el) => {
              return (
                <Select.Option key={el.id} value={el.id}>
                  {el.name}
                </Select.Option>
              );
            })}
              </Select>
            </Form.Item>
          </Form>
          <div style={{ padding: "20px 0px", textAlign: "end" }}>
            <Space size={15}>
              <span
                onClick={handleCancel}
                className="btn-exit-modal btn-hover-yl"
              >
                キャンセル
              </span>

              <ButtonLoadingHOC
                name={"保存"}
                handelButton={form.submit}
                loadingButton={loading}
              />
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalUpdateStatusPosting;
