import { Button } from 'antd';

import  '../../css/buttonLoading.scss';

export default function ButtonLoadingHOC({ name, handelButton=()=>{},loadingButton, icon=null}:any) {

     
     const enterLoading = () => {
     handelButton();     
}
  return (
    <div className='style_button'>
       <Button  loading={loadingButton} onClick={() => enterLoading()} icon={icon}>
{name}   
</Button>
  </div>
  )
}