/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input, Space } from "antd";

const FormInputInSuperAdmin = ({
  name,
  required,
  placeholder,
  labelName,
  message,
  placeEmail,
  width, disabled
}: any) => {
  return (
    <Form.Item
      label={
        <Space size={14}>
          <div className={required ? "required_admin" : "not_required_admin"}>
            {required ? "必須" : "任意"}{" "}
          </div>
          <div style={{ fontWeight: 500 }}>{labelName}</div>
        </Space>
      }
      labelCol={{span: 7}}
    >
      <Form.Item
        name={name}
        rules={[
          {
            required: required,
            message: message,
          },
          {
            type: placeEmail ? "email" : null,
            message: placeEmail ? "メールアドレスは有効なメールではありません。": '',
          },
          {
            whitespace: required,
            message: `空白を入力できません。`,
          },
        ]}
      >
        <Input placeholder={placeholder} style={{width:width}} disabled={disabled} />
      </Form.Item>
    </Form.Item>
  );
};
export default FormInputInSuperAdmin;
