import {  Modal,  Space } from "antd";
import  { useState } from "react";
import { deleteMultiplePost } from "../../../../../api/service/posting";
import ButtonLoadingHOC from "../../../../../HOC/ButtonLoadingHOC";
import { notiHOC } from "../../../../../HOC/NotiHOC";



// eslint-disable-next-line @typescript-eslint/no-empty-function
const ModalDeletePosting: React.FC<any> = ({setSelectedRowKeys=()=>{},tableJob=false,selectedRowKeys, fetchDataForTable, postTitle,listData}:any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const onDeleteMultiple = async () => {
    // setLoading(true)
    const listIdPost = [];
    listData.map((el) => {
      if (selectedRowKeys.includes(el.id)) {
        listIdPost.push(el.id);
      }
    });
    console.log('listIdPost',listIdPost);
    
    const response = await deleteMultiplePost({listIdPost:listIdPost});
    if ((response.status = 200)) {
      notiHOC("成功した投稿を削除する", true);
      setSelectedRowKeys([]);
      fetchDataForTable();
      
    } else {
      notiHOC("失敗した投稿の削除", false);
    }
  };

  
  return (
    <>
      <a onClick={showModal}   className={tableJob? "link-hover-yl-table":"btn-border-blue-blod-medium btn-hover-yl"}>
      削除
      </a>
      <Modal
        centered
        title={<h1>{"削除確認"}</h1>}
        open={isModalOpen}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="delete-manager-list">
          <p className="m-t-8" style={{maxHeight:500, overflowY:'auto'}}>{'選択された求人タイトルを削除します。よろしければ「削除」をクリックしてください'}
              {/* <br></br>{postTitle} */}
              </p>
         
          <div style={{ padding: "20px 0px", textAlign: "end" }}>
            <Space size={15}>
              <span
                onClick={handleCancel}
                className="btn-exit-modal btn-hover-yl"
              >
                キャンセル
              </span>

              <ButtonLoadingHOC
                name={"削除"}
                handelButton={onDeleteMultiple}
                loadingButton={loading}
              />
            </Space>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalDeletePosting;
