import { useState } from "react";

const StatusNameAndCounter = ({ index, name, changeName }) => {
  const [textCounter, setTextCounter] = useState(null);
  return (
    <>
      <input
        type="text"
        placeholder="選考プロセス名"
        name=""
        maxLength={10}
        onChange={(e) => {
          changeName(e, index);
          setTextCounter(e.target.value.length);
        }}
        defaultValue={name}
      />
      <p className="txt-link-gray">{textCounter ?? name?.length}/10文字</p>
    </>
  );
};

export default StatusNameAndCounter;
