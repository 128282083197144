export const parseIntValue = (obj) => {
  let obj2 = {};
  obj2 = {
    deleteFlg: obj?.deleteFlg === 'true' ? true : null || obj?.deleteFlg === 'false' ? false : null,
    createdAt: obj?.createdAt,
    profile: {
      nickName: obj?.profile?.nickName,
      hometown: obj?.profile?.hometown ? parseInt(obj.profile.hometown) : null,
      japaneseLevel: obj?.profile?.japaneseLevel ? parseInt(obj.profile.japaneseLevel) : null,
      residenceStatus: obj?.profile?.residenceStatus ? parseInt(obj.profile.residenceStatus) : null,
      occupation: obj?.profile?.occupation ? parseInt(obj.profile.occupation) : null,
      annualIncome: obj?.profile?.annualIncome ? parseInt(obj.profile.annualIncome) : null,
      gender: obj?.profile?.gender ? parseInt(obj.profile.gender) : null,
      nationality: obj?.profile?.nationality ? parseInt(obj.profile.nationality) : null,
      livingArea: obj?.profile?.livingArea ? parseInt(obj.profile.livingArea) : null,
      entryDate: obj?.profile?.entryDate,
      periodOfStay: obj?.profile?.periodOfStay,
      birthday: obj?.profile?.age,
    },
    approval: {
      approvalStatus: obj?.approval?.approvalStatus,
    },
    subscriber: {
      transactions: {
        some: {
          provider: obj?.provider,
        },
      },
    },
  };
  return removeEmpty(obj2);
};

export function removeEmpty(obj) {
  let finalObj = {};
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) == true) {
      if (
        obj[key][0] !== '' &&
        obj[key][0] !== undefined &&
        obj[key][0] !== null &&
        obj[key].length >= 1
      ) {
        finalObj[key] = obj[key];
      }
    }
    if (obj[key] && typeof obj[key] === 'object' && Array.isArray(obj[key]) == false) {
      const nestedObj = removeEmpty(obj[key]);
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj;
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key];
    }
  });
  return finalObj;
}

export const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const convertSorter = (obj) => {
  let propNames = Object.getOwnPropertyNames(obj); //prefersEmail,email
  let arrayKeys = propNames[0].split(','); // ['prefersEmail', 'email]
  let arraySort = [];
  if (typeof obj[propNames] === 'string') {
    arraySort = obj[propNames].split('');
    let n = arraySort.length;
    let result = {};
    if (n > 4) {
      const arr2 = arraySort.slice(0, n - 3).join('');
      let index = arrayKeys.length - 1;
      result[arrayKeys[index--]] = arr2; // initialize
      for (; index >= 0; index--) {
        // nesting
        result = {
          [arrayKeys[index]]: result,
        };
      }
      return result;
    }
    const arr2 = arraySort.join('');
    let index = arrayKeys.length - 1;
    result[arrayKeys[index--]] = arr2; // initialize
    for (; index >= 0; index--) {
      // nesting
      result = {
        [arrayKeys[index]]: result,
      };
    }
    return result;
  } else return obj;
};

export function unique(arr) {
  var newArr = [];
  newArr = arr.filter(function (item) {
    return newArr.includes(item) ? '' : newArr.push(item);
  });
  return newArr;
}
export function isURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(str);
}
