import { Suspense, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Top from "./containers/Top";
import {  AuthProvider } from "./hooks/useAuth";
import RequireAuth from "./containers/RequireAuth";
import Router from "./config/route";
import Job from "./containers/Job";
import User from "./containers/User";
import Company from "./containers/Company";
import Message from "./containers/Message";
import Processes from "./containers/Processes";
import Applicants from "./containers/Applicants";
import Helper from "./containers/Help";
import Chat from "./containers/Chat";
import Admin from "./containers/Admin";
import AppPolicy from "./AppPolicy";
import Conversation from "./containers/Chat/Conversation";
import { RoleUser } from "./hooks/checkRole";
import HelperContextProvider from "./containers/Help/HelpComponents/HelperContext";
import InterviewSchedule from "./containers/Applicants/InterviewSchedule";
import "antd/dist/antd.css";
import ModalHOC from "./HOC/ModalHOC";
import "moment/locale/ja"
import locale from "antd/es/locale/ja_JP"
import { ConfigProvider } from "antd"
import "./style.scss";
import EditPosting from "./containers/Posting/FormUpdatePosting";
import NewPosting from "./containers/Posting/FormCreateNewPosting";
import ListPosting from "./containers/Posting/listPosting";
import AccountList from "./containers/Account/listAccount";
import CreateNewAccount from "./containers/Account/CreateNewAccount";
import EditAccount from "./containers/Account/EditAccount";

const App = () => {
  return (
<ConfigProvider locale={locale}>
<ModalHOC />
<AuthProvider>
      <HelperContextProvider>
        <Suspense fallback={""}>
          <Routes>
            <Route path={Router.chat} element={<Chat />} />

            <Route
              path={`${Router.conversation}/:roomData`}
              element={<Conversation />}
            />
            <Route path={Router.login} element={<User.Login />} />
            <Route
              path={Router.admin}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Admin.AdminList />
                  </RoleUser>
                </RequireAuth>
              }
            />

            <Route
              path={Router.company_new}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Admin.AdminNew />
                  </RoleUser>
                </RequireAuth>
              }
            />
            
            <Route path={Router.app_policy} element={<AppPolicy />} />
            <Route
              path={`${Router.admin_edit_company}/:id`}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Admin.AdminEdit />
                  </RoleUser>
                </RequireAuth>
              }
            />
            <Route
              path={Router.forgot_password}
              element={<User.ForgotPassword />}
            />
               <Route
              path={Router.forgot_pass}
              element={<User.PassForgotPassword />}
            />
            <Route
              path={Router.set_new_password}
              element={
                <RequireAuth>
                  <User.SetPassword />
                </RequireAuth>
              }
            />
            <Route
              path={"help"}
              element={
                <RequireAuth>
                  <Helper />
                </RequireAuth>
              }
            />
            <Route
              path={Router.new_job}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Job.New />
                  </RoleUser>
                </RequireAuth>
              }
            />

          <Route
              path={"/"}
              element={
                <RequireAuth>
                  <RoleUser>
                  <Job.List />
                  </RoleUser>
                </RequireAuth>
              }
            />

            <Route
              path={Router.jobs}
              element={
                <RequireAuth>
                  <Job.List />
                </RequireAuth>
              }
            />
            <Route
              path={`${Router.jobs_edit}/:id`}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Job.Edit />
                  </RoleUser>
                </RequireAuth>
              }
            />
            
            <Route
              path={Router.jobs_analysis}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Job.Anaysis />
                  </RoleUser>
                </RequireAuth>
              }
            />
            <Route
              path={Router.jobs_csv}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Job.CSV />
                  </RoleUser>
                </RequireAuth>
              }
            />
            <Route
              path={Router.jobs_report}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Job.Report />
                  </RoleUser>
                </RequireAuth>
              }
            />
            <Route
              path={Router.company_detail}
              element={
                <RequireAuth>
                  <Company.Details />
                </RequireAuth>
              }
            />
            <Route
              path={Router.company_edit}
              element={
                <RequireAuth>
                  <Company.Edit />
                </RequireAuth>
              }
            />
            <Route
              path={Router.company_preview}
              element={
                <RequireAuth>
                  <Company.Preview />
                </RequireAuth>
              }
            />
            <Route
              path={Router.message_templates}
              element={
                <RequireAuth>
                  <Message.Templates />
                </RequireAuth>
              }
            />
            <Route
              path={Router.processes}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Processes.Proc />
                  </RoleUser>
                </RequireAuth>
              }
            />
            <Route
              path={Router.applicants}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Applicants.List />
                  </RoleUser>
                </RequireAuth>
              }
            />
            
            <Route
              path={`${Router.applicants_detail}/:id`}
              element={
                <RequireAuth>
                  <RoleUser>
                    <Applicants.Details />
                  </RoleUser>
                </RequireAuth>
              }
            />
            <Route
              path={`${Router.interview_schedule}/:id`}
              element={
                <RequireAuth>
                  <InterviewSchedule />
                </RequireAuth>
              }
            />
            <Route
              path={Router.root}
              element={
                <RequireAuth>
                  <Top />
                </RequireAuth>
              }
            />


             <Route
              path={Router.posting}
              element={
                <RequireAuth>
                  <ListPosting />
                </RequireAuth>
              }
            />

             <Route
              path={Router.new_posting}
              element={
                <RequireAuth>
                  <RoleUser>
                    <NewPosting />
                  </RoleUser>
                </RequireAuth>
              }
            />

<Route
              path={`${Router.posting_edit}/:id`}
              element={
                <RequireAuth>
                  <RoleUser>
                    <EditPosting />
                  </RoleUser>
                </RequireAuth>
              }
            />

<Route
              path={Router.setting}
              element={
                <RequireAuth>
                  <RoleUser>
                    <AccountList />
                  </RoleUser>
                </RequireAuth>
              }
            />
       <Route
              path={Router.new_account}
              element={
                <RequireAuth>
                  <RoleUser>
                    <CreateNewAccount />
                  </RoleUser>
                </RequireAuth>
              }
            />

                 <Route
              path={`${Router.account_detail}/:id`}
              element={
                <RequireAuth>
                  <RoleUser>
                    <EditAccount />
                  </RoleUser>
                </RequireAuth>
              }
            />

          </Routes>
        </Suspense>
      </HelperContextProvider>
    </AuthProvider>
</ConfigProvider>
  );
};

export default App;
