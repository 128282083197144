import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";

import { Spin } from "antd";
import { getAuthority, removeAuthority } from "../api/utils/authority";
import { getInfo } from "../api/service/user";

const RequireAuth = function ({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const navigate  = useNavigate()
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const location = useLocation();
  const getInfoUser = async ()=>{
    const response=  await getInfo()
    if(response.status==200){
      auth.signin(response.data.data, () => {
        console.log('hihi');
      })
      setLoginStatus(true)
    } else{
      if(response.status==401){
        removeAuthority()
      }
    }
    
  }
  useEffect(() => {
    if (!loginStatus) {
      const auth:any = getAuthority();
      if(auth){
        getInfoUser()
      }else{
        navigate('/login')
      }


    }
  }, [loginStatus]);
  if (!loginStatus) {
    return <div style={{width:'100vw', height:'100vh', textAlign:'center', display:'flex', justifyContent:'center', alignItems:'center'}}><Spin spinning={true} size="large" /></div>;
  }
  if (auth.user) return children;
  else return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
