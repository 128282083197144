import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment = require("moment");

const TextSend = ({ text, time }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "right",
        alignItems: "flex-end",
      }}
    >
      <Box
        maxWidth={"70%"}
        component="div"
        className="has-time "
        textAlign={"right"}
      >
        <Box
          px={2.5}
          py={1.5}
          sx={{
            background: "rgba(0, 196, 204, 0.2)",
            borderRadius: "15px 15px  0 15px",
            textAlign: "left",
            wordBreak: "break-word",
            color: "#555",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "21px",
            letterSpacing: "0.0.4em",
            marginBottom: 1.25,
            fontFamily: "Noto Sans",
            display: "inline-block",
            whiteSpace:'pre-line'
          }}
        >
          {text}
        </Box>

        <Typography
          sx={{
            fontFamily: "Noto Sans",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "21px",
            letterSpacing: "0.04em",
            color: "rgba(170, 170, 170, 1)",
            textAlign: "right",
            overflow: "hidden",
          }}
          className="time"
        >
          {moment(time).format("YYYY/MM/DD hh:mm")}
        </Typography>
      </Box>
    </Box>
  );
};
export default TextSend;
