

const SearchIcon = () => {
    return (
        <div>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.3125" cy="7.3125" r="6.3125" stroke="#AAAAAA" strokeWidth="2" />
                <path d="M18 18L12.375 12.375" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </div>
    )
}

export default SearchIcon