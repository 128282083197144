interface Credential {
  accessToken: string;
  clientId: string;
  uid: string;
}

interface AuthHeader {
  ['access-token']?: string;
  client?: string;
  uid?: string;
}

const ApiService = () => {
  let accessToken: string; let clientId: string; let
    uid: string;
  const storage = window.localStorage;

  const persist = () => {
    const credential = {
      accessToken: storage.getItem('access-token'),
      clientId,
      uid,
    };

    storage.setItem('credential', JSON.stringify(credential));
  };
  const authFetch = async (endpoint: string, params: RequestInit = {}) => {
    const defaultHeaders: HeadersInit & AuthHeader = params.headers || {};
    
    if (storage.getItem('access-token')) defaultHeaders['authorization'] = `Bearer ${storage.getItem('access-token')}`;
    if (clientId) defaultHeaders.client = clientId;
    if (uid) defaultHeaders.uid = uid;
    
    const requestHeaders = new Headers(defaultHeaders);
    const response = await fetch(endpoint, {
      headers: requestHeaders,
      ...params,
    });
    const responseHeaders = response.headers;
    if (responseHeaders.get('access-token')) accessToken = responseHeaders.get('access-token');
    if (responseHeaders.get('client')) clientId = responseHeaders.get('client');
    if (responseHeaders.get('uid')) uid = responseHeaders.get('uid');
    return response;
    // persist();
  };

  const credentialFromUrl = (searchParams: URLSearchParams) => {
    const paramKeys = {
      'access-token': 'accessToken',
      client_id: 'clientId',
      token: 'accessToken',
      uid: 'uid',
    };
    const credential: Credential = { accessToken: null, clientId: null, uid: null };

    for (const paramKey of Object.keys(paramKeys)) {
      if (searchParams.has(paramKey)) credential[paramKeys[paramKey]] = searchParams.get(paramKey);
      else return null;
    }
    return credential;
  };

  const reset = () => {
    accessToken = null;
    clientId = null;
    uid = null;
    storage.removeItem('credential');
  };



  const restore = () => {
    let credential: Credential = credentialFromUrl(new URL(document.location.href).searchParams);
    if (!credential) credential = JSON.parse(storage.getItem('credential'));
    if (credential) {
      accessToken = credential.accessToken;
      clientId = credential.clientId;
      uid = credential.uid;
    }
  };

  restore();
  return {
    fetch: authFetch,
    reset,
  };
};

export default ApiService;
