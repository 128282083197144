/* eslint-disable @typescript-eslint/no-var-requires */
import { Form, Image, Input, Space } from "antd";


import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
const ReactQuill = require('react-quill');
import '../Posting.scss';
import { useNavigate } from "react-router-dom";
import { getDetailPost, updatePost } from "../../../api/service/posting";
import { notiHOC } from "../../../HOC/NotiHOC";
import FormImage from "../FormImage";
import FormEditor from "../FormEditor";
import ButtonLoadingHOC from "../../../HOC/ButtonLoadingHOC";
import Router from "../../../config/route";


const FormEditPosting = ({

}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useNavigate();
  const [dataPost, setDataPost]= useState(null)
  const fetchEditData = async () => {
    const response = await getDetailPost(id);

    if (response.data.success == true) {
     

      setDataPost(response.data.data)
      
 
    } else {
      console.log("No data");
    }
  };

  useEffect(() => {
    fetchEditData()
  }, [])
  const updatePosting = async (e) => {

      const dataUpdate = {
        "title": e.title,
        "content": e.content,
        "image":e.main_image
      }
      console.log('dataUpdate',dataUpdate);
      
      const response = await updatePost(id,dataUpdate);
      if ((response.data.success == true)) {
     console.log('fsd',response.data);
     

        notiHOC("保存しました", true);
        history(Router.posting)
      } else {
        notiHOC("投稿の更新に失敗しています", false);
      }

  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };
  return (
    <>

   {dataPost &&  <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={updatePosting}
        onFinishFailed={onFinishFailed}
        colon={false}
        labelAlign="left"
        form={form}
        layout="horizontal"
        requiredMark={false}
initialValues={{
  title:dataPost.title,
  content:dataPost.content,
  main_image:dataPost.main_image
}}
>
        <Form.Item

label={<Space className="field-name">
タイトル<span className="tag-red">必須</span>
</Space>}
  name="title"
rules={[{ required: true, message: 'テンプレートタイトルフィールドは必須です' }]}
        >
          <Input size={'large'} placeholder={""} />
        </Form.Item>


       <FormImage form={form} dataPost={dataPost} />


        <FormEditor/>


        <div className="btn-modal-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
          <span
            onClick={() => {console.log('dsa');
             }}
            className="btn-exit-modal btn-hover-yl"
          >
            プレビュー
          </span>

          <ButtonLoadingHOC name={'保存する'} handelButton={form.submit} loadingButton={false} />
        </div>

      </Form>}
    </>


  )
}
export default FormEditPosting;