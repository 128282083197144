const Router = {
  login: "/login",
  admin: "/admin",
  admin_edit_company: "/admin/edit",
  forgot_password: "/forgot_password",
  forgot_pass: "/forgot/pass",

  set_new_password: "/set_new_password",
  new_job: "/jobs/new",
  jobs: "/jobs",
  jobs_edit: "/jobs/edit",
  jobs_preview: "/jobs/preview",
  jobs_analysis: "/jobs/anaysis",
  jobs_csv: "/jobs/csv",
  jobs_report: "/jobs/report",
  company_new: "/admin/new",
  company_detail: "/company/details",
  company_edit: "/company/edit",
  company_preview: "/company/preview",
  message_templates: "/message_templates",
  processes: "/processes",
  applicants: "/applicants",
  applicants_result: "/applicants/result",
  applicants_detail: "/applicants/details",
  help: "/help",
  app_policy: "/app_policy",
  root: "/",
  chat: "/chat",
  conversation: "/conversation",
  interview_schedule: "applicantion/interview_schedule",
  posting:"/posting",
  new_posting: "/posting/new",
  posting_edit: "/posting/edit",
  setting: "/setting",
 new_account: "/account/new",
 account_detail: "/account/details",
};

export default Router;
