import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Space, Form, Spin } from "antd";
import "./index.scss";
import Input from "react-phone-number-input/input";

import { notiHOC } from "../../../../HOC/NotiHOC";

import { updateCompanyDetail } from "../../../../api/session/admin/company";
import ButtonLoadingHOC from "../../../../HOC/ButtonLoadingHOC";
import { getCompanyDetail } from "../../../../api/session/company/detail";
import FormInputInSuperAdmin from "../../../../components/FormUseInSuperAdmin/FormInputInSuperAdmin";
import "react-phone-number-input/style.css";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
  
} from "react-phone-number-input";
import { handleDataSentUpdateCompany } from "./handleDataSentUpdateCompany";

const EditCompanyForm = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyDetail, setCompanyDetail] = useState(null);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [inputPhone, setInputPhone] = useState(null);

  const fetchCompanyDetail = async () => {
    const response = await getCompanyDetail(id);
    if (response.status === 200) {
      setCompanyDetail(response.data.data);
    } else {
      alert("no data");
    }
  };

  useEffect(() => {
    if (id) {
      fetchCompanyDetail();
    }
  }, [id]);

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      behavior: "smooth",
    });
  };
  const onFinish = async (values) => {
    console.log("valuesvalues", values);
    const dateSent= handleDataSentUpdateCompany(values, id)
   
    setIsLoading(true);
    const response = await updateCompanyDetail(dateSent);

    if (response.status === 200) {
      setIsLoading(false);

      notiHOC("求人情報を保存しました。", true);
    } else {
      setIsLoading(false);

      notiHOC("更新に失敗しました。", false);
    }
  };
  const checkInputPhoneNumber = (_, value) => {
    if (value) {
      
      
      if (isValidPhoneNumber(value)) {
        const array = formatPhoneNumber(value).split("");
        if (array[1] == "0") {
          return Promise.reject(
            new Error("有効な電話番号を入力してください。1 ")
          );
        }
        if (array[0] !== inputPhone?.split("")[0]) {
          return Promise.reject(
            new Error("有効な電話番号を入力してください。2 ")
          );
        }
        if (array[1] !== inputPhone?.split("")[1]) {
          return Promise.reject(
            new Error("有効な電話番号を入力してください。3")
          );
        }
        return Promise.resolve();
      }

      return Promise.reject(new Error("有効な電話番号を入力してください。"));
    }

    return Promise.reject(new Error(""));
  };

  useEffect(()=>{
    if(companyDetail){
      setInputPhone(formatPhoneNumber(companyDetail?.phone))
    }
  },[companyDetail])
  return (
    <div style={{ width: "100%", paddingTop: 30 }} className={"formItem"}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          minHeight: !companyDetail ? 300 : 0,
        }}
      >
        <Spin spinning={!companyDetail} />
      </div>
      {companyDetail && (
        <div>
          {" "}
          <Form
            colon={false}
            requiredMark={false}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            form={form}
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
            onChange={(e) => {
              if (e.target.id == "phone") {
                if (e.target.value) {
                  setInputPhone(e.target.value);
                }
              }
            }}
            initialValues={{
              name: companyDetail?.name,
              address: companyDetail?.address,
              representative_name: companyDetail?.representative_name,
              email: companyDetail?.email_company,
              number_of_employee: companyDetail?.num_employee?.toString(),
              capital: companyDetail?.capital_stock?.toString(),
              date_of_establishment: companyDetail?.founded_at,
              access: companyDetail?.access,
              name_of_person_in_charge: companyDetail?.name_personnel,
              phone_of_person_in_charge: companyDetail?.phone_personnel,
              email_of_person_in_charge: companyDetail?.email_personnel,
              phone: companyDetail?.phone?.toString()
            }}
          >
            <FormInputInSuperAdmin
              name="name"
              required={true}
              labelName="企業名"
              placeholder="株式会社○○○○"
              message="入力してください。"
            />

            <FormInputInSuperAdmin
              name="address"
              required={true}
              labelName="企業所在地"
              placeholder="5400000"
              message="入力してください。"
            />

            <FormInputInSuperAdmin
              name="representative_name"
              required={true}
              placeholder="代表者名を入力"
              labelName="代表者名"
              message="入力してください。"
              width={325}
            />

            <FormInputInSuperAdmin
              name="email"
              required={true}
              placeholder="メールアドレスを入力"
              labelName="メールアドレス"
              message="入力してください。"
              placeEmail={true}
              width={325}
              disabled
            />

            <Form.Item
              name={"phone"}
              label={
                <Space size={14}>
                  <div className={"not_required_admin"}>{"任意"} </div>

                  <div style={{ fontWeight: 500 }}>{"直通電話番号"}</div>
                </Space>
              }
              rules={[
               
                {
                  validator: checkInputPhoneNumber,
                },
              ]}
            >
              <Input
                country="JP"
                placeholder={"03-1234-5678"}
                className={"inputNumberPhone"}
              />
            </Form.Item>

            <FormInputInSuperAdmin
              name="number_of_employee"
              required={false}
              placeholder="1,000"
              labelName="従業員数"
            />
            <FormInputInSuperAdmin
              name="capital"
              required={false}
              placeholder="5,000,000"
              labelName="資本金（円）"
            />
            <FormInputInSuperAdmin
              name="date_of_establishment"
              required={false}
              placeholder="2022年10月22日"
              labelName="設立日"
            />
            <FormInputInSuperAdmin
              name="access"
              required={false}
              placeholder="恵比寿駅　徒歩５分"
              labelName="アクセス"
            />
            <Form.Item
              label={
                <Space size={14}>
                  <div className={"required_admin"}>{"必須"}</div>
                  <div style={{ fontWeight: 500 }}>{"企業所在地"}</div>
                </Space>
              }
            >
              <FormInputInSuperAdmin
                name="name_of_person_in_charge"
                required={true}
                placeholder="人事担当者名を入力"
                labelName="担当者名"
                message="入力してください。"
                width={325}
              />
              <FormInputInSuperAdmin
                name="phone_of_person_in_charge"
                required={true}
                placeholder="電話番号を入力"
                message="入力してください。"
                labelName="直通電話番号"
                width={325}
              />
              <FormInputInSuperAdmin
                name="email_of_person_in_charge"
                required={true}
                placeholder="メールアドレスを入力"
                labelName="メールアドレス"
                message="入力してください。"
                width={325}
              />
            </Form.Item>
          </Form>
          <div className="form-btn-area sticky">
            <ButtonLoadingHOC
              name={"保存する"}
              handelButton={form.submit}
              loadingButton={isLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditCompanyForm;
