import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment = require("moment");

const TextReceive = ({ text, time }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "right",
        alignItems: "flex-start",
      }}
    >
      <Box maxWidth={"70%"} component="div" className="has-time ">
        <Box
          px={2.5}
          py={1.5}
          sx={{
            background: "#F5F5F5",
            borderRadius: "15px 15px 15px 0",
            textAlign: "left",
            wordBreak: "break-word",
            color: "#555",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "21px",
            letterSpacing: "0.0.4em",
            marginBottom: 1.25,
            fontFamily: "Noto Sans",
            display: "inline-block",
            whiteSpace:'pre-line'
          }}
        >
          {text}
        </Box>
        <Typography
          sx={{
            fontFamily: "Noto Sans",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "21px",
            letterSpacing: "0.04em",
            color: "rgba(170, 170, 170, 1)",
            overflow: "hidden",
          }}
          className="time"
        >
          {moment(time).format("YYYY/MM/DD hh:mm")}
        </Typography>
      </Box>
    </Box>
  );
};
export default TextReceive;
