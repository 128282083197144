import { Button, Modal, Spin, Upload } from "antd";
import { useContext, useEffect, useState } from "react";
import { listImageAvailable } from "../../../api/session/listImageAvailable";
import { notiHOC } from "../../../HOC/NotiHOC";
import ImgCrop from "antd-img-crop";
import {
  DeleteImageById,
  UpNewImage,
} from "../../../api/session/imageApi";

const ModalImageStorage = ({ imgAspectRatio, hanldeSelectImage,typeButton }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [data, setData] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [isLoadImage, setIsLoadImage] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [pass, setPass] = useState(false);
const company_id=localStorage.getItem("companyId")

  
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const listImage = async () => {
    setIsLoadImage(true);
    const dataListImg={company_id:company_id}
    const res = await listImageAvailable(dataListImg);

    if (res.status === 200) {
      setIsLoadImage(false);
      setData(res.data.data);
    } else {
      setIsLoadImage(false);
      notiHOC("接続に失敗しました。", false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      listImage();
    }
  }, [isModalOpen]);

  const handleDeleteImg = async (itemId, aspect_ratio) => {
    setIsLoadImage(true);
    const data = {company_id:company_id, aspect_ratio: aspect_ratio };
    const response = await DeleteImageById(itemId, data);
    console.log('gfgfg',response);
    
    if (response.data.success == true) {
      setIsLoadImage(false);
      notiHOC("更新に成功。。", true);
   
      setData(response.data.data);
    } else {
      setIsLoadImage(false);
      notiHOC("更新に失敗しました。", false);
    }
    listImage()
  };

  //handle before Upload
  const beforeUpload = (file) => {
    console.log("beforeUpload", file);
    const isJPG = file.type === "image/jpeg";
    const isJPEG = file.type === "image/jpeg";
    const isGIF = file.type === "image/gif";
    const isPNG = file.type === "image/png";
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      notiHOC(
        "間違ったファイル形式。JPG、JPEG、GIF、PNGファイルをアップロードしてください。",
        false
      );
      setPass(false);
      return false;
    }
    setPass(true);
    return true;
  };

  const handleChange = async (info) => {
    let fileList = [...info.fileList];

    if (fileList.length == 0) {
      setFileList([...fileList]);
    }
    if (pass) {
      fileList = fileList.slice(-1);
      setFileList(fileList);
    }
  };

  const uploadImageF = async (options) => {
    console.log("options", options);
    const { onSuccess, onError, file, onProgress, category } = options;

    const fmData = new FormData();

    fmData.append("file", file);
    fmData.append("aspect_ratio", imgAspectRatio);
    fmData.append("company_id", company_id.toString() );
      const response = await UpNewImage(fmData);
      if(response.status==200){
        setData(response.data.data);
        setFileList([]);
        onSuccess("ok", response);
      }else {
        setIsLoadImage(false);
        notiHOC("更新に失敗しました。", false);
        onError(response);
      }
     
        listImage()
  };

  return (
    <>
    {typeButton? (       <Button
        style={{
          color: "#00C4CC",
          border: "1px solid #00C4CC",
          background: "rgb(0, 196, 204, 0.1)",
        }}
        onClick={showModal}
      >
        {"ファイルを選択"}
      </Button> ) :(     <Button
       type="dashed"
        style={{
          color: "#000000d9",
          padding:'7% 4%',
          width:'100%',
          height:'100%'
        
        
        }}
        onClick={showModal}
      >
        {"+ Upload"}
      </Button> )}

     

  


     
      <Modal
        title={false}
        footer={null}
        centered
        width={"90vh"}
        open={isModalOpen}
        closable={false}
        onCancel={handleCancel}
      >
        <div className="dialog_antd" style={{ minHeight: 500 }}>
          <div className="dialog-title">
            <div className="dialog-title-action">
              <span className="dialog-title-text">画像管理</span>

              <ImgCrop
                modalTitle={"画像を編集する"}
                grid
                rotate
                aspect={imgAspectRatio}
                modalWidth={1200}
              >
                <Upload
                  customRequest={uploadImageF}
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  maxCount={1}
                >
                  {" "}
                  <Button className="dialog-title-btn">{"画像を選択"}</Button>
                </Upload>
              </ImgCrop>
              <span className="dialog-title-select">
                ※画像管理画面で画像の削除が可能です
              </span>
            </div>
            <div className="dialog-title-delete">
              <img
                style={{
                  width: 25,
                  height: 25,
                  margin: "0 5px",
                  cursor: "pointer",
                }}
                src={require("../../../../img/common/icon-close.png")}
                onClick={handleCancel}
              />
            </div>
          </div>
          <div className="dialog-content">
            <Spin spinning={isLoadImage}>
              <div className="row">
                {data?.map((item) => {
                  if (item.aspect_ratio == imgAspectRatio.toFixed(2)) {
                    return (
                      <div className="col">
                        <div
                          className="img-up-item"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            className="delete-img-btn"
                            src={require("../../../../img/common/close.png")}
                            onClick={() => {
                              handleDeleteImg(item.id, item.aspect_ratio);
                            }}
                          />
                          <img
                            onClick={() => {
                              hanldeSelectImage(item.url,item.id);
                              handleCancel();
                            }}
                            style={{ width: "100%", borderRadius: "5px" }}
                            src={item.url}
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </Spin>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalImageStorage;
