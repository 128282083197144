import "./top.css";
import React = require("react");
import { DatePicker, Row, Col } from 'antd';
import { ConfigProvider } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import ButtonLoadingHOC from "../../HOC/ButtonLoadingHOC";

import locale from 'antd/es/locale/ja_JP';
import { getDataSummary } from "../../api/service/user";
const DataSummary = ({ dataSummary, setDataSummary }) => {
  const now = new Date();
  const [dateFrom, setDateFrom] = React.useState<any>(
    new Date(now.getFullYear(), now.getMonth(), 1)
  );
  const [dateTo, setDateTo] = React.useState<any>(
    new Date(now.getFullYear(), now.getMonth() + 1, 0)
  );
  const [loadingButton, setLoadingButton] = React.useState(false);



  const submitForm = async (e) => {
    setLoadingButton(true)


    if (dateFrom > dateTo) {
    } else {
      const data = {
        date_from: dateFrom,
        date_to: dateTo,
      };
      const response = await getDataSummary(data)
        .then(response => {
          if (response.status === 200) {
            setDataSummary(response.data);

          }
          setLoadingButton(false)

        }


        );

    }
  };

  const { RangePicker } = DatePicker;

  return (
    <>


      <div className="top-data container has-shadow">
        <h2 className="section-ttl">サマリー</h2>
        <form className="form-search" onSubmit={submitForm}>
          <Row gutter={16} style={{ alignItems: "center" }}>
            <Col  >



              <ConfigProvider locale={locale}>

                <RangePicker  format="YYYY/M/D" style={{ width: '310px', padding: '7px 15px' }} />
              </ConfigProvider>
            </Col>
            <Col >

              <ButtonLoadingHOC name={'表示'} handelButton={
                submitForm

              } loadingButton={loadingButton} />
            </Col>
          </Row>
        </form>
        <hr />


        <Row >
          <Col span={4} style={{ textAlign: 'center', border: '1px solid #ccc', padding: '20px 10px', borderRadius: '8px', marginRight: '50px' }}>
            <div className="status-name" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#FFF8E5', width: '100%', borderRadius: '4px', padding: '5px 10px' }}>
              <p style={{ alignItems: 'center', marginBottom: '0px', fontSize: '20px' }}>
              応募
              </p>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CaretUpOutlined style={{ fontSize: '10px' }} />
                <CaretDownOutlined style={{ fontSize: '10px' }} />
              </div>
            </div>
            <div className="status-count" style={{ color: 'black', fontWeight: '700', fontSize: '30px' }}>
              {(dataSummary && dataSummary?.listStatus["二次面接合格"]) ?? 0}
              <span style={{ color: 'black', fontSize: '15px' }}>人</span>
            </div>
          </Col>




          <Col span={4} style={{ textAlign: 'center', border: '1px solid #ccc', padding: '20px 10px', borderRadius: '8px', marginRight: '50px' }}>
            <div className="status-name" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#FFF8E5', width: '100%', borderRadius: '4px', padding: '5px 10px' }}>
              <p style={{ alignItems: 'center', marginBottom: '0px', fontSize: '20px' }}>
              面接中              </p>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CaretUpOutlined style={{ fontSize: '10px' }} />
                <CaretDownOutlined style={{ fontSize: '10px' }} />
              </div>
            </div>
            <div className="status-count" style={{ color: 'black', fontWeight: '700', fontSize: '30px' }}>
            {(dataSummary && dataSummary?.listStatus["二次面接合格"]) ?? 0}
              <span style={{ color: 'black', fontSize: '15px' }}>人</span>
            </div>
          </Col>



          <Col span={4} style={{ textAlign: 'center', border: '1px solid #ccc', padding: '20px 10px', borderRadius: '8px', marginRight: '50px' }}>
            <div className="status-name" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#FFF8E5', width: '100%', borderRadius: '4px', padding: '5px 10px' }}>
              <p style={{ alignItems: 'center', marginBottom: '0px', fontSize: '20px' }}>
              内定・採用              </p>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CaretUpOutlined style={{ fontSize: '10px' }} />
                <CaretDownOutlined style={{ fontSize: '10px' }} />
              </div>
            </div>
            <div className="status-count" style={{ color: 'black', fontWeight: '700', fontSize: '30px' }}>
            {(dataSummary && dataSummary?.listStatus["二次面接合格"]) ?? 0}              <span style={{ color: 'black', fontSize: '15px' }}>人</span>
            </div>
          </Col>



          <Col span={4} style={{ textAlign: 'center', border: '1px solid #ccc', padding: '20px 10px', borderRadius: '8px', marginRight: '50px' }}>
            <div className="status-name" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#CCCCCC', width: '100%', borderRadius: '4px', padding: '5px 10px' }}>
              <p style={{ alignItems: 'center', marginBottom: '0px', fontSize: '20px' }}>
              不採用              </p>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CaretUpOutlined style={{ fontSize: '10px' }} />
                <CaretDownOutlined style={{ fontSize: '10px' }} />
              </div>
            </div>
            <div className="status-count" style={{ color: 'black', fontWeight: '700', fontSize: '30px' }}>
            {(dataSummary && dataSummary?.listStatus["二次面接合格"]) ?? 0}
              <span style={{ color: 'black', fontSize: '15px' }}>人</span>
            </div>
          </Col>







        </Row>

        <Row >
          <Col span={5} style={{ marginLeft: '90px' }}>
            <div style={{ border: '1px solid rgb(204, 204, 204)', height: '50px', borderTop: 'none' }}>

            </div>
          </Col>

          <Col span={5} style={{ marginLeft: '30px' }}>
            <div style={{ border: '1px solid rgb(204, 204, 204)', height: '50px', borderTop: 'none' }}>

            </div>
          </Col>
        </Row>









        <div className="summary-count">
          <span className="bold">遷移率</span>
          <span>
            {dataSummary?.listStatus["応募"] /
              dataSummary?.listStatus["書類選考合格"]}
            %
          </span>
          <span>100.0%</span>
          <span>-%</span>
          <span>-%</span>
        </div>
        <div className="summary-count">
          <span className="bold">リードタイム</span>
          <span>0.0日</span>
          <span>0.0日</span>
          <span>-日</span>
          <span>-日</span>
        </div>
      </div>
    </>
  );
};

export default DataSummary;
