import hradminlist from "../../../../img/helpImg/hradminlist.png";
import newhradmin from "../../../../img/helpImg/newhradmin.png";

const ChangeEmail = () => {
  return (
    <div className="m-t-30 common-text-black">
      <p>
        {" "}
        求人エックスにログインするためのメールアドレス（ログインID）は、管理画面からご自身で変更ができます。
      </p>
      <br />
      <p>
        {" "}
        1）求人エックスにログイン後、企業情報管理画面下部にある企業担当者項目で、該当のID右部にある［編集］をクリック。
      </p>
      <br />
      <img style={{ width: "640px" }} src={hradminlist} />
      <br />
      <br />
      <p>
        2）ポップアップが出てくるのでログインID（メースアドレス）の項目に希望のメールアドレスを入力の上、パスワードを入力して［保存する］をクリック。
      </p>
      <br />
      <img style={{ width: "640px" }} src={newhradmin} />
      <br />
      <br />
      <div className="common-text-gray">
        <p>
          ※変更したいIDが応募送信先アドレスとして原稿に紐づいている場合は紐づけを外してから作業をお願いします。
        </p>
        <p>
          ※変更後のアドレスを応募送信先アドレスとして原稿に紐づけたい場合は再度応募先メールアドレスの確認作業が必要となります（応募先メールアドレスの確認作業については<span className="common-text-blue-normal">こちら</span>）。
        </p>
        <br />
        <p>
          メールアドレスはログインIDとなりますので、次回ログインの際はお間違えないようご注意ください。
        </p>
        <br />
      </div>
    </div>
  );
};

export default ChangeEmail;
