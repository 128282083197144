import moment = require("moment");

export const handleInitialValuesResultApplicant = (values) => {
 
    const data  = {
        status_applicant:values.status_applicant,
        next_interview: values.interview_schedule ? moment(values.interview_schedule):null,
        memo: values.memo
    };
    for (let i = 1; i <= values.status_progress_applicants.length; i++) {
        data[`grades${i}`] =values.status_progress_applicants[i-1]?.grades? Number(values.status_progress_applicants[i-1]?.grades):null;
        data[`description${i}`] =values.status_progress_applicants[i-1]?.description;
      
    }
    return data;
  };
  