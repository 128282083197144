import moment = require("moment");

export const handleDataSentResultApplicant = (values, applicant) => {
  const dataSent = {
    memo: values.memo,
    interview_schedule:values.next_interview? moment(values.next_interview).format("YYYY-MM-DD HH:mm:ss"): null,
    statusProgresApplicant: [
      {
        id: applicant.status_progress_applicants[0]?.id || null,
        description: values.description1,
        grades: values.grades1,
      },
      {
        id: applicant.status_progress_applicants[1]?.id || null,
        description: values.description2,
        grades: values.grades2,
      },
      {
        id: applicant.status_progress_applicants[2]?.id || null,
        description: values.description3,
        grades: values.grades3,
      },
    ].filter((el) => el.id !== null),
  };

  return dataSent;
};
