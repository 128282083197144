import {  createReducer } from '@reduxjs/toolkit'
import { openNoti} from '../action/notiAction';

const initialState = {
     
     title:'',

 }
 
 
 const notiHOCReducers= createReducer(initialState, (builder) => {
  builder
    .addCase(openNoti, (state, action) => {   
     console.log('fsdfdsf',action.payload.title);
       
      state.title= action.payload.title;
        })
   
})
 
 
 export default notiHOCReducers